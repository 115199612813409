import { useEffect, useState } from 'react';

import find from 'lodash/find';

import { ClientService } from '../services/client.service';
import { CurrentUserService } from '../services/current-user.service';
import { TenantsService } from '../services/tenants.service';
import { OrgTenantPreferencesService } from 'src/services/org-tenant-preferences.service';
import useAbortController from 'src/hooks/use-abort-controller.hook';
import { TenantPreferenceKeysEnum } from '../enums/tenant-preference-keys.enum';
import { chatServices } from '../constants/chat-services.constant';

interface IChatTenantPreference {
  chatAvailable: boolean;
}

export default function useChatTenantPreference(): IChatTenantPreference {
  const { signal } = useAbortController();
  const [chatAvailable, setChatAvailable] = useState(null);

  useEffect(() => {
    checkChatAvailability();
  }, []);

  const checkChatAvailability = async (): Promise<void> => {
    const isChatAvailable = CurrentUserService?.permissions?.CHAT_UI && (await checkChatPreferences());
    setChatAvailable(isChatAvailable);
  };

  const checkChatPreferences = (): Promise<boolean> => {
    return CurrentUserService.isOrgUser ? getOrgUserChatAvailability() : getChatTenantPreference();
  };

  const getOrgUserChatAvailability = (): Promise<boolean> => {
    return TenantsService.getOrgChatServices(signal).then((services) => !!services?.length);
  };

  const getChatTenantPreference = async (): Promise<boolean> => {
    const clientServices = await ClientService.getTenantServices(CurrentUserService.user);
    const hasChatServices = clientServices.some((item) => chatServices.includes(item.name));

    return (
      hasChatServices &&
      OrgTenantPreferencesService.getOrgTenantPreferences(signal).then((tenantPreferences) => {
        const preference = find(tenantPreferences.data, (i) => i.key === TenantPreferenceKeysEnum.PREMIUM_ANALYST_CHAT);
        return preference?.value;
      })
    );
  };

  return { chatAvailable };
}
