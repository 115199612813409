import { common as commonColors } from '@mui/material/colors';
import { createTheme, darken, Theme, ThemeOptions } from '@mui/material/styles';
import merge from 'lodash/merge';

import { ThemesEnum } from '../enums/themes.enum';

interface IThemeConfig {
  theme: ThemesEnum.LIGHT | ThemesEnum.DARK | undefined;
}

export const fonts = ['Roboto', 'sans-serif', 'Arial'].join(', ');
const btnWarningColor = '#F3BB1C';
const btnWarningColorHover = darken(btnWarningColor, 0.2);

const baseOptions: ThemeOptions = {
  spacing: 4,
  palette: {
    primary: {
      main: '#00BEA5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#03D6C2',
    },
    info: {
      main: '#e0e0e0',
    },
  },
  typography: {
    fontFamily: fonts,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'auto !important',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-containedWarning': {
            backgroundColor: btnWarningColor,
            '&:hover': {
              backgroundColor: btnWarningColorHover,
            },
          },
          '&.MuiButton-outlinedWarning': {
            borderColor: btnWarningColor,
            color: btnWarningColor,
            '&:hover': {
              borderColor: btnWarningColorHover,
              color: btnWarningColorHover,
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '4px 12px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '4px 12px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          marginLeft: '8px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          whiteSpace: 'pre-line',
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: ThemesEnum.LIGHT,
    overrides: {},
    palette: {
      mode: ThemesEnum.LIGHT,
      action: {
        selected: '#E6F9F6',
      },
      background: {
        default: '#f4f6f8',
        paper: commonColors.white,
      },
      text: {
        primary: 'rgb(0, 0, 0)',
        tip: 'rgb(137, 138, 141)',
        error: '#B4292A',
      },
    },
  },
  {
    name: ThemesEnum.DARK,
    palette: {
      mode: ThemesEnum.DARK,
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2b3648',
        paper: '#212936',
      },
      text: {
        primary: 'rgb(255, 255, 255)',
        tip: 'rgb(137, 138, 141)',
        error: '#B4292A',
      },
    },
  },
];

export const createCustomTheme = (config: IThemeConfig = {} as IThemeConfig): Theme => {
  const themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
  }

  return createTheme(merge({}, baseOptions, themeOptions));
};
