import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Loader } from '../loader.component';
import { ICssProperties, ICssStyle } from '../../../interfaces/css-style.type';

interface IProps {
  children: React.ReactElement;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  tooltip?: string | React.ReactElement;
  sx?: ICssProperties;
  className?: string;
  isLoading?: boolean;
  ariaLabel?: string;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const classes: ICssStyle = {
  iconButton: {
    '&.MuiButtonBase-root': {
      padding: 1,

      '&.Mui-disabled': {
        opacity: 0.8,

        '& .MuiSvgIcon-root': {
          fill: 'text.secondary',
        },

        '& svg.custom-svg-icon': {
          opacity: 0.5,
        },
      },
    },
  },

  small: {
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  medium: {
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
  large: {
    '& .MuiSvgIcon-root': {
      width: '28px',
      height: '28px',
    },
  },
};

export default function FccIconButton({
  tooltip = '',
  color = 'text.secondary',
  size = 'small',
  className,
  sx = {},
  onClick,
  disabled,
  children,
  isLoading,
  ariaLabel,
  onMouseDown,
}: IProps): React.ReactElement {
  const iconSize = classes[size];

  return (
    <Tooltip title={tooltip}>
      <Box component="span" sx={{ cursor: disabled ? 'default' : 'pointer', ...sx }}>
        <IconButton
          aria-label={ariaLabel}
          className={className}
          sx={{ ...classes.iconButton, ...iconSize, color }}
          size="small"
          onClick={onClick}
          onMouseDown={onMouseDown}
          disabled={disabled}
        >
          {isLoading ? <Loader isGlobal={false} size={25} /> : children}
        </IconButton>
      </Box>
    </Tooltip>
  );
}
