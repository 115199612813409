import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CurrentUserService } from 'src/services/current-user.service';
import { IUserSlice } from './utils/user-slice.type';

const initialState: IUserSlice = {
  data: null,
};

export const fetchUserData = createAsyncThunk('user/data', () => CurrentUserService.getCurrentUserData());

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});

const { actions } = userSlice;
export const logout = actions.logout;
