import React from 'react';

import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Remove from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FccIconButton from 'src/shared/components/icons/fcc-icon-button.component';
import { ICssStyle } from 'src/interfaces/css-style.type';

interface IProps {
  minimizePanel: () => void;
  maximizePanel: () => void;
  isMinimized: boolean;
  attachmentsCount: number;
  isQueueEmpty: boolean;
  closeUploadingModal: () => void;
}

const classes: ICssStyle = {
  root: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  title: { fontSize: '20px', lineHeight: '28px', fontWeight: 'fontWeightMedium', color: 'text.primary' },
};

const closeIconTooltip = 'The "Close" action will be activated after all attachments are uploaded';

export default function PanelHeader({
  minimizePanel,
  maximizePanel,
  isMinimized,
  attachmentsCount,
  isQueueEmpty,
  closeUploadingModal,
}: IProps): React.ReactElement {
  return (
    <Box sx={classes.root}>
      <Typography sx={classes.title}>Uploading Attachments ({attachmentsCount})</Typography>
      <Box>
        {isMinimized ? (
          <FccIconButton onClick={maximizePanel} size="large">
            <Add />
          </FccIconButton>
        ) : (
          <FccIconButton onClick={minimizePanel} size="large">
            <Remove />
          </FccIconButton>
        )}
        <FccIconButton
          sx={{ ml: 2 }}
          onClick={closeUploadingModal}
          size="large"
          disabled={!isQueueEmpty}
          tooltip={isQueueEmpty ? '' : closeIconTooltip}
        >
          <Close />
        </FccIconButton>
      </Box>
    </Box>
  );
}
