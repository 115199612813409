import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { Theme, useTheme } from '@mui/material/styles';

import NavItem from './components/nav-item.component';
import { INavBarItem, INavBarSection } from './navbar.type';
import { ThemesEnum } from 'src/enums/themes.enum';
import LogoDark from 'src/assets/Fortified_Logo_Dark.svg';
import LogoLight from 'src/assets/Fortified_Logo_Light.svg';
import HelpAndResources from 'src/shared/widgets/help-and-resources';

const classes = {
  subheader: {
    paddingLeft: 2,
    fontWeight: 'fontWeightMedium',
  },
  logoContainer: { margin: '8px auto', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  betaLabel: {
    border: (t: Theme): string => `2px solid ${t.palette.text.primary}`,
    borderRadius: '20px',
    padding: '1px 5px',
    fontWeight: 'fontWeightBold',
    fontSize: '10px',
    letterSpacing: '1px',
    opacity: 0.7,
  },
};

function renderNavItems(tabs: INavBarItem[]): React.ReactElement {
  return (
    <List disablePadding>
      {tabs.reduce((acc: React.ReactElement[], item: INavBarItem) => reduceChildRoutes(acc, item), [])}
    </List>
  );
}

function reduceChildRoutes(acc: React.ReactElement[], tab: INavBarItem): React.ReactElement[] {
  acc.push(<NavItem key={tab.id} tab={tab} />);
  return acc;
}

interface INavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  sections: INavBarSection[];
}

export default function NavBar({ sections, onMobileClose, openMobile }: INavBarProps): React.ReactElement {
  const theme = useTheme();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box sx={classes.logoContainer}>
        <img src={theme.palette.mode === ThemesEnum.LIGHT ? LogoLight : LogoDark} alt="Logo" />
      </Box>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ padding: 2 }}>
          {sections.map(({ subheader, items }) => (
            <React.Fragment key={subheader}>
              <List
                subheader={
                  <ListSubheader sx={{ paddingLeft: 2, fontWeight: 'fontWeightMedium' }} disableGutters disableSticky>
                    {subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems(items)}
              </List>
            </React.Fragment>
          ))}
        </Box>
      </PerfectScrollbar>
      <HelpAndResources />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              width: 256,
            },
          }}
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          sx={{
            '& .MuiDrawer-paper': {
              width: 256,
            },
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}
