import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { AuthService } from '../../services/auth.service';
import Login_Image from 'src/assets/Login_Image.svg';

const PREFIX = 'AuthView';

const classes = {
  root: `${PREFIX}-root`,
  brandContainer: `${PREFIX}-brandContainer`,
  formContainer: `${PREFIX}-formContainer`,
  title: `${PREFIX}-title`,
  loginImage: `${PREFIX}-loginImage`,
  formFooter: `${PREFIX}-formFooter`,
  link: `${PREFIX}-link`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '100vh',
  },

  [`& .${classes.brandContainer}`]: {
    width: '55%',
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betwen',
    position: 'relative',
  },

  [`& .${classes.formContainer}`]: {
    width: '45%',
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    '& span': {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.loginImage}`]: {
    width: '100%',
    height: 'auto',
  },
  [`& .${classes.formFooter}`]: {
    position: 'absolute',
    bottom: '16px',
    maxWidth: '450px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  [`& .${classes.link}`]: { fontSize: '12px', color: theme.palette.primary.main, cursor: 'pointer' },
}));

export default function AuthView(): React.ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.verifiedUserName) {
      configureUser();
    } else {
      navigate('/login');
    }
  }, []);

  const configureUser = async (): Promise<void> => {
    await AuthService.configure(AuthService.verifiedUserName);
  };

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.brandContainer}>
        <img className={classes.loginImage} src={Login_Image} alt="Fortified" />
      </Box>
      <Box className={classes.formContainer}>
        <Outlet />
        <Box className={classes.formFooter}>
          <Typography sx={{ fontSize: '14px', fontWeight: 'fontWeightBold', mb: 4, color: 'text.primary' }}>
            Need Help ?
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>
            Please contact support at{' '}
            <a
              onClick={(event): void => event.stopPropagation()}
              href="mailto:help@fortifiedhealthsecurity.com"
              className={classes.link}
            >
              help@fortifiedhealthsecurity.com
            </a>
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>
            (If you need immediate assistance please contact us at <b>615-600-4002</b>.)
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography sx={{ fontSize: '12px' }}>
            View our{' '}
            <a
              onClick={(event): void => event.stopPropagation()}
              href="https://fortifiedhealthsecurity.com/digital-platform-services-terms-of-use/"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              onClick={(event): void => event.stopPropagation()}
              href="https://fortifiedhealthsecurity.com/privacy-notice/"
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  );
}
