import React, { Suspense, useEffect, useRef, useState } from 'react';

import { lazy } from '@loadable/component';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import ceil from 'lodash/ceil';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import { useSnackbar } from 'notistack';

import Notification from './notification.component';
import NotificationsFilter from './notifications-filter.component';
import DrawerPanel from 'src/shared/components/drawer-panel.component';
import { Loader } from 'src/shared/components/loader.component';
import { CurrentUserService } from '../../../../services/current-user.service';
import { TenantsService } from '../../../../services/tenants.service';
import { NotificationsService } from '../notifications.service';
import { ClientService } from 'src/services/client.service';
import useAssessmentRedirect from '../utils/use-assessment-redirect.hook';
import useVtmRedirect from '../utils/use-vtm-redirect.hook';
import { INotification, INotificationFilters, INotificationPage } from '../notifications.type';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { IWSMessageWithPayload } from 'src/interfaces/ws-message.type';
import { QueryKeysEnum } from '../../../../enums/query-keys.enum';
import { TenantStatusEnum } from '../../../widgets/tenant-management/utils/tenant-status.enum';
import { AllOptionValueEnum } from '../utils/notification-services.enum';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';
import { assessmentDetailsTabsTypes, assessmentTypes } from '../utils/constants/assessment-details-types.constant';
import { notificationDetailsTypes } from '../utils/constants/notification-details-types.constant';
import { notificationListTypes } from '../utils/constants/notification-list-types.constant';
import { redirectNotificationsTypes } from '../utils/constants/redirect-notifications-types.constant';
import { rowsPerPageValues } from 'src/constants/rows-per-page-values.constant';
import { ToastMessages } from 'src/constants/toast-messages.constant';
import Sleep_Bell from 'src/assets/Sleep_Bell.svg';
import FccButton from 'src/shared/components/controls/button';

const EntityDetailsModal = lazy(() => import('../entity-details-modal'));
const EntityListModal = lazy(() => import('../entity-list-modal'));

const classes: ICssStyle = {
  noData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30%',
    '& img': {
      width: '150px',
      marginBottom: 4,
    },
    '& span': {
      color: 'text.secondary',
      fontSize: '18px',
    },
  },
};

interface IProps {
  newNotification: IWSMessageWithPayload<INotification>;
  panelState: boolean;
  setPanelState: (state: boolean) => void;
  readNotification: () => void;
}

const defaultParams = {
  status: [TenantStatusEnum.LIVE],
  page: 0,
  rowsPerPage: rowsPerPageValues.optimistic,
};

const COUNT_PER_PAGE = 50;

const initialParams: INotificationFilters = {
  limit: COUNT_PER_PAGE,
  page: 1,
  service: AllOptionValueEnum.ALL,
  clientId: AllOptionValueEnum.ALL,
};

export default function NotificationsPanel({
  newNotification,
  panelState,
  setPanelState,
  readNotification,
}: IProps): React.ReactElement {
  const { vtmRedirect } = useVtmRedirect();
  const { assessmentRedirect } = useAssessmentRedirect();
  const queryClient = useQueryClient();
  const containerRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const selectedClientId = ClientService.getSelectedClient()?.client_id;
  const organization = ClientService.getSelectedOrganization() || CurrentUserService.user.organization;
  const organizationClientId = organization?.client_id;
  const organizationId = ClientService.getOrganizationId();
  const servicesFilterParam = TenantsService.getServicesFilterParam();
  const isTenantUser = !CurrentUserService.isUserInCoreTeam && !CurrentUserService.isOrgUser;
  const markAllAsReadTooltip = `This will mark all notifications as read for all ${
    isTenantUser ? '' : 'organization, tenant(s), and '
  }services no matter what filters are currently applied.`;
  const unreadCountQueryKey = [QueryKeysEnum.NOTIFICATIONS_UNREAD_COUNT, {}];

  const [selectedNotification, setSelectedNotification] = useState<INotification>(null);
  const [showNotificationDetails, setShowNotificationDetails] = useState<boolean>(false);
  const [showNotificationList, setShowNotificationList] = useState<boolean>(false);
  const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<INotificationFilters>({
    ...initialParams,
    clientId: organizationId && selectedClientId ? selectedClientId : AllOptionValueEnum.ALL,
  });

  const notificationQueryKey = [
    QueryKeysEnum.NOTIFICATIONS,
    { service: queryParams.service, clientId: queryParams.clientId, selectedClientId, organizationId },
  ];

  const countQueryKey = [
    QueryKeysEnum.NOTIFICATIONS_COUNT,
    { clientId: queryParams.clientId, selectedClientId, organizationId },
  ];

  const { data: tenants, isLoading: isTenantsLoading } = useQuery({
    queryKey: [QueryKeysEnum.ORG_TENANTS, organizationId],
    queryFn: ({ signal }) =>
      TenantsService.getTenants({ ...defaultParams, services: servicesFilterParam, organizationId }, signal),
    enabled: !!organizationId,
  });

  const { data: notifications, isLoading, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: notificationQueryKey,
    queryFn: async ({ pageParam, signal }) => {
      return await NotificationsService.getNotifications(
        { ...queryParams, page: pageParam },
        tenants?.data,
        organizationClientId,
        signal,
      );
    },
    initialPageParam: initialParams.page,
    getNextPageParam: (lastPage, allPages) => {
      const maxPage = ceil(lastPage.total_count / COUNT_PER_PAGE);
      const nextPage = allPages.length + 1;
      return nextPage <= maxPage ? nextPage : undefined;
    },
    enabled: !!tenants || !organizationId,
  });

  const { data: notificationsCount, isLoading: isCountLoading } = useQuery({
    queryKey: countQueryKey,
    queryFn: ({ signal }) =>
      NotificationsService.getNotificationsCount(queryParams, tenants?.data, organizationClientId, signal),
    enabled: !!tenants || !organizationId,
  });

  const { data: unreadNotificationsCount, isLoading: isUnreadNotificationsCountLoading } = useQuery({
    queryKey: unreadCountQueryKey,
    queryFn: ({ signal }) =>
      NotificationsService.getUnreadNotificationsCount({ isAdminViewMode: true, tenantId: null }, null, signal),
  });

  useEffect(() => {
    if (newNotification) {
      queryClient.invalidateQueries({ queryKey: notificationQueryKey });
      queryClient.invalidateQueries({ queryKey: countQueryKey });
    }
  }, [newNotification]);

  const updateQueryParams = (service: string, clientId: string): void => {
    setQueryParams({ ...initialParams, service, clientId });
    setTimeout(() => (containerRef.current.scrollTop = 0));
  };

  const handleSelectNotification = (data: INotification): void => {
    handleReadNotification(data);
    setSelectedNotification(data);
    setShowNotificationDetails(notificationDetailsTypes.includes(data?.type));
    setShowNotificationList(notificationListTypes.includes(data?.type));
    assessmentTypes.includes(data?.type) && handleAssessmentRedirect(data);
    assessmentDetailsTabsTypes.includes(data?.type) && handleAssessmentDetailsTabsRedirect(data);
    data?.type === NotificationTypesEnum.SCAN_COMPLETED && handleScanCompletedRedirect(data);
    redirectNotificationsTypes.includes(data?.type) && setPanelState(false);
  };

  const handleReadNotification = (notification: INotification): void => {
    if (!notification.is_read) {
      const updatedList = {
        pageParams: notifications.pageParams,
        pages: map(notifications.pages, (page) => {
          return {
            ...page,
            data: map(page.data, (item) => {
              return item.id === notification.id ? { ...item, is_read: true } : item;
            }),
          };
        }),
      };
      const updatedNotificationsCount = {
        ...notificationsCount,
        [notification.service]: notificationsCount[notification.service] - 1,
      };
      NotificationsService.readNotification(notification.id).then(() => {
        queryClient.setQueryData(notificationQueryKey, updatedList);
        queryClient.setQueryData(countQueryKey, updatedNotificationsCount);
        readNotification();
      });
    }
  };

  const handleAssessmentRedirect = (data: INotification): void => {
    assessmentRedirect({
      clientId: data?.client_id,
      assessmentId: data?.data?.id,
      type: data?.type,
      clientType: data?.data?.entity_type,
    });
  };

  const handleAssessmentDetailsTabsRedirect = (data: INotification): void => {
    assessmentRedirect({
      clientId: data?.client_id,
      assessmentId: data?.data?.assessment_id,
      type: data?.type,
      clientType: data?.data?.entity_type,
      entityId: data?.data?.id,
    });
  };

  const handleScanCompletedRedirect = (data: INotification): void => {
    vtmRedirect(data?.client_id, data?.data?.source, `${data?.data?.group_id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (event: any): void => {
    const target = event.target;
    const margin = 0.8;
    const isBottomReached = (target.scrollHeight - ceil(target.scrollTop)) * margin <= target.clientHeight;
    if (isBottomReached) {
      fetchNextPage();
    }
  };

  const handleMarkAllNotificationsAsRead = (): void => {
    setIsMarkingAllAsRead(true);
    NotificationsService.markAllNotificationsAsRead()
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.NOTIFICATIONS] });
        queryClient.setQueryData(unreadCountQueryKey, 0);
        queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.NOTIFICATIONS_COUNT] });
        queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.NOTIFICATIONS_UNREAD_COUNT] });
        enqueueSnackbar('All notifications have been marked as read.');
      })
      .catch(() => enqueueSnackbar(ToastMessages.generalError, { variant: ToastMessageSeveritiesEnum.ERROR }))
      .finally(() => setIsMarkingAllAsRead(false));
  };

  return (
    <>
      <DrawerPanel
        isDrawerOpen={panelState}
        onClose={(): void => setPanelState(false)}
        width="650px"
        customPadding="0px"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 4, py: 2 }}>
          <Typography component="h6" sx={{ fontSize: '20px', fontWeight: 'fontWeightMedium' }}>
            Notifications
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isUnreadNotificationsCountLoading && (
              <Tooltip title={markAllAsReadTooltip}>
                <Box>
                  <FccButton
                    startIcon={<PlaylistAddCheck />}
                    sx={{ textTransform: 'none' }}
                    onClick={handleMarkAllNotificationsAsRead}
                    disabled={!unreadNotificationsCount}
                    loading={isMarkingAllAsRead}
                  >
                    Mark all as read
                  </FccButton>
                </Box>
              </Tooltip>
            )}
            <IconButton aria-label="close" onClick={(): void => setPanelState(false)} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <NotificationsFilter
          queryParams={queryParams}
          updateQueryParams={updateQueryParams}
          tenants={tenants}
          notificationsCount={notificationsCount}
          isTenantsLoading={isTenantsLoading}
          isCountLoading={isCountLoading}
          selectedClientId={selectedClientId}
          organization={organization}
        />
        <Divider />
        <Box sx={{ width: '100%', overflowY: 'auto' }} onScroll={debounce(handleScroll, 300)} ref={containerRef}>
          {notifications?.pages?.map((page: INotificationPage) => {
            return page?.data?.map((notification: INotification) => (
              <Notification
                key={notification.id}
                data={notification}
                selectNotification={handleSelectNotification}
                isRead={notification.is_read}
              />
            ));
          })}

          {(isLoading || isFetchingNextPage) && <Loader isGlobal={true} />}

          {!isLoading && !isFetchingNextPage && !notifications?.pages?.[0]?.data.length && (
            <Box sx={classes.noData}>
              <img src={Sleep_Bell} alt="There are no notifications yet" />
              <Typography component="span">There are no notifications yet</Typography>
            </Box>
          )}
        </Box>
      </DrawerPanel>
      {showNotificationDetails && (
        <Suspense>
          <EntityDetailsModal
            notification={selectedNotification}
            onCloseDialog={(): void => setShowNotificationDetails(false)}
          />
        </Suspense>
      )}
      {showNotificationList && (
        <Suspense>
          <EntityListModal
            notification={selectedNotification}
            onCloseDialog={(): void => setShowNotificationList(false)}
          />
        </Suspense>
      )}
    </>
  );
}
