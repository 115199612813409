import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AttachmentActions from './attachment-actions.component';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { formatAttachmentName } from '../utils/uploading-attachments.util';

interface IProps {
  attachmentName: string;
  error: string;
  removeFromQueue: () => void;
  removeFromView: () => void;
  retryUpload: () => void;
  stopUpload: () => void;
  isPending: boolean;
  isUploaded: boolean;
}

const classes: ICssStyle = {
  root: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 },
  name: { fontSize: '16x', lineHeight: '28px', color: 'text.primary' },
};

export default function AttachmentHeader({
  attachmentName,
  error,
  removeFromQueue,
  removeFromView,
  retryUpload,
  stopUpload,
  isPending,
  isUploaded,
}: IProps): React.ReactElement {
  return (
    <Box sx={classes.root}>
      <Typography sx={{ ...classes.name, ...(error && { color: 'error.main' }) }}>
        {formatAttachmentName(attachmentName)}
      </Typography>
      <AttachmentActions
        error={error}
        removeFromQueue={removeFromQueue}
        removeFromView={removeFromView}
        retryUpload={retryUpload}
        stopUpload={stopUpload}
        isPending={isPending}
        isUploaded={isUploaded}
      />
    </Box>
  );
}
