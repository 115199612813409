import React from 'react';

import CheckOutlined from '@mui/icons-material/CheckOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';

import { ICssStyle } from 'src/interfaces/css-style.type';

interface IProps {
  uploadedPercentage: number;
  error: string;
  isPending: boolean;
  isUploaded: boolean;
}

const classes: ICssStyle = {
  state: {
    fontSize: '12px',
    lineHeight: '16px',
    color: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'fontWeightMedium',
  },
  error: {
    fontSize: '12px',
    lineHeight: '20px',
    color: 'error.main',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'fontWeightMedium',
  },
  icon: { fontSize: '14px', mr: 1 },
  percentage: { fontSize: '12px', lineHeight: '20px', fontWeight: 'fontWeightMedium', ml: 1 },
};

export default function UploadState({ error, isUploaded, isPending, uploadedPercentage }: IProps): React.ReactElement {
  return (
    <>
      {error ? (
        <Typography sx={classes.error}>
          <InfoOutlined sx={classes.icon} />
          {error}
        </Typography>
      ) : isUploaded ? (
        <Typography sx={classes.state}>
          <CheckOutlined sx={classes.icon} />
          Completed
        </Typography>
      ) : isPending ? (
        <Typography sx={{ ...classes.state, color: 'text.secondary' }}>Pending...</Typography>
      ) : (
        <Typography sx={classes.state}>
          Uploading...
          <Typography component="span" sx={classes.percentage}>
            {uploadedPercentage}%
          </Typography>
        </Typography>
      )}
    </>
  );
}
