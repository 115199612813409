import React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';

import { ICssStyle } from '../../../../interfaces/css-style.type';

interface IProps extends ButtonProps {
  component?: React.ElementType;
  loading?: boolean;
  ariaLabel?: string;
}

const classes: ICssStyle = {
  button: {
    position: 'relative',
    lineHeight: 'normal',
    paddingY: 2,

    '&.Mui-disabled svg.custom-svg-icon': {
      opacity: 0.26,
    },
  },
};

export default function FccButton({
  children,
  color,
  variant,
  startIcon,
  endIcon,
  onClick,
  className,
  sx,
  disabled,
  type,
  fullWidth,
  size,
  component = 'button',
  loading = false,
  ariaLabel,
}: IProps): React.ReactElement {
  const isLoadingWithIcon = loading && startIcon;
  const isLoadingWithoutIcon = loading && !startIcon;

  return (
    <Button
      color={color}
      variant={variant}
      startIcon={isLoadingWithIcon ? <CircularProgress size="1em" sx={{ color: 'inherit' }} /> : startIcon}
      endIcon={endIcon}
      onClick={onClick}
      className={className}
      sx={{ ...classes.button, color: color === 'inherit' ? '#000' : color, ...sx }}
      disabled={disabled || loading}
      type={type}
      aria-label={ariaLabel}
      fullWidth={fullWidth}
      size={size}
      component={component}
    >
      {isLoadingWithoutIcon ? (
        <>
          <CircularProgress size="1em" sx={{ color: 'inherit', position: 'absolute' }} />
          <Box sx={{ visibility: 'hidden' }}>{children}</Box>
        </>
      ) : (
        children
      )}
    </Button>
  );
}
