import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import omit from 'lodash/omit';
import tail from 'lodash/tail';
import { useSnackbar } from 'notistack';
import { v4 } from 'uuid';

import AttachmentInfo from './components/attachment-info.component';
import PanelHeader from './components/panel-header.component';
import { UploadingAttachmentsService } from './uploading-attachments.service';
import { ClientService } from 'src/services/client.service';
import { EventListEnum, EventService } from 'src/services/event.service';
import useAbortController from 'src/hooks/use-abort-controller.hook';
import useHandlePromiseError from 'src/hooks/use-handle-promise-error.hook';
import {
  IAttachmentData,
  IAttachmentEventData,
  IAttachmentResponse,
  IEnqueueAttachments,
} from './uploading-attachments.type';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { commonStyle } from 'src/assets/styles/common.style';

const classes: ICssStyle = {
  root: {
    maxHeight: '448px',
    backgroundColor: 'background.paper',
    boxShadow: '0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 36px rgba(0, 0, 0, 0.19)',
    borderRadius: '4px',
    px: 6,
    py: 4,
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    zIndex: 20,
    width: '450px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const UploadingAttachmentsContext = createContext(null);
const uploadingMessage = 'Uploading has been started. All attachments will be added to the list.';

export default function UploadingAttachmentsProvider({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const { abortController } = useAbortController();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { handleError } = useHandlePromiseError();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [attachmentsData, setAttachmentsData] = useState<IAttachmentData[]>([]);
  const [uploadQueue, setUploadQueue] = useState<IAttachmentData[]>([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [loadingState, setLoadingState] = useState<Record<string, number>>({});
  const [errorState, setErrorState] = useState<Record<string, string>>({});
  const [uploadedAttachments, setUploadedAttachments] = useState<string[]>([]);
  const isAttachmentUploadingRef = useRef<boolean>(false);
  const attachmentsDataLengthRef = useRef<number>(attachmentsData.length);

  useEffect(() => {
    if (!!uploadQueue.length && !isAttachmentUploadingRef.current) {
      uploadAttachment(uploadQueue[0]);
    }
  }, [uploadQueue]);

  useEffect(() => {
    attachmentsDataLengthRef.current = attachmentsData.length;
  }, [attachmentsData]);

  const enqueueAttachments = useCallback<IEnqueueAttachments>(
    (attachments, url, target) => {
      const data: IAttachmentData[] = attachments.map((attachment) => ({
        attachment,
        url,
        id: v4(),
        tokenSource: abortController,
        clientId: ClientService.getClientId(),
        target,
      }));

      !isModalOpen && setIsModalOpen(true);
      setAttachmentsData((prev) => [...prev, ...data]);
      setUploadQueue((prev) => [...prev, ...data]);

      enqueueSnackbar(uploadingMessage);
    },
    [isModalOpen],
  );

  const onUploadProgress = (event: ProgressEvent, attachmentId: string, attachmentSize: number): void => {
    const percentage = Math.floor((event.loaded / attachmentSize) * 100);
    setLoadingState((prevState) => ({ ...prevState, [attachmentId]: percentage }));
  };

  const uploadAttachment = (data: IAttachmentData): void => {
    isAttachmentUploadingRef.current = true;
    setLoadingState((prev) => ({ ...prev, [data.id]: 0 }));

    UploadingAttachmentsService.uploadAttachment(data, onUploadProgress)
      .then((response) => handleUploadSuccess(response, data))
      .catch((error) => {
        handleError(error);
        updateDataStatesOnError(data);
      })
      .finally(handleUploadCleanUp);
  };

  const handleUploadSuccess = (response: IAttachmentResponse, data: IAttachmentData): void => {
    setUploadedAttachments((prev) => [...prev, data.id]);

    const { clientId, target } = data;
    const eventData: IAttachmentEventData = {
      targetId: target.id,
      targetType: target.type,
      clientId,
      attachment: response,
    };
    EventService.emit(EventListEnum.ATTACHMENT_UPLOADED, eventData);
  };

  const updateDataStatesOnError = (data: IAttachmentData): void => {
    setErrorState((prev) => ({ ...prev, [data.id]: 'Uploading Error' }));
    setLoadingState((prev) => omit(prev, [data.id]));
  };

  const handleUploadCleanUp = (): void => {
    isAttachmentUploadingRef.current = false;
    setUploadQueue((prev) => tail(prev));
  };

  const closeUploadingModal = (): void => {
    setIsMinimized(false);
    setIsModalOpen(false);
    setAttachmentsData([]);
    setUploadedAttachments([]);
    setErrorState({});
    setLoadingState({});
  };

  const removeFromView = (id: string): void => {
    if (attachmentsDataLengthRef.current === 1) {
      closeUploadingModal();
    } else {
      setAttachmentsData((prev) => prev.filter((item) => item.id !== id));
      setErrorState((prev) => omit(prev, [id]));
    }
  };

  const removeFromQueue = (id: string): void => {
    setUploadQueue((prev) => prev.filter((item) => item.id !== id));
    removeFromView(id);
  };

  const retryUpload = (data: IAttachmentData): void => {
    const updatedData = { ...data, tokenSource: abortController };
    setAttachmentsData((prev) => prev.map((item) => (item.id === data.id ? updatedData : item)));
    setUploadQueue((prev) => [...prev, updatedData]);
    setErrorState((prev) => omit(prev, [data.id]));
  };

  const value = useMemo(() => ({ enqueueAttachments }), [enqueueAttachments]);

  return (
    <UploadingAttachmentsContext.Provider value={value}>
      {children}
      {isModalOpen && (
        <Box sx={classes.root}>
          <PanelHeader
            minimizePanel={(): void => setIsMinimized(true)}
            maximizePanel={(): void => setIsMinimized(false)}
            isMinimized={isMinimized}
            attachmentsCount={attachmentsData.length}
            isQueueEmpty={!uploadQueue.length}
            closeUploadingModal={closeUploadingModal}
          />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              display: isMinimized ? 'none' : 'block',
              my: 4,
              ...commonStyle.shadowScrollArea(theme),
            }}
          >
            {attachmentsData.map((data) => (
              <AttachmentInfo
                key={data.id}
                attachmentData={data}
                uploadedPercentage={loadingState[data.id]}
                isUploaded={uploadedAttachments.includes(data.id)}
                error={errorState[data.id]}
                removeFromQueue={removeFromQueue}
                removeFromView={removeFromView}
                retryUpload={retryUpload}
              />
            ))}
          </Box>
        </Box>
      )}
    </UploadingAttachmentsContext.Provider>
  );
}
