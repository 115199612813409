import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UploadState from './upload-state.component';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { formatBytes } from 'src/utils/bytes-formatter.util';

const classes: ICssStyle = {
  root: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 },
  progress: { fontSize: '12px', lineHeight: '20px', fontWeight: 'fontWeightMedium', color: 'text.secondary' },
};

interface IProps {
  uploadedPercentage: number;
  error: string;
  attachmentSize: number;
  isPending: boolean;
  isUploaded: boolean;
}

export default function UploadInfo({
  uploadedPercentage,
  error,
  attachmentSize,
  isPending,
  isUploaded,
}: IProps): React.ReactElement {
  const uploadedAttachmentSize = (attachmentSize / 100) * uploadedPercentage;
  const progressText = error ? '0 Mb' : `${formatBytes(uploadedAttachmentSize)} of ${formatBytes(attachmentSize)}`;

  return (
    <Box sx={classes.root}>
      <Typography sx={{ ...classes.progress, ...(error ? { color: 'error.main' } : {}) }}>{progressText}</Typography>
      <UploadState
        error={error}
        isPending={isPending}
        isUploaded={isUploaded}
        uploadedPercentage={uploadedPercentage}
      />
    </Box>
  );
}
