import { AxiosResponse } from 'axios';

import { ClientService } from './client.service';
import apiService from 'src/api/api.service';
import { ITenantPreferences } from 'src/interfaces/tenant-preferences.type';
import { apiUrls } from 'src/api/api-urls.constant';

export class OrgTenantPreferencesService {
  public static getOrgTenantPreferences(signal: AbortSignal): Promise<ITenantPreferences> {
    const { clientId, url } = OrgTenantPreferencesService.getSettingsRequestAttr();
    const params = { client_id: clientId };

    return apiService
      .get<AxiosResponse<ITenantPreferences>>({ url, params, signal })
      .then((response) => response?.data);
  }

  public static getSettingsRequestAttr(preferenceId?: number): { clientId: string; url: string } {
    const clientId = ClientService.getClientId();
    const preference = preferenceId ? `${preferenceId}/` : '';

    const url = ClientService.isOrgViewMode()
      ? `${apiUrls.organizationPreference}${preference}`
      : `${apiUrls.tenantPreference}${preference}`;

    return { clientId, url };
  }
}
