import some from 'lodash/some';

import { ClientContextService } from './client-context.service';
import { CurrentUserService } from './current-user.service';
import { IOrganization } from '../interfaces/organization.type';
import { ITenantService, ITenantUI } from '../interfaces/tenant.type';
import { IUser } from '../interfaces/user.type';
import { ClientServicesEnum } from '../enums/client-services.enum';
import { ExternalServicesEnum } from '../enums/external-services.enum';
import { TenantServiceStatusEnum } from '../enums/tenant-service-status.enum';
import { UserRolesEnum } from 'src/enums/user-roles.enum';
import { servicesByRole } from '../constants/client-services-by-role.constant';
import { escalationServices } from '../constants/escalation-services.constant';

export class ClientService {
  public static clientServices: ITenantService[] = [];
  public static selectedOrganization: IOrganization = null;
  public static selectedTenant: ITenantUI = null;

  public static getTenantServices(user: IUser): Promise<ITenantService[]> {
    const clientId = user.organization ? user.organization.client_id : user.tenant.client_id;
    const request = user.organization
      ? ClientContextService.getOrganizationShortInfo(clientId)
      : ClientContextService.getTenantShortInfo(clientId);

    return request.then((res) => res.services);
  }

  public static isClientViewMode(): boolean {
    const isFccSide = CurrentUserService.isUserInCoreTeam;
    const isOrgUser = CurrentUserService.isOrgUser;

    const isClientUser = !isFccSide && !isOrgUser;
    const selectedClient = (isFccSide || isOrgUser) && !!ClientService.getSelectedClient();

    return isClientUser || selectedClient;
  }

  public static isOrgViewMode(): boolean {
    const isFccSide = CurrentUserService.isUserInCoreTeam;
    const isOrgUser = CurrentUserService.isOrgUser;
    const hasOrgViewAccess = isOrgUser || (isFccSide && !!ClientService.getSelectedOrganization());

    return hasOrgViewAccess && !ClientService.isClientViewMode();
  }

  public static isAdminViewMode(): boolean {
    const isFccSide = CurrentUserService.isUserInCoreTeam;
    return isFccSide && !ClientService.isClientViewMode() && !ClientService.isOrgViewMode();
  }

  public static clearSelectedClient(): void {
    ClientService.selectedTenant = null;
    ClientService.selectedOrganization = null;
  }

  public static setSelectedClient(client: ITenantUI): void {
    ClientService.selectedTenant = client;
  }

  public static getSelectedClient(): ITenantUI {
    return ClientService.selectedTenant;
  }

  public static setSelectedOrganization(organization: IOrganization): void {
    ClientService.selectedOrganization = organization;
  }

  public static getSelectedOrganization(): IOrganization {
    return ClientService.selectedOrganization;
  }

  public static getClientId(): string {
    return ClientService.isOrgViewMode()
      ? ClientService.getSelectedOrganization()?.client_id || CurrentUserService.userOrgClientId
      : ClientService.getSelectedClient()?.client_id || CurrentUserService.userClientId;
  }

  public static getAllClientServices(): ITenantService[] {
    return (
      ClientService.getSelectedClient()?.services ||
      ClientService.getSelectedOrganization()?.services ||
      ClientService.clientServices
    );
  }

  public static getActiveClientServices(): ClientServicesEnum[] {
    const services = ClientService.getAllClientServices();
    return ClientService.getActiveServiceNames(services);
  }

  public static getClientServiceSource(
    service: ClientServicesEnum,
    clientServices?: ITenantService[],
  ): ExternalServicesEnum {
    const services = clientServices || ClientService.getSelectedClient()?.services || ClientService.clientServices;
    return services?.find(({ name }) => name === service)?.source;
  }

  public static getClientServicesByUserAccess(
    clientServices?: ITenantService[],
    roles?: UserRolesEnum[],
  ): ClientServicesEnum[] {
    const services =
      clientServices ||
      ClientService.getSelectedClient()?.services ||
      ClientService.getSelectedOrganization()?.services ||
      ClientService.clientServices;

    const serviceNames = CurrentUserService.isUserInCoreTeam
      ? services?.map((item) => item.name)
      : ClientService.getActiveServiceNames(services);

    return ClientService.getClientServicesByRole(serviceNames, roles);
  }

  public static filterTenantServicesByUserAccess(clientServices: ITenantService[], liveOnly = false): ITenantService[] {
    const services =
      CurrentUserService.isUserInCoreTeam && !liveOnly
        ? clientServices
        : clientServices.filter((service) => service.status === TenantServiceStatusEnum.LIVE);

    return servicesByRole.reduce((acc, item) => {
      const currentService = services.find(({ name }) => name === item.service);
      const hasAccess = some(CurrentUserService.roles, (role) => item.checkAccess(role));
      return !!currentService && hasAccess ? [...acc, currentService] : acc;
    }, []);
  }

  public static getClientServicesByRole(
    services = ClientService.getActiveClientServices(),
    roles?: UserRolesEnum[],
  ): ClientServicesEnum[] {
    return servicesByRole
      .filter((item) => {
        const isActive = services?.includes(item.service);
        const hasAccess = some(roles || CurrentUserService.roles, (role) => item.checkAccess(role));
        return isActive && hasAccess;
      })
      .map((item) => item.service);
  }

  public static checkEscalationServicesUserAccess(services?: ITenantService[]): boolean {
    const userServices = ClientService.getClientServicesByUserAccess(services);
    return userServices.some((service) => escalationServices.includes(service));
  }

  public static getUserServicesByRole(): ClientServicesEnum[] {
    const services =
      CurrentUserService.isUserInCoreTeam || CurrentUserService.isOrgUser
        ? Object.values(ClientServicesEnum).filter((k) => typeof k === 'string')
        : ClientService.getActiveClientServices();

    return ClientService.getClientServicesByRole(services);
  }

  public static getActiveServiceNames(services: ITenantService[]): ClientServicesEnum[] {
    return services
      ?.filter((service) => service.status === TenantServiceStatusEnum.LIVE)
      .map((service) => service.name);
  }

  public static checkSiemMdrServiceAccess(service: ClientServicesEnum): boolean {
    const isSiemOrMdrService = [ClientServicesEnum.SIEM, ClientServicesEnum.MDR].includes(service);
    if (!isSiemOrMdrService) {
      return true;
    }

    if (CurrentUserService.isUserInCoreTeam) {
      return true;
    }

    const clientServices = ClientService.getAllClientServices();
    const siemMdrService = clientServices.find((clientService) => clientService.name === service);
    if (siemMdrService?.status === TenantServiceStatusEnum.LIVE) {
      return true;
    }

    const xdrService = clientServices.find((clientService) => clientService.name === ClientServicesEnum.XDR);
    return ![TenantServiceStatusEnum.LIVE, TenantServiceStatusEnum.ON_BOARDING].includes(xdrService?.status);
  }

  public static getClientName(): string {
    return ClientService.getSelectedClient()?.name || CurrentUserService.userTenantName;
  }

  public static getOrganizationId(): number {
    return ClientService.getSelectedOrganization()?.id || CurrentUserService.userOrganizationId;
  }

  public static getOrganizationName(): string {
    return ClientService.getSelectedOrganization()?.name || CurrentUserService.userOrganizationName;
  }
}
