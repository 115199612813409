export const AppMessages = {
  downloadFilteredMessageForAlarms:
    'Downloaded alarms will reflect the currently applied filters. If you would like to download all alarms, clear your filters and click the download button again.',
  downloadSelectedMessageForAlarms: 'Are you sure you want to download only selected alarms?',
  downloadSnackbarMessageForAlarms: 'Alarms report is preparing!',
  downloadFilteredMessageForHosts:
    'Downloaded hosts will reflect the currently applied filters. If you would like to download all hosts clear your filters and click the download button again.',
  downloadSelectedMessageForHosts: 'Are you sure you want to download only selected hosts?',
  downloadSnackbarMessageForHosts: 'Hosts report is preparing!',
  downloadSelectedMessageForVulnerabilities: 'Are you sure you want to download only selected vulnerabilities?',
  downloadFilteredMessageForVulnerabilities:
    'Downloaded vulnerabilities will reflect the currently applied filters. If you would like to download all vulnerabilities, clear your filters and click the download button again.',
  downloadSnackbarMessageForVulnerabilities: 'Vulnerabilities report is preparing!',
  downloadSelectedMessageForEscalations: 'Are you sure you want to download only selected escalations?',
  downloadSnackbarMessageForAcceptedVulnerabilities: 'Accepted vulnerabilities report is preparing!',
  downloadSelectedMessageForAcceptedVulnerabilities:
    'Are you sure you want to download only selected accepted vulnerabilities?',
  downloadFilteredMessageForAcceptedVulnerabilities:
    'Downloaded accepted vulnerabilities will reflect the currently applied filters. If you would like to download all accepted vulnerabilities, clear your filters and click the download button again.',
  downloadFilteredMessageForEscalations:
    'Downloaded escalations will reflect the currently applied filters. If you would like to download all escalations, clear your filters and click the download button again.',
  downloadSnackbarMessageForEscalations: 'Escalation report is preparing!',
  noFilterResult:
    'We’re sorry, we couldn’t find any matches for your filter criteria. We recommend checking your filter criteria, if you believe there should be results please feel free to reach out to us using the support icon in the menu bar above and we would be happy to assist.',
  noResult: 'No data available.',
  noResultMdrAlarms:
    'Central Command does not currently have any alarms for your organization. When an alarm is created it will be available here.',
  noResultIomtAlarms: 'No alarms have been generated within the selected time period.',
  noResultSiemAlarms: 'No alarms have been generated within the selected time period.',
  noResultSiemAlarmsRealtime: 'No alarms have been generated within the last hour.',
  noResultSiemEscalations:
    'Central Command does not currently have any escalations for your organization. When an escalation is created it will be available here.',
  noResultVulnerabilities: 'Only informational vulnerabilities were found.',
  noResultVulnerabilitiesForHosts: 'This hosts contains only informational vulnerabilities.',
  noSearchResult:
    'We’re sorry, we couldn’t find any matches for your search term. We recommend checking your search term for typos, if it looks correct, please feel free to reach out to us using the support icon in the menu bar above and we would be happy to assist.',
  selectItemToDownload: 'Select item to download.',
  noResultChatHistories:
    'It looks like you don’t have any chat history! After you’ve completed a chat the details and conversation history will be available here.',
  notLiveService:
    'This service is in either on-boarding, off-boarding, or deactivated status. Set the service to live status to enable clients to use it.',
  serviceNotAvailable: 'Service not available.',
  widgetEmptyStateMessage: 'There is no data yet',
  widgetErrorMessage: 'An error has occurred.',
  underDevelopment:
    'This section of Central Command is still currently under development and it’s not quite ready yet.\n' +
    'We are working hard to bring you the most valuable experience possible!',
};
