import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';

import apiService from '../api/api.service';
import { IUploadFormData } from '../interfaces/upload-form-data.type';

export enum FormDataMethodsEnum {
  PATCH = 'patch',
  POST = 'post',
}

export function uploadFormData<R>(
  data: IUploadFormData,
  url: string,
  method: FormDataMethodsEnum = FormDataMethodsEnum.POST,
): Promise<R> {
  const formData = new FormData();
  const { params, attachments } = data;

  forIn(params, (value, key) => {
    const propertyValue = value instanceof Blob || typeof value === 'string' ? value : JSON.stringify(value);
    if (!isNil(value)) {
      formData.append(key, propertyValue);
    }
  });
  attachments?.forEach((file) => formData.append(file.name, new Blob([file], { type: file.type })));

  return apiService[method]({
    url,
    data: formData,
  });
}
