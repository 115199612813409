import React, { Suspense, useRef, useState } from 'react';

import { lazy } from '@loadable/component';
import ContactSupportOutlined from '@mui/icons-material/ContactSupportOutlined';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';

import { CurrentUserService } from '../../../services/current-user.service';
import { baseURL } from 'src/api/axios';
import GuideIcon from 'src/assets/guide.svg';

const SupportForm = lazy(() => import('./components/support-form'));

const classes = {
  wrapper: {
    display: 'flex',
    gap: 3,
    flexDirection: 'column',
    marginX: 4,
  },
  container: { display: 'flex', alignItems: 'center', flexDirection: 'column', pt: 1, px: 0, pb: 5.5 },
  helpOutlineOutlinedIconStyles: { width: '50px', height: '50px', opacity: 0.4 },
  primaryText: {
    color: '#a0a2a4',
    fontWeight: '500',
    mb: 3,
    lineHeight: '24px',
    fontSize: '16px',
  },
  buttonStyles: { fontSize: '14px', paddingX: 8, paddingY: 1 },
  buttonItem: { paddingY: 1.5, paddingX: 2 },
  copyIconStyles: { opacity: 0.6, width: '14px', height: 16 },
};

export default function HelpAndResources(): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent): void => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const openUserGuide = (): void => {
    const [, url] = baseURL.split('//');
    const helpUrl = `https://help.${url}`;
    window.open(helpUrl, '_blank');
  };

  const dropdownItems = [
    CurrentUserService.permissions.VIEW_CREATE_SUPPORT_REQUEST_BUTTON && {
      icon: <ContactSupportOutlined sx={{ opacity: 0.4 }} />,
      name: 'Create a Support Request',
      action: (): void => setOpenDialog(true),
    },
    { icon: <img src={GuideIcon} alt="" />, name: 'View the User Guide', action: openUserGuide },
  ].filter(Boolean);

  return (
    <Box sx={classes.wrapper}>
      <Divider />
      <Box sx={classes.container}>
        <HelpOutlineOutlined sx={classes.helpOutlineOutlinedIconStyles} />
        <Box sx={classes.primaryText} component="span">
          Need Some Help?
        </Box>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          variant="contained"
          sx={classes.buttonStyles}
        >
          Help & resources
        </Button>

        <Paper>
          <Popover
            open={open}
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                {dropdownItems.map(({ icon, name, action }) => (
                  <MenuItem
                    key={name}
                    sx={classes.buttonItem}
                    onClick={(e): void => {
                      action();
                      handleClose(e);
                    }}
                  >
                    {icon}
                    <Box sx={{ marginX: 1 }} component="span">
                      {name}
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Popover>
        </Paper>
      </Box>
      {openDialog && (
        <Suspense fallback={null}>
          <SupportForm onCloseDialog={(): void => setOpenDialog(false)} />
        </Suspense>
      )}
    </Box>
  );
}
