export enum QueryKeysEnum {
  ACCEPTED_VULNERABILITIES_LIST = 'accepted-vulnerabilities-list',
  ALL_SERVICES = 'all-services',
  ASSESSMENT_DETAILS = 'assessment-details',
  ASSESSMENT_DOCUMENTS_DATA = 'assessment-documents-data',
  ASSESSMENT_DOCUMENTS_FUNCTIONS_AND_CATEGORIES = 'assessment-documents-functions-and-categories',
  ASSESSMENT_LIST = 'assessment-list',
  ASSESSMENT_SURVEY_COMPLETE_PERCENTAGE = 'assessment-survey-complete-percentage',
  ASSESSMENT_SURVEY_DETAILS = 'assessment-survey-details',
  ASSESSMENT_SURVEY_LIST = 'assessment-survey-list',
  ASSESSMENT_SURVEY_STEPS = 'assessment-survey-steps',
  ASSESSMENT_TIMELINE = 'assessment-timeline',
  AUDIT_LOGS = 'audit-logs',
  AUDIT_LOGS_STATIC_DATA = 'audit-logs-static-data',
  CAP_CALL_NOTES = 'cap-call-notes',
  CAP_ITEMS = 'cap-items',
  CAP_ITEMS_FOR_METRICS = 'cap-items-for-metrics',
  CAP_NUMBER_ACCEPTED = 'cap-number-accepted',
  CAP_NUMBER_REMEDIATED_LAST_TWELVE_MONTH = 'cap-number-remediated-last-twelve-month',
  CAP_OPEN_ITEMS_BREAKDOWN_BY_STATUS = 'cap-open-items-breakdown-by-status',
  CAP_OVERVIEW = 'cap-overview',
  CAP_TOTAL_OPEN_ITEMS = 'cap-total-open-items',
  CARRY_OVER_DATA = 'carry-over-data',
  CLIENT_ORGANIZATIONS = 'client-organizations',
  CLIENT_TENANTS = 'client-tenants',
  COMPARATIVE_ANALYTICS = 'comparative-analytics',
  DOCUMENT_COLLECTION_METRICS = 'document-collection-metrics',
  ESCALATION_LIST_DATA = 'escalation-list-data',
  GLOBAL_SEARCH_MDR = 'global-search-mdr',
  GLOBAL_SEARCH_RESULTS = 'global-search-results',
  GLOBAL_SEARCH_SIEM = 'global-search-siem',
  GLOBAL_SEARCH_VTM = 'global-search-vtm',
  GLOBAL_SEARCH_XDR = 'global-search-xdr',
  HOST_VULNERABILITIES = 'host-vulnerabilities',
  HOSTS_LIST = 'hosts-list',
  LINKED_TENANTS = 'linked-tenants',
  MARKETING_MESSAGES_PREFERENCES = 'marketing-messages-preferences',
  MDR_ALARMS = 'mdr-alarms',
  NEWSFEED = 'newsfeed',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_COUNT = 'notifications-count',
  NOTIFICATIONS_UNREAD_COUNT = 'notifications-unread-count',
  ORG_CLIENTS = 'org-clients',
  ORG_CLIENTS_BY_TYPE = 'org-clients-by-type',
  ORG_TENANTS = 'org-tenants',
  ORG_TENANTS_ESCALATIONS = 'org-tenants-escalations',
  ORG_TENANTS_SERVICES = 'org-tenants-services',
  ORG_TENANTS_UNREAD_NOTIFICATION_COUNT = 'org-tenants-unread-notification-count',
  ORGANIZATION_DETAILS = 'organization_details',
  ORGANIZATIONS = 'organizations',
  PREVIOUS_FINDINGS_AND_RECOMMENDATIONS = 'previous-findings-and-recommendations',
  PREVIOUS_ASSESSMENT_EXISTS = 'previous-assessment-exists',
  PREVIOUSLY_USED_FACILITIES = 'previously-used-facilities',
  REPORT_SCHEDULERS = 'report-schedulers',
  REPORTS = 'reports',
  RISK_ASSESSMENT_ASSIGNMENT_USERS = 'risk-assessment-assignment-users',
  RISK_ASSESSMENT_DOCUMENTS = 'risk-assessment-documents',
  RISK_ASSESSMENT_DOCUMENTS_METRICS = 'risk-assessment-documents-metrics',
  RISK_ASSESSMENT_INVOLVED_FACILITIES = 'risk-assessment-involved-facilities',
  RISK_DETAILS = 'risk-details',
  RISK_LIST = 'risk-list',
  RISK_RELATED_ASSESSMENTS = 'risk-related-assessments',
  SCAN_GROUPS = 'scan-groups',
  SF_ACCOUNT_DETAILS = 'sf-account-details',
  SF_ACCOUNTS = 'sf-accounts',
  SIEM_ALARMS = 'siem-alarms',
  SURVEY_RELATED_DATA = 'survey-related-data',
  TENANTS = 'tenants',
  TENANTS_WITHOUT_ORGANIZATION = 'tenants-without-organization',
  USER_FORM_ORGANIZATIONS = 'user-form-organizations',
  USER_FORM_ROLES = 'user-form-roles',
  USER_FORM_TENANTS = 'user-form-tenants',
  USER_MANAGEMENT = 'user-management',
  VULNERABILITIES_LIST = 'vulnerabilities-list',
  WALKTHROUGH_AREAS = 'walkthrough-areas',
  WALKTHROUGH_LIST = 'walkthrough-list',
  WALKTHROUGH_RELATED_DATA = 'walkthrough-related-data',
  WALKTHROUGH_SURVEY = 'walkthrough-survey',
  WALKTHROUGH_SURVEY_COMPLETE_PERCENTAGE = 'walkthrough-survey-complete-percentage',
  WALKTHROUGH_SURVEY_STEPS = 'walkthrough-survey-steps',
  WORKSHEET_CONTROL_DOCUMENTS = 'worksheet-control-documents',
  WORKSHEET_DOCUMENT_ATTACHMENTS = 'worksheet-document-attachments',
  WORKSHEET_DOCUMENT_DETAILS = 'worksheet-document-details',
  WORKSHEET_DOCUMENT_POTENTIAL_RISKS = 'worksheet-document-potential-risks',
  WORKSHEET_DOCUMENT_RELATED_RISKS = 'worksheet-document-related-risks',
  WORKSHEET_DOCUMENT_RELATED_RISKS_ATTACHMENTS = 'worksheet-document-related-risks-attachments',
  WORKSHEET_DOCUMENTS_DATA = 'worksheet-documents-data',
  WORKSHEET_FINDINGS = 'worksheet-findings',
  WORKSHEET_METRICS = 'worksheet-metrics',
  XDR_ALARMS = 'xdr-alarms',
}
