export enum UserPermissionsEnum {
  // User Tenant Management
  USER_DODSON = 'User.Me:Dodson',
  UPDATE_MASTER_SSO_PREFERENCES = 'Tenant.IsMaster.SSO.Preferences:Update',
  UPDATE_TENANT_SSO_PREFERENCES = 'Tenant.SSO.Preferences:Update',
  UPDATE_MASTER_MFA_PREFERENCES = 'Tenant.IsMaster.MFA.Preferences:Update',
  UPDATE_TENANT_MFA_PREFERENCES = 'Tenant.MFA.Preferences:Update',
  VIEW_AUDIT_LOG_LIST = 'AuditLog:List',
  VIEW_TENANT_AUDIT_LOG_LIST = 'AuditLog.Tenant:List',
  UPDATE_MASTER_INTEGRATION_PREFERENCES = 'Tenant.IsMaster.Integration.Preferences:Update',
  UPDATE_TENANT_INTEGRATION_PREFERENCES = 'Tenant.Integration.Preferences:Update',
  CREATE_TENANT = 'Tenant:Create',
  ACTIVATE_TENANT = 'Tenant:Activate',
  DEACTIVATE_TENANT = 'Tenant:Deactivate',
  VIEW_TENANT_LIST = 'Tenant:List',
  VIEW_TENANT_PROFILE = 'Tenant:Get',
  UPDATE_TENANT_PROFILE = 'Tenant:Update',
  UPDATE_TENANT_LOGO = 'Tenant.Logo:Update',
  VIEW_TENANT_STATUS = 'Tenant.Status:Get',
  VIEW_TENANT_SERVICE_STATUS = 'Tenant.Service.Status:Get',
  VIEW_TENANT_CONTACT_INFORMATION = 'Tenant.ContactInformation:(Get)',
  UPDATE_TENANT_CONTACT_INFORMATION = 'Tenant.ContactInformation:Update',
  TENANT_SERVICE_STATUS_UPDATE = 'Tenant.Service.Status:Update',
  VIEW_TENANT_SERVICE_LIST = 'Tenant.Service:List',
  VIEW_TENANT_CONTACT_LIST = 'Tenant.Contact:List',
  CREATE_TENANT_CONTACT = 'Tenant.Contact:Create',
  VIEW_TENANT_CONTACT = 'Tenant.Contact:Get',
  UPDATE_TENANT_CONTACT = 'Tenant.Contact:Update',
  DELETE_TENANT_CONTACT = 'Tenant.Contact:Delete',
  VIEW_FACILITY_LIST = 'Tenant.Facility:List',
  CREATE_FACILITY = 'Tenant.Facility:Create',
  VIEW_FACILITY = 'Tenant.Facility:Get',
  UPDATE_FACILITY = 'Tenant.Facility:Update',
  DELETE_FACILITY = 'Tenant.Facility:Delete',
  VIEW_DEPARTMENT_LIST = 'Tenant.Department:List',
  CREATE_DEPARTMENT = 'Tenant.Department:Create',
  UPDATE_DEPARTMENT = 'Tenant.Department:Update',
  DELETE_DEPARTMENT = 'Tenant.Department:Delete',
  VIEW_TENANT_ROLE_LIST = 'Tenant.Role:List',
  VIEW_MASTER_TENANT_ROLE_LIST = 'Tenant.IsMaster.Role:List',
  CREATE_USER_MASTER_TENANT = 'User.InMasterTenant:Create',
  VIEW_MASTER_TENANT_USER = 'User.InMasterTenant:Get',
  UPDATE_MASTER_TENANT_USER = 'User.InMasterTenant:Update',
  DELETE_MASTER_TENANT_USER = 'User.InMasterTenant:Delete',
  CREATE_TENANT_USER = 'User:Create',
  VIEW_TENANT_USER = 'User:Get',
  UPDATE_TENANT_USER = 'User:Update',
  DELETE_TENANT_USER = 'User:Delete',
  UPDATE_MASTER_TENANT_USER_ROLE = 'User.InMasterTenant.Role:Update',
  UPDATE_TENANT_USER_ROLE = 'User.Role:Update',
  LOCK_MASTER_TENANT_USER = 'User.InMasterTenant:Lock',
  UNLOCK_MASTER_TENANT_USER = 'User.InMasterTenant:Unlock',
  LOCK_TENANT_USER = 'User:Lock',
  UNLOCK_TENANT_USER = 'User:Unlock',
  ACTIVATE_MASTER_TENANT_USER = 'User.InMasterTenant:Activate',
  DEACTIVATE_MASTER_TENANT_USER = 'User.InMasterTenant:Deactivate',
  ACTIVATE_TENANT_USER = 'User:Activate',
  DEACTIVATE_TENANT_USER = 'User:Deactivate',
  UPDATE_USER_FACILITY = 'User.Facility:Update',
  VIEW_MASTER_TENANT_USER_LIST = 'User.InMasterTenant:List',
  VIEW_TENANT_USER_LIST = 'User:List',
  VIEW_TENANT_USER_PROFILE = 'User.Me:Get',
  UPDATE_TENANT_USER_PROFILE = 'User.Me:Update',
  UPDATE_TENANT_USER_PREFERENCES = 'User.Me.Preferences:Update',
  USER_PASSWORD_RESET = 'User.Password:Reset',
  USER_MASTER_TENANT_PASSWORD_RESET = 'User.InMasterTenant.Password:Reset',
  TENANT_PREFERENCE_GET = 'Tenant.Preference:Get',
  TENANT_PREFERENCE_UPDATE = 'Tenant.Preference:Update',
  TENANT_MASTER_PREFERENCE_GET = 'Tenant.IsMaster.Preference:Get',
  CREATE_TENANT_SERVICE = 'Tenant.Service:Create',
  UPDATE_TENANT_SERVICE = 'Tenant.Service:Update',

  // Organizations
  ORGANIZATION_SERVICE_CREATE = 'Organization.Service:Create',
  ORGANIZATION_SERVICE_STATUS_VIEW = 'Organization.Service.Status:Get',
  ORGANIZATION_LINKED_TENANTS_UPDATE = 'Organization.Linked.Tenants:Update',
  ORGANIZATION_SERVICE_STATUS_UPDATE = 'Organization.Service.Status:Update',
  ORGANIZATION_PROFILE_VIEW = 'Organization:Get',

  // View dashboards (Unified, Custom, Etc.)
  VIEW_DASHBOARD_LIST = 'Dashboard:List',
  CREATE_DASHBOARD = 'Dashboard:Create',
  VIEW_DASHBOARD = 'Dashboard:Get',
  UPDATE_DASHBOARD = 'Dashboard:Update',
  DELETE_DASHBOARD = 'Dashboard:Delete',
  VIEW_CLIENTS_AND_SERVICES = 'ClientsAndServices:List',

  // ANALYTIC
  VIEW_ANALYTIC_OVERALL_SCORE = 'Analytic.OverallFortifiedScore:Get',
  VIEW_ANALYTIC_SERVICE_LINE_SCORE = 'Analytic.ServiceLineScore:Get',
  VIEW_ANALYTIC_IMPROVEMENT_RECOMMENDATIONS_SCORE = 'Analytic.ImprovementRecommendationsScore:Get',
  VIEW_ANALYTIC_ROI = 'Analytic.ROI:Get',
  PERFORM_COMPARATIVE_ANALYTIC = 'Analytic.ComparativeAnalytic:Perform',

  // VTM
  VTM_SCANNER_CREATE = 'VTM.Scanner:Create',
  VTM_SCANNER_UPDATE = 'VTM.Scanner:Update',
  VTM_SCANNER_DELETE = 'VTM.Scanner:Delete',
  VIEW_VTM_DASHBOARDS = 'VTM.Dashboard:List',
  VIEW_VTM_SERVICE = 'VTM.Service:Get',
  UPDATE_VTM_DASHBOARD_PREFERENCES = 'VTM.Dashboard.Preferences:Update',
  VIEW_VULNERABILITY_LIST = 'Vulnerability:List',
  VULNERABILITY_GET = 'Vulnerability:Get',
  EXPORT_VULNERABILITY = 'Vulnerability:Export',
  VULNERABILITY_USER_UPDATE = 'Vulnerability.User:Update',
  VULNERABILITY_USER_MASTER_UPDATE = 'Vulnerability.User.InMasterTenant:Update',
  UPDATE_VULNERABILITY_STATUS = 'Vulnerability.Status:Update',
  VIEW_HOST_LIST = 'Host:List',
  EXPORT_HOST = 'Host:Export',
  ASSIGN_HOST_TO_USER = 'Host.User:Update',
  CHANGE_HOST_STATUS = 'Host.Status:Update',
  ASK_VTM = 'Vulnerability:Ask',
  VULNERABILITY_ACCEPT = 'Vulnerability:Accept',
  VULNERABILITY_UNACCEPT = 'Vulnerability:Unaccept',
  VTM_ACCEPTED_VULNERABILITIES_LIST = 'VTM.Accepted.Vulnerabilities:List',
  EXPORT_VTM_ONE_TIME_REPORT = 'VTM:Export',
  SCAN_RESULT_REPORT_LIST = 'ScanResult.Report:List',
  VTM_SCAN_LIST = 'VTM.Scan:List',
  GROUP_SCAN_RESULT_LIST = 'ScanResult.Group:List',
  SCAN_RESULT_GROUP_CREATE = 'ScanResult.Group:Create',
  UNGROUP_SCAN_RESULT_LIST = 'ScanResult.Ungrouped:List',
  GROUP_EXTERNAL_SCAN_RESULT_LIST = 'ScanResult.External:List',
  SCAN_RESULT_REPORT_CREATE = 'ScanResult.Report:Create',
  CREATE_INTERNAL_SCAN_RESULT = 'ScanResult.Internal:Create',
  VIEW_INTERNAL_SCAN_RESULT = 'ScanResult.Internal:Get',
  UPDATE_INTERNAL_SCAN_RESULT = 'ScanResult.Internal:Update',
  DELETE_INTERNAL_SCAN_RESULT = 'ScanResult.Internal:Delete',
  CREATE_EXTERNAL_SCAN_RESULT = 'ScanResult.External:Create',
  VIEW_EXTERNAL_SCAN_RESULT = 'ScanResult.External:Get',
  UPDATE_EXTERNAL_SCAN_RESULT = 'ScanResult.External:Update',
  DELETE_EXTERNAL_SCAN_RESULT = 'ScanResult.External:Delete',
  CREATE_INTERNAL_SCAN = 'Scan.Internal:Create',
  VIEW_INTERNAL_SCAN = 'Scan.Internal:Get',
  UPDATE_INTERNAL_SCAN = 'Scan.Internal:Update',
  DELETE_INTERNAL_SCAN = 'Scan.Internal:Delete',
  CREATE_EXTERNAL_SCAN = 'Scan.External:Create',
  VIEW_EXTERNAL_SCAN = 'Scan.External:Get',
  UPDATE_EXTERNAL_SCAN = 'Scan.External:Update',
  DELETE_EXTERNAL_SCAN = 'Scan.External:Delete',
  VIEW_VTM_ALERT_LIST = 'VTM.Alert:List',
  UPDATE_VTM_ALERT_STATUS = 'VTM.Alert.Status:Update',
  EXPORT_VTM_ALERT = 'VTM.Alert:Export',
  VTM_SCAN_UPLOAD = 'VTM.Scan:Upload',
  VULNERABILITY_MARK_FOR_REVIEW = 'Vulnerability:MarkForReview',
  VTM_DISCUSSION_DETAILS_LIST = 'VTM.DiscussionDetails:List',

  // SIEM
  VIEW_SIEM_DASHBOARDS = 'SIEM.Dashboard:List',
  VIEW_SIEM_SERVICE = 'SIEM.Service:Get',
  UPDATE_SIEM_DASHBOARD_PREFERENCES = 'SIEM.Dashboard.Preferences:Update',
  EXPORT_SIEM_SUMMARY_REPORT = 'SIEM:Export',
  VIEW_SIEM_ALERT_LIST = 'SIEM.Alert:List',
  UPDATE_SIEM_ALERT_STATUS = 'SIEM.Alert.Status:Update',
  EXPORT_SIEM_ALERT_LIST = 'SIEM.Alert:Export',
  ASSIGN_SIEM_ALERT_TO_USER = 'SIEM.Alert.User:Update',
  SIEM_ALERT_ASK = 'SIEM.Alert:Ask',
  DOWNLOAD_ESCALATIONS = 'Escalations:Export',

  // MDR
  VIEW_MDR_SERVICE = 'MDR.Service:Get',
  VIEW_MDR_DASHBOARD = 'MDR.Dashboard:List',
  MDR_ALARM_ASK = 'MDR.Alarm:Ask',

  // XDR
  VIEW_XDR_SERVICE = 'XDR.Service:Get',

  // IOMT
  VIEW_IOMT_SERVICE = 'IoMT.Service:Get',
  VIEW_IOMT_DASHBOARD = 'IoMT.Dashboard:List',
  IOMT_ALARM_ASK = 'IoMT.Alarm:Ask',
  EXPORT_IOMT_ALERT = 'IoMT.Alarms:Export',

  // OTHERS
  VIEW_NOTIFICATIONS = 'Notification:List',

  // COMMUNICATION THREAD
  SEND_THREAD_MESSAGE = 'CT:Message:Send',
  GLOBAL_SEARCH_PERFORM = 'GlobalSearch:Perform',

  ESCALATION_ACKNOWLEDGE = 'Escalation:Acknowledge',
  ESCALATION_APPROVE = 'Escalation:Approve',
  ESCALATION_ASK = 'Escalation:Ask',
  ESCALATION_EDIT_TEMPLATE = 'Escalation:EditTemplate',
  ESCALATION_MARK_AS_REMEDIATED = 'Escalation:MarkAsRemediated',
  ESCALATION_PREVIEW_TEMPLATE = 'Escalation:PreviewTemplate',
  ESCALATION_USER_MASTER_UPDATE = 'Escalation.User.InMasterTenant:Update',
  ESCALATION_USER_UPDATE = 'Escalation.User:Update',
  ESCALATION_VIEW_DETAILS = 'Escalation:Get',
  ESCALATION_VIEW_LIST = 'Escalations:List',

  // Advisory Services
  RISK_ASSESSMENT = 'RiskAssessment.Service:Get',
  RISK_ASSESSMENT_CARRY_OVER_DOCUMENT_LIST = 'RiskAssessment.Carry-Over.Document:List',
  RISK_ASSESSMENT_CARRY_OVER_DOCUMENT_ATTACHMENT_CREATE = 'RiskAssessment.Carry-Over.Document.Attachment:Create',
  RISK_ASSESSMENT_CONTROL_COMPLETE = 'RiskAssessment.Control:Complete',
  RISK_ASSESSMENT_CONTROL_FINDING_LIST = 'RiskAssessment.Control.Finding:List',
  RISK_ASSESSMENT_CONTROL_FINDING_CREATE = 'RiskAssessment.Control.Finding:Create',
  RISK_ASSESSMENT_CONTROL_NOTE_UPDATE = 'RiskAssessment.Control.Note:Update',
  RISK_ASSESSMENT_CONTROL_POTENTIAL_RISK_CREATE = 'RiskAssessment.Control.PotentialRisk:Create',
  RISK_ASSESSMENT_CONTROL_POTENTIAL_RISK_DELETE = 'RiskAssessment.Control.PotentialRisk:Delete',
  RISK_ASSESSMENT_CONTROL_POTENTIAL_RISK_UPDATE = 'RiskAssessment.Control.PotentialRisk:Update',
  RISK_ASSESSMENT_CONTROL_RELATED_RISK_LIST = 'RiskAssessment.Control.RelatedRisk:List',
  RISK_ASSESSMENT_CONTROL_REOPEN = 'RiskAssessment.Control:ReOpen',
  RISK_ASSESSMENT_CONTROL_SCORING_UPDATE = 'RiskAssessment.Control.Scoring:Update',
  RISK_ASSESSMENT_CONTROLS_LIST = 'RiskAssessment.Control:List',
  RISK_ASSESSMENT_CREATE = 'RiskAssessment.Assessment:Create',
  RISK_ASSESSMENT_DRAFT_REPORT_CREATE = 'RiskAssessment.DraftReport:Create',
  RISK_ASSESSMENT_DOCUMENT_ASK = 'RiskAssessment.Document:Ask',
  RISK_ASSESSMENT_DOCUMENT_ATTACHMENT_CREATE = 'RiskAssessment.Document.Attachment:Create',
  RISK_ASSESSMENT_DOCUMENT_ATTACHMENT_DELETE = 'RiskAssessment.Document.Attachment:Delete',
  RISK_ASSESSMENT_DOCUMENT_ATTACHMENT_NOTE_UPDATE = 'RiskAssessment.Document.Attachment.Note:Update',
  RISK_ASSESSMENT_DOCUMENT_ATTACHMENT_TYPE_UPDATE = 'RiskAssessment.Document.Attachment.Type:Update',
  RISK_ASSESSMENT_DOCUMENT_MARK_AS_COMPLETE = 'RiskAssessment.Document:MarkAsComplete',
  RISK_ASSESSMENT_DOCUMENT_NOTES_UPDATE = 'RiskAssessment.Document.Note:Update',
  RISK_ASSESSMENT_DOCUMENT_REOPEN = 'RiskAssessment.Document:Reopen',
  RISK_ASSESSMENT_DOCUMENT_REVIEW = 'RiskAssessment.Document:Review',
  RISK_ASSESSMENT_DOCUMENT_UPDATE = 'RiskAssessment.Document:Update',
  RISK_ASSESSMENT_DOCUMENTS_FOLDER_CREATE = 'RiskAssessment.Documents.Folder:Create',
  RISK_ASSESSMENT_FINAL_REPORT_REMOVE = 'RiskAssessment.FinalReport:Remove',
  RISK_ASSESSMENT_FINAL_REPORT_UPLOAD = 'RiskAssessment.FinalReport:Upload',
  RISK_ASSESSMENT_LIST = 'RiskAssessment.Assessment:List',
  RISK_ASSESSMENT_REPORT_CREATE = 'RiskAssessment.Report:Create',
  RISK_ASSESSMENT_SURVEY_COMPLETE = 'RiskAssessment.Survey:Complete',
  RISK_ASSESSMENT_SURVEY_CREATE = 'RiskAssessment.Survey:Create',
  RISK_ASSESSMENT_SURVEY_EXPORT = 'RiskAssessment.Survey:Export',
  RISK_ASSESSMENT_SURVEY_GET = 'RiskAssessment.Survey:Get',
  RISK_ASSESSMENT_SURVEY_PASS = 'RiskAssessment.Survey:Pass',
  RISK_ASSESSMENT_SURVEY_USER_UPDATE = 'RiskAssessment.Survey.User:Update',
  RISK_ASSESSMENT_UPDATE = 'RiskAssessment.Assessment:Update',
  RISK_ASSESSMENT_USER_UPDATE = 'RiskAssessment.Assessment.User:Update',
  RISK_ASSESSMENT_WALKTHROUGH_DELETE = 'RiskAssessment.Walkthrough:Delete',
  RISK_ASSESSMENT_WALKTHROUGH_ATTACHMENT_CREATE = 'RiskAssessment.Walkthrough.Attachment:Create',
  RISK_ASSESSMENT_WALKTHROUGH_ATTACHMENT_EXPORT = 'RiskAssessment.Walkthrough.Attachment:Export',
  RISK_ASSESSMENT_WALKTHROUGH_ATTACHMENT_DELETE = 'RiskAssessment.Walkthrough.Attachment:Delete',
  RISK_ASSESSMENT_WALKTHROUGH_ASSIGN = 'RiskAssessment.Walkthrough:Assign',
  RISK_ASSESSMENT_WALKTHROUGH_CREATE = 'RiskAssessment.Walkthrough:Create',
  RISK_ASSESSMENT_WALKTHROUGH_EXPORT = 'RiskAssessment.Walkthrough:Export',
  RISK_ASSESSMENT_WALKTHROUGH_EDIT = 'RiskAssessment.Walkthrough:Edit',

  RISK_MARK_AS_ACCEPTED = 'RiskRegister.Risk:MarkAsAccepted',
  RISK_MARK_AS_REMEDIATED = 'RiskRegister.Risk:MarkAsRemediated',
  RISK_REGISTER = 'RiskRegister.Service:Get',
  RISK_REGISTER_ATTACHMENT_CREATE = 'RiskRegister.Risk.Attachment:Create',
  RISK_REGISTER_ATTACHMENT_DELETE = 'RiskRegister.Risk.Attachment:Delete',
  RISK_REGISTER_CAP_RISK_CREATE = 'RiskRegister.CAP.Risk:Create',
  RISK_REGISTER_CAP_RISK_CALLNOTE_CREATE = 'RiskRegister.CAP.Risk:CallNote:Create',
  RISK_REGISTER_CAP_RISK_CALLNOTE_UPDATE = 'RiskRegister.CAP.Risk:CallNote:Update',
  RISK_REGISTER_CAP_RISK_CALLNOTE_DELETE = 'RiskRegister.CAP.Risk:CallNote:Delete',
  RISK_REGISTER_CAP_RISK_CALLNOTE_MANAGE_FCC = 'RiskRegister.CAP.Risk:CallNote:Manage:Fcc',
  RISK_REGISTER_CAP_RISK_CALLNOTE_MANAGE_CLIENT = 'RiskRegister.CAP.Risk:CallNote:Manage:Client',
  RISK_REGISTER_CAP_RISK_EDIT = 'RiskRegister.CAP.Risk:Edit',
  RISK_REGISTER_CAP_RISK_DELETE = 'RiskRegister.CAP.Risk:Delete',
  RISK_REGISTER_CAP_RISK_LIST = 'RiskRegister.CAP.Risk:List',
  RISK_REGISTER_CAP_RISK_STALLED = 'RiskRegister.CAP.Risk:Stalled',
  RISK_REGISTER_COMMENT_CREATE = 'RiskRegister.Risk.Comment:Create',
  RISK_REGISTER_COMMENTS_LIST = 'RiskRegister.Risk.Comment:List',
  RISK_REGISTER_CONTROL_RISK_CREATE = 'RiskRegister.Control.Risk:Create',
  RISK_REGISTER_CREATE = 'RiskRegister.Risk:Create',
  RISK_REGISTER_DELETE = 'RiskRegister.Risk:Delete',
  RISK_REGISTER_DISCUSSION_DETAILS_LIST = 'RiskRegister.DiscussionDetails:List',
  RISK_REGISTER_ESCALATION_RISK_CREATE = 'RiskRegister.Escalations.Risk:Create',
  RISK_REGISTER_MARK_FOR_REVIEW = 'RiskRegister:MarkForReview',
  RISK_REGISTER_REOPEN = 'RiskRegister.Risk:Reopen',
  RISK_REGISTER_UPDATE = 'RiskRegister.Risk:Update',
  RISK_REGISTER_VTM_CREATE = 'RiskRegister.VTM.Risk:Create',
  RISK_USER_UPDATE = 'RiskRegister.User:Update',

  // Reports
  VIEW_MDR_REPORTS = 'MDR.Report:List',
  VIEW_IOMT_REPORTS = 'IoMT.Report:List',
  VIEW_SIEM_REPORTS = 'SIEM.Report:List',
  VIEW_RISK_ASSESSMENT_REPORTS = 'RiskAssessment.Report:List',
  VIEW_RISK_REGISTER_REPORTS = 'RiskRegister.Report:List',
  VIEW_VTM_REPORTS = 'VTM.Report:List',
  VIEW_COMPARATIVE_ANALYTICS_REPORTS = 'Analytics.Report:List',

  // UI PERMISSIONS
  ASSESSMENT_SURVEY_EDIT_UI = 'ASSESSMENT_SURVEY_EDIT_UI',
  ASSESSMENT_SURVEY_UPDATE_BY_ASSIGNEE_UI = 'ASSESSMENT_SURVEY_UPDATE_BY_ASSIGNEE_UI',
  ASSIGNMENT_BY_FILTERS_UI = 'ASSIGNMENT_BY_FILTERS_UI',
  ASSIGNMENT_TO_FILTERS_UI = 'ASSIGNMENT_TO_FILTERS_UI',
  CHAT_UI = 'CHAT_UI',
  GENERATE_NEW_REPORT_UI = 'GENERATE_NEW_REPORT_UI',
  GLOBAL_SEARCH_NOTIFY_UI = 'GLOBAL_SEARCH_NOTIFY_UI',
  HOST_DETAILS_SCAN_INFO_UI = 'HOST_DETAILS_SCAN_INFO_UI',
  REPORT_STATUS_WS_CHANNEL_UI = 'REPORT_STATUS_WS_CHANNEL_UI',
  SCHEDULE_REPORT_UI = 'SCHEDULE_REPORT_UI',
  USER_ASSIGNMENT_UI = 'USER_ASSIGNMENT_UI',
  VIEW_REPORTS_GENERATED_BY_OTHERS_UI = 'VIEW_REPORTS_GENERATED_BY_OTHERS_UI',
  WALKTHROUGH_ASSIGNMENT_BY_FILTERS_UI = 'WALKTHROUGH_ASSIGNMENT_BY_FILTERS_UI',
  WALKTHROUGH_ASSIGNMENT_TO_FILTERS_UI = 'WALKTHROUGH_ASSIGNMENT_TO_FILTERS_UI',
  WALKTHROUGH_FACILITY_NOTES_EDIT_UI = 'WALKTHROUGH_FACILITY_NOTES_EDIT_UI',

  // INCIDENT REPORT
  VIEW_CREATE_SUPPORT_REQUEST_BUTTON = 'IncidentReport:Send',
}
