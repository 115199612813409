import React from 'react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { UserPreferencesService } from '../services/user-preferences.service';
import { IUserPreference } from '../interfaces/user-preferences.type';
import { ThemesEnum } from '../enums/themes.enum';
import { getThemeSelector } from '../store/slices/user-preferences/user-preferences.selector';
import { useSelector } from '../store';
import { createCustomTheme } from './index';
import '../App.css';

function CustomThemeProvider({ children }: { children: React.ReactElement }): React.ReactElement {
  const updatedTheme: IUserPreference = useSelector(getThemeSelector);
  const currentTheme = UserPreferencesService.getUserTheme();
  const theme = createCustomTheme({
    theme: (updatedTheme?.value as ThemesEnum) || currentTheme || ThemesEnum.LIGHT,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CustomThemeProvider;
