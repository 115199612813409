import { useEffect } from 'react';

import { EventListEnum, EventService } from 'src/services/event.service';

export default function useEventService<T>(events: EventListEnum[], callback: (event: CustomEvent<T>) => void): void {
  useEffect(() => {
    events.forEach((event) => EventService.subscribe<T>(event, callback));
    return (): void => events.forEach((event) => EventService.unsubscribe<T>(event, callback));
  }, []);
}
