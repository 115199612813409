import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBreadcrumb } from 'src/shared/components/breadcrumbs/breadcrumbs.type';

export const tenantManagementSlice = createSlice({
  name: 'tenantManagement',
  initialState: { breadcrumbs: [] },
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<IBreadcrumb[]>) => {
      state.breadcrumbs = action.payload;
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
  },
});

const { actions } = tenantManagementSlice;
export const setBreadcrumbs = actions.setBreadcrumbs;
export const clearBreadcrumbs = actions.clearBreadcrumbs;
