import { useEffect, useRef, useState } from 'react';

import openSocket from 'socket.io-client';

import { AuthService } from '../services/auth.service';

interface IWSAdditionalParams {
  ClientId?: string;
  ThreadId?: string;
}

interface IProps {
  url: string;
  params?: IWSAdditionalParams;
  permission?: boolean;
}

export default function useWebSocketService<T>({
  url,
  params = {},
  permission = true,
}: IProps): {
  lastMessage: T;
} {
  const socketRef = useRef(null);
  const [lastMessage, setLastMessage] = useState<T>(null);

  const getAccessToken = async (): Promise<string> => {
    const session = await AuthService.getCurrentSession();
    return session.getAccessToken().getJwtToken();
  };

  const openSocketChannel = async (): Promise<void> => {
    socketRef.current = openSocket(url, {
      path: '/api/ws/socket.io',
      transports: ['websocket'],
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 2000,
      auth: {
        Authorization: `Bearer ${await getAccessToken()}`,
        ...params,
      },
    });

    socketRef.current.on('disconnect', async (reason: T) => {
      if (reason === 'io server disconnect') {
        socketRef.current.auth.Authorization = `Bearer ${await getAccessToken()}`;
        socketRef.current.connect();
      }
    });

    socketRef.current.on('message', (data: T) => {
      setLastMessage(data);
    });
  };

  useEffect(() => {
    socketRef?.current?.disconnect?.();

    if (!url) {
      return;
    }

    permission && openSocketChannel();

    return (): void => {
      socketRef?.current?.disconnect();
    };
  }, [url]);

  return { lastMessage };
}
