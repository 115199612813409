import React from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { Theme } from '@mui/material/styles';

const classes = {
  bellIcon: {
    color: 'text.secondary',
    opacity: 0.8,

    '& .MuiBadge-badge': {
      backgroundColor: 'text.error',
      top: '18%',
      right: '18%',
      boxShadow: `0 0 0 2px`,
      color: (t: Theme): string => t.palette.background.default,
    },
  },
};

interface IProps {
  isBadgeShown: boolean;
}

export default function NotificationBellIcon({ isBadgeShown }: IProps): React.ReactElement {
  return (
    <Badge variant="dot" overlap="circular" invisible={!isBadgeShown} sx={classes.bellIcon}>
      <NotificationsIcon />
    </Badge>
  );
}
