import { createSlice } from '@reduxjs/toolkit';

import { IEscalation } from '../../../shared/modules/escalations/types/escalation.type';

export type IEscalationChat = Partial<IEscalation> & { description: string; clientId: string };

const initialState: { escalation: IEscalationChat } = {
  escalation: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatEscalation(state, action) {
      state.escalation = action.payload;
    },
    clearChatEscalation(state) {
      state.escalation = null;
    },
  },
});

const { actions } = chatSlice;
export const setChatEscalation = actions.setChatEscalation;
export const clearChatEscalation = actions.clearChatEscalation;
