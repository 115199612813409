import axios from 'axios';
import { useSnackbar } from 'notistack';

import { ToastMessageSeveritiesEnum } from '../enums/toast-message-severities.enum';
import { ToastMessages } from '../constants/toast-messages.constant';

interface IUseHandlePromiseError {
  handleError: (error: Error, onError?: () => void) => void;
}

export default function useHandlePromiseError(): IUseHandlePromiseError {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: Error, onError?: () => void): void => {
    if (axios.isCancel(error)) return;

    onError?.();
    enqueueSnackbar(ToastMessages.generalError, { variant: ToastMessageSeveritiesEnum.ERROR });
  };

  return { handleError };
}
