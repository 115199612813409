import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';

export const emailDetailsTypes = [
  NotificationTypesEnum.ASSESSMENT_DOCUMENT,
  NotificationTypesEnum.EMAIL_REDIRECT_DETAILS,
  NotificationTypesEnum.ESCALATION_ASSIGNMENT,
  NotificationTypesEnum.ESCALATION_DETAILS,
  NotificationTypesEnum.ESCALATION_THREAD,
  NotificationTypesEnum.RISK_ASSIGNMENT,
  NotificationTypesEnum.VULNERABILITY_ASSIGNMENT,
];
