import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import IconButton from '@mui/material/IconButton';
import { SnackbarKey, useSnackbar } from 'notistack';

import { TrackUserActionsService } from 'src/services/track-user-actions.service';
import useWebSocketService from 'src/hooks/use-web-socket-service.hook';
import { IWSReport } from 'src/interfaces/report.type';
import { IWSMessageWithPayload } from 'src/interfaces/ws-message.type';
import { ReportStatusesEnum } from 'src/enums/reports/report-statuses.enum';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { downloadFileByUrl } from 'src/utils/download-file.util';
import { AuditLogData } from 'src/constants/audit-log-data.constant';
import { ToastMessages } from 'src/constants/toast-messages.constant';
import { wsBaseUrl } from 'src/api/axios';
import FccButton from 'src/shared/components/controls/button';

export function useGeneratedReport(): void {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { lastMessage } = useWebSocketService<IWSMessageWithPayload<IWSReport>>({
    url: `${wsBaseUrl}/report`,
  });

  const showSuccessSnackbar = (report: IWSReport): SnackbarKey => {
    const message = `A new report "${report.filename}" is available.`;
    return enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      variant: ToastMessageSeveritiesEnum.INFO,
      persist: true,
      action: (key) => action(key, report),
    });
  };

  const showErrorSnackbar = (): SnackbarKey => {
    return enqueueSnackbar(ToastMessages.generalError, {
      variant: ToastMessageSeveritiesEnum.ERROR,
    });
  };

  const showSnackbar = (payload: IWSReport): SnackbarKey => {
    switch (payload.status) {
      case ReportStatusesEnum.FINISHED: {
        return showSuccessSnackbar(payload);
      }
      case ReportStatusesEnum.FAILED: {
        return showErrorSnackbar();
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    let snackbarKey: SnackbarKey;
    if (lastMessage) {
      snackbarKey = showSnackbar(lastMessage.payload);
    }

    return (): void => snackbarKey && closeSnackbar(snackbarKey);
  }, [lastMessage]);

  const action = (key: SnackbarKey, report: IWSReport): React.ReactElement => (
    <>
      <FccButton
        sx={{ color: '#fff' }}
        variant="text"
        startIcon={<GetAppOutlinedIcon fontSize={'small'} />}
        onClick={(): void => {
          TrackUserActionsService.logUserAction({
            ...AuditLogData.REPORT_DOWNLOAD,
            message: `User downloaded ${report?.category} report to pdf`,
          });
          downloadFileByUrl(report.presigned_url);
          closeSnackbar(key);
        }}
      >
        Download
      </FccButton>
      <IconButton
        onClick={(): void => closeSnackbar(key)}
        aria-label="close"
        size="small"
        sx={{ color: '#fff', ml: 2 }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
}
