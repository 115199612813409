import { CurrentUserService } from '../services/current-user.service';
import { ClientService } from 'src/services/client.service';

export function reportsGuard(): boolean {
  const hasPermission = [
    CurrentUserService.permissions.VIEW_MDR_REPORTS,
    CurrentUserService.permissions.VIEW_IOMT_REPORTS,
    CurrentUserService.permissions.VIEW_SIEM_REPORTS,
    CurrentUserService.permissions.VIEW_RISK_ASSESSMENT_REPORTS,
    CurrentUserService.permissions.VIEW_RISK_REGISTER_REPORTS,
    CurrentUserService.permissions.VIEW_VTM_REPORTS,
    CurrentUserService.permissions.VIEW_COMPARATIVE_ANALYTICS_REPORTS,
  ].some(Boolean);
  return hasPermission && ClientService.isClientViewMode();
}
