import { ClientService } from '../../services/client.service';
import { CurrentUserService } from 'src/services/current-user.service';
import { INavBarItem, INavBarSection } from './navbar/navbar.type';
import { ClientServicesEnum } from '../../enums/client-services.enum';
import { AppLayersEnum } from './utils/app-layers.enum';
import { TenantServiceStatusEnum } from 'src/enums/tenant-service-status.enum';

export class MainLayoutService {
  public static filterMenuOptions(sectionList: INavBarSection[]): INavBarSection[] {
    const services = ClientService.getClientServicesByUserAccess();
    const menuLayer = MainLayoutService.getCurrentMenuLayer();

    return sectionList
      .map((sectionListItem: INavBarSection) => ({
        ...sectionListItem,
        items: MainLayoutService.filterSectionItems(services, menuLayer, sectionListItem),
      }))
      .filter((sectionListItem: INavBarSection) => sectionListItem.items.length);
  }

  private static filterSectionItems(
    servicesByRole: ClientServicesEnum[],
    menuLayer: AppLayersEnum,
    sectionListItem: INavBarSection,
  ): INavBarItem[] {
    return sectionListItem.items
      .filter(({ layers, checkAccess }: INavBarItem) => layers.includes(menuLayer) && checkAccess(servicesByRole))
      .reduce<INavBarItem[][]>(MainLayoutService.sortInActiveMenuOptions(servicesByRole), [])
      .flatMap((item) => item)
      .map(MainLayoutService.buildClientRoutesPath(menuLayer));
  }

  private static sortInActiveMenuOptions(
    servicesByRole: ClientServicesEnum[],
  ): (acc: INavBarItem[][], cur: INavBarItem) => INavBarItem[][] {
    return (acc: INavBarItem[][], cur: INavBarItem): INavBarItem[][] => {
      const isInactive = cur.isInactive?.(servicesByRole);
      const active = [...(acc[0] || []), ...(isInactive ? [] : [cur])];
      const inactive = [...(acc[1] || []), ...(isInactive ? [cur] : [])];

      return [[...active], [...inactive]];
    };
  }

  private static buildClientRoutesPath(menuLayer: AppLayersEnum): (item: INavBarItem) => INavBarItem {
    return (item: INavBarItem) => {
      if (menuLayer === AppLayersEnum.ADMIN) {
        return item;
      }

      return { ...item, href: `${MainLayoutService.getRoutePath()}${item.href}` };
    };
  }

  public static getRoutePath(): string {
    const organizationId = ClientService.getSelectedOrganization()?.client_id;
    const tenantId = ClientService.getSelectedClient()?.client_id;

    const orgParam = organizationId ? `/organizations/${organizationId}` : '';
    const clientParam = tenantId ? `/tenants/${tenantId}` : '';
    return orgParam || clientParam ? `${orgParam}${clientParam}` : '';
  }

  private static getCurrentMenuLayer(): AppLayersEnum {
    const layers = [
      { name: AppLayersEnum.ADMIN, checkAccess: ClientService.isAdminViewMode },
      { name: AppLayersEnum.ORGANIZATION, checkAccess: ClientService.isOrgViewMode },
      { name: AppLayersEnum.CLIENT, checkAccess: ClientService.isClientViewMode },
    ];

    return layers.find((item) => item.checkAccess())?.name;
  }

  public static checkClientServiceAccess(
    hasPermission: boolean,
    service: ClientServicesEnum,
    servicesByRole: ClientServicesEnum[] = [],
  ): boolean {
    return hasPermission && servicesByRole.includes(service);
  }

  public static checkShowServiceTailIcon(serviceName: ClientServicesEnum): boolean {
    const services = ClientService.getAllClientServices();
    const isFccSide = CurrentUserService.isUserInCoreTeam;
    const currentService = services.find((item) => item.name === serviceName);
    return isFccSide && !!currentService && currentService.status !== TenantServiceStatusEnum.LIVE;
  }
}
