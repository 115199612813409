import { Theme } from '@mui/material/styles';

import { ICssProperties, ICssStyle } from 'src/interfaces/css-style.type';
import { ThemesEnum } from '../../enums/themes.enum';

export const commonStyle: ICssStyle = {
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  shadowScrollArea: (
    theme: Theme,
    bg = theme.palette.background.paper,
    shadowColor = theme.palette.mode === ThemesEnum.LIGHT ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)',
    hideShadowColor = theme.palette.mode === ThemesEnum.LIGHT ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)',
  ): ICssProperties => ({
    position: 'relative',
    zIndex: 1,
    background: `${bg} no-repeat`,
    backgroundImage: `linear-gradient(to bottom, ${shadowColor}, transparent), linear-gradient(to top, ${shadowColor}, transparent)`,
    backgroundPosition: '0 0, 0 100%',
    backgroundSize: '100% 12px',

    '&:after, &:before': {
      content: '""',
      position: 'relative',
      zIndex: -1,
      display: 'block',
      height: '20px',
      margin: '0 0 -20px',
      background: `linear-gradient(to bottom, ${bg}, ${bg} 60%, ${hideShadowColor})`,
    },

    '&:after': {
      margin: '-20px 0 0',
      background: `linear-gradient(to bottom, ${hideShadowColor}, ${bg} 50%, ${bg})`,
    },
  }),
};
