import { v4 } from 'uuid';

const triggeringDelay = 1000;
const cleaningDelay = 5000;

const createIFrame = (name: string): HTMLIFrameElement => {
  const iFrame = document.createElement('iframe');
  iFrame.setAttribute('name', name);
  iFrame.setAttribute('id', name);
  iFrame.style.setProperty('display', 'none');
  document.body.appendChild(iFrame);
  return iFrame;
};

const saveFile = (url: string, fileName: string, frameName: string): void => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.setAttribute('target', frameName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFileByUrl = (url: string, fileName?: string): void => {
  const frameName = `downloadIframe-${v4()}`;
  const iFrame = createIFrame(frameName);

  saveFile(url, fileName, frameName);
  setTimeout(() => iFrame.remove(), cleaningDelay);
};

export const downloadMultipleFilesByUrls = (urls: string[]): void => {
  urls.forEach((url, index) => setTimeout(() => downloadFileByUrl(url), (index + 1) * triggeringDelay));
};
