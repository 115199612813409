export enum MenuOptionsEnum {
  AUDIT_LOG = 'audit-log',
  CLIENTS = 'clients',
  COMPARATIVE_ANALYTICS = 'comparative-analytics',
  ESCALATIONS = 'escalations',
  FEATURE_REQUEST = 'feature-request',
  GLOBAL_SETTINGS = 'global-settings',
  INCIDENT_RESPONSE = 'incident-response',
  IOMT = 'iomt',
  MDR = 'mdr',
  MSAT = 'msat',
  ORG_TENANTS = 'tenants',
  ORGANIZATION_PROFILE = 'organization-profile',
  PENTESTING = 'pen-testing',
  REPORTS = 'reports',
  RISK_ASSESSMENT = 'risk-assessment',
  RISK_REGISTER = 'risk-register',
  SETTINGS = 'settings',
  SIEM = 'siem',
  TENANT_MANAGEMENT = 'tenant-management',
  TENANT_PROFILE = 'tenant-profile',
  TPRM = 'tprm',
  UNIFIED_DASHBOARD = 'unified-dashboard',
  USER_MANAGEMENT = 'user-management',
  VISP = 'vciso',
  VTM = 'vtm',
  XDR = 'xdr',
}
