import { CurrentUserService } from './current-user.service';
import { IUser } from '../interfaces/user.type';
import { GtmEventsEnum } from '../enums/gtm/gtm-events.enum';
import { gtmSendEvent } from '../utils/gtm-data-layer.util';

export class GtmService {
  public static sendAuthEvent(user: IUser): void {
    const authMethod = sessionStorage.getItem('authMethod');
    authMethod &&
      gtmSendEvent({
        event: GtmEventsEnum.LOGIN,
        method: authMethod,
        user_role: user.roles?.map((role) => role.name)?.join(', '),
        tenant: user.tenant?.name,
      });
    sessionStorage.removeItem('authMethod');
  }

  public static sendAssociatedUserEvent(user: IUser): void {
    gtmSendEvent({
      event: GtmEventsEnum.ASSOCIATE_USER,
      user_role: user.roles?.map((role) => role.name)?.join(', '),
      tenant: user.tenant?.name,
      user_name: user.username,
    });
  }

  public static sendCustomEvent(data: Record<string, unknown>): void {
    gtmSendEvent({
      ...data,
      user_role: CurrentUserService.roles?.join(', '),
      tenant: CurrentUserService.userTenantName,
      user_name: CurrentUserService.user.username,
    });
  }
}
