import { CurrentUserService } from '../services/current-user.service';
import { UserPermissionsEnum } from '../enums/user-permissions.enum';
import { UserRolesEnum } from '../enums/user-roles.enum';
import { riskAssessmentRoles, riskRegisterRoles } from './user-roles.constant';

const {
  GLOBAL_ADMIN,
  TENANT_ADMIN,
  THREAT_ANALYST,
  BUSINESS_UNIT_LEADER,
  HELP_DESK,
  OWNER,
  ANALYST,
  LEADER,
  OBSERVER,
  DODSON_MODE,
  ESCALATION_APPROVER,
  RA_CLIENT_ADMIN,
  RA_FCC_ADMIN,
  RA_FCC_ANALYST,
  RA_CLIENT_ANALYST,
  RR_CLIENT_ADMIN,
  RR_FCC_ADMIN,
  RR_FCC_ANALYST,
  RR_CLIENT_ANALYST,
} = UserRolesEnum;

export const uiPermissionsMap = [
  {
    permission: UserPermissionsEnum.ASSIGNMENT_TO_FILTERS_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        OWNER,
        ANALYST,
        GLOBAL_ADMIN,
        RA_CLIENT_ADMIN,
        RA_FCC_ADMIN,
        RA_FCC_ANALYST,
        RA_CLIENT_ANALYST,
        RR_CLIENT_ADMIN,
        RR_FCC_ADMIN,
        RR_FCC_ANALYST,
        RR_CLIENT_ANALYST,
      ]),
  },
  {
    permission: UserPermissionsEnum.ASSIGNMENT_BY_FILTERS_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        THREAT_ANALYST,
        HELP_DESK,
        OWNER,
        ANALYST,
        ESCALATION_APPROVER,
        RA_CLIENT_ADMIN,
        RA_FCC_ADMIN,
        RA_FCC_ANALYST,
        RA_CLIENT_ANALYST,
        RR_CLIENT_ADMIN,
        RR_FCC_ADMIN,
        RR_FCC_ANALYST,
        RR_CLIENT_ANALYST,
      ]),
  },
  {
    permission: UserPermissionsEnum.WALKTHROUGH_ASSIGNMENT_BY_FILTERS_UI,
    checkAccess: (): boolean => CurrentUserService.isUserRolesInclude([GLOBAL_ADMIN, RA_FCC_ADMIN]),
  },
  {
    permission: UserPermissionsEnum.WALKTHROUGH_FACILITY_NOTES_EDIT_UI,
    checkAccess: (): boolean => CurrentUserService.isUserRolesInclude([RA_FCC_ANALYST, RA_FCC_ADMIN, GLOBAL_ADMIN]),
  },
  {
    permission: UserPermissionsEnum.WALKTHROUGH_ASSIGNMENT_TO_FILTERS_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        RA_CLIENT_ADMIN,
        RA_FCC_ADMIN,
        RA_FCC_ANALYST,
        RA_CLIENT_ANALYST,
      ]),
  },
  {
    permission: UserPermissionsEnum.GLOBAL_SEARCH_NOTIFY_UI,
    checkAccess: (): boolean => CurrentUserService.isUserRolesInclude([GLOBAL_ADMIN, TENANT_ADMIN, THREAT_ANALYST]),
  },
  {
    permission: UserPermissionsEnum.USER_ASSIGNMENT_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        THREAT_ANALYST,
        ESCALATION_APPROVER,
        OWNER,
        ANALYST,
      ]),
  },
  {
    permission: UserPermissionsEnum.HOST_DETAILS_SCAN_INFO_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        THREAT_ANALYST,
        BUSINESS_UNIT_LEADER,
        DODSON_MODE,
        ESCALATION_APPROVER,
      ]),
  },
  {
    permission: UserPermissionsEnum.CHAT_UI,
    checkAccess: (): boolean =>
      !CurrentUserService.isUserInCoreTeam && CurrentUserService.isUserRolesInclude([OWNER, ANALYST, LEADER, OBSERVER]),
  },
  {
    permission: UserPermissionsEnum.VIEW_REPORTS_GENERATED_BY_OTHERS_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        RA_FCC_ADMIN,
        RR_FCC_ADMIN,
        OWNER,
        RA_CLIENT_ADMIN,
        RR_CLIENT_ADMIN,
      ]),
  },
  {
    permission: UserPermissionsEnum.GENERATE_NEW_REPORT_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        THREAT_ANALYST,
        BUSINESS_UNIT_LEADER,
        OWNER,
        ANALYST,
        LEADER,
        OBSERVER,
        DODSON_MODE,
        ESCALATION_APPROVER,
        ...riskAssessmentRoles,
        ...riskRegisterRoles,
      ]),
  },
  {
    permission: UserPermissionsEnum.SCHEDULE_REPORT_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        THREAT_ANALYST,
        BUSINESS_UNIT_LEADER,
        OWNER,
        ANALYST,
        LEADER,
        OBSERVER,
        DODSON_MODE,
        ESCALATION_APPROVER,
        ...riskAssessmentRoles,
        ...riskRegisterRoles,
      ]),
  },
  {
    permission: UserPermissionsEnum.REPORT_STATUS_WS_CHANNEL_UI,
    checkAccess: (): boolean =>
      CurrentUserService.isUserRolesInclude([
        GLOBAL_ADMIN,
        TENANT_ADMIN,
        OWNER,
        RA_FCC_ADMIN,
        RA_CLIENT_ADMIN,
        RR_FCC_ADMIN,
        RR_CLIENT_ADMIN,
      ]),
  },
  {
    permission: UserPermissionsEnum.ASSESSMENT_SURVEY_EDIT_UI,
    checkAccess: (): boolean => CurrentUserService.isUserRolesInclude([GLOBAL_ADMIN, RA_FCC_ADMIN, RA_FCC_ANALYST]),
  },
  {
    permission: UserPermissionsEnum.ASSESSMENT_SURVEY_UPDATE_BY_ASSIGNEE_UI,
    checkAccess: (): boolean => CurrentUserService.isUserRolesInclude([RA_CLIENT_ADMIN, RA_CLIENT_ANALYST]),
  },
];
