import { UserRolesEnum } from '../enums/user-roles.enum';

const {
  GLOBAL_ADMIN,
  TENANT_ADMIN,
  THREAT_ANALYST,
  BUSINESS_UNIT_LEADER,
  HELP_DESK,
  OWNER,
  ANALYST,
  LEADER,
  OBSERVER,
  DODSON_MODE,
  ESCALATION_APPROVER,
  RA_FCC_ADMIN,
  RA_FCC_ANALYST,
  RA_FCC_VIEWER,
  RA_CLIENT_ADMIN,
  RA_CLIENT_ANALYST,
  RA_CLIENT_VIEWER,
  RR_FCC_ADMIN,
  RR_FCC_ANALYST,
  RR_FCC_VIEWER,
  RR_CLIENT_ADMIN,
  RR_CLIENT_ANALYST,
  RR_CLIENT_VIEWER,
} = UserRolesEnum;

export const generalRoles = [
  GLOBAL_ADMIN,
  TENANT_ADMIN,
  THREAT_ANALYST,
  BUSINESS_UNIT_LEADER,
  HELP_DESK,
  OWNER,
  ANALYST,
  LEADER,
  OBSERVER,
  DODSON_MODE,
  ESCALATION_APPROVER,
];

export const riskAssessmentRoles = [
  RA_FCC_ADMIN,
  RA_FCC_ANALYST,
  RA_FCC_VIEWER,
  RA_CLIENT_ADMIN,
  RA_CLIENT_ANALYST,
  RA_CLIENT_VIEWER,
];

export const riskRegisterRoles = [
  RR_FCC_ADMIN,
  RR_FCC_ANALYST,
  RR_FCC_VIEWER,
  RR_CLIENT_ADMIN,
  RR_CLIENT_ANALYST,
  RR_CLIENT_VIEWER,
];

export const userRolesMap: Record<string, string> = {
  [UserRolesEnum.RA_CLIENT_ADMIN]: 'Risk assessment - admin',
  [UserRolesEnum.RA_CLIENT_ANALYST]: 'Risk assessment - analyst',
  [UserRolesEnum.RA_CLIENT_VIEWER]: 'Risk assessment - viewer',
  [UserRolesEnum.RA_FCC_ADMIN]: 'Risk assessment - admin',
  [UserRolesEnum.RA_FCC_ANALYST]: 'Risk assessment - analyst',
  [UserRolesEnum.RA_FCC_VIEWER]: 'Risk assessment - viewer',
  [UserRolesEnum.RR_CLIENT_ADMIN]: 'Risk register - admin',
  [UserRolesEnum.RR_CLIENT_ANALYST]: 'Risk register - analyst',
  [UserRolesEnum.RR_CLIENT_VIEWER]: 'Risk register - viewer',
  [UserRolesEnum.RR_FCC_ADMIN]: 'Risk register - admin',
  [UserRolesEnum.RR_FCC_ANALYST]: 'Risk register - analyst',
  [UserRolesEnum.RR_FCC_VIEWER]: 'Risk register - viewer',
};
