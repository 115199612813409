import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';

import { lazy } from '@loadable/component';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

import Account from './components/account.component';
import Search from './components/search.component';
import ThemeSwitcher from './components/theme-switcher.component';
import { ClientService } from 'src/services/client.service';
import { CurrentUserService } from 'src/services/current-user.service';
import useChatTenantPreference from '../../../hooks/use-chat-tenant-preference.hook';
import { getAvatarTitle } from '../../../utils/get-avatar-title.util';
import { getUserFullName } from 'src/utils/get-user-full-name.util';
import { getUserDataSelector } from 'src/store/slices/user/user.selector';
import Notifications from '../../../shared/modules/notifications';
import HeaderTitleContext from '../utils/header-title.context';
import { useSelector } from 'src/store';

const ChatWidget = lazy(() => import('../../../shared/modules/chat'));
const ReportIncident = lazy(() => import('../../../shared/modules/report-incident'));

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  paddingReset: `${PREFIX}-paddingReset`,
  userInfo: `${PREFIX}-userInfo`,
  userName: `${PREFIX}-userName`,
  divider: `${PREFIX}-divider`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.text.primary,
    height: '64px',
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    boxShadow: 'none',
    position: 'fixed',
    borderBottom: `1px solid #E5E5E5`,
    paddingLeft: '256px',
  },

  [`& .${classes.title}`]: {
    fontSize: '24px',
    lineHeight: '36px',
    paddingLeft: theme.spacing(1),
    flexGrow: 1,
    overflow: 'hidden',
  },

  [`&.${classes.paddingReset}`]: {
    paddingLeft: 0,
  },

  [`& .${classes.userInfo}`]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.userName}`]: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(4),
  },
}));

interface ITopBarProps {
  onMobileNavOpen: () => void;
}

const TopBar = ({ onMobileNavOpen }: ITopBarProps): React.ReactElement => {
  const { title } = useContext(HeaderTitleContext);
  const { chatAvailable } = useChatTenantPreference();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const user = useSelector(getUserDataSelector);
  const parentContainer = useRef(null);
  const canSearch = CurrentUserService.permissions?.GLOBAL_SEARCH_PERFORM && !ClientService.isOrgViewMode();

  const [showThemeSwitcher, setShowThemeSwitcher] = useState(false);
  const [avatarTitle, setAvatarTitle] = useState(null);

  useEffect(() => {
    if (user) {
      const { first_name, last_name } = user;
      checkThemeSwitchPermission();
      setAvatarTitle(getAvatarTitle(first_name, last_name));
    }
  }, [user]);

  const checkThemeSwitchPermission = (): void => {
    setShowThemeSwitcher(!CurrentUserService?.permissions?.USER_DODSON);
  };

  return (
    <StyledAppBar className={clsx({ [classes.root]: true, [classes.paddingReset]: mobileDevice })}>
      <Toolbar ref={parentContainer}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box className={classes.title}>{title || ''}</Box>
        {canSearch && <Search />}
        {showThemeSwitcher && <ThemeSwitcher />}
        <Notifications />
        {chatAvailable && (
          <Suspense fallback={null}>
            <ChatWidget />
          </Suspense>
        )}
        {!CurrentUserService.isUserInCoreTeam && (
          <Suspense fallback={null}>
            <ReportIncident />
          </Suspense>
        )}
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={classes.userInfo}>
          <span className={classes.userName}>{getUserFullName(user)}</span>
        </Box>
        <Box ml={2}>
          <Account title={avatarTitle} showChatHistory={chatAvailable} />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopBar;
