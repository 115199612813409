import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

import { IUser } from '../../../interfaces/user.type';
import { IUserSlice } from './utils/user-slice.type';
import { UserPermissionsEnum } from 'src/enums/user-permissions.enum';
import { RootState } from '../../root.reducer';

const getUserSelector = (state: RootState): IUserSlice => state.user;
const getUserDataSelector = createSelector(getUserSelector, (userSlice: IUserSlice) => userSlice.data);
const getUserPermissionsSelector = createSelector(getUserDataSelector, (user: IUser) => user?.permissions);
const getUserTenantSelector = createSelector(getUserDataSelector, (user: IUser) => user?.tenant);

const getUserPermissionByNameSelector = createSelector(getUserPermissionsSelector, (permissions) =>
  memoize((permissionName: UserPermissionsEnum) => permissions.find((item) => item.machine_name === permissionName)),
);

export { getUserPermissionByNameSelector, getUserDataSelector, getUserTenantSelector };
