import { useNavigate } from 'react-router-dom';

import { CurrentUserService } from 'src/services/current-user.service';
import useSelectedClient from './use-selected-client.hook';
import { RiskAssessmentRoutesEnum } from '../../../widgets/advisory-services/utils/risk-assessment-routes.enum';
import { NotificationClientTypeEnum } from './notification-client-type.enum';
import { NotificationTypesEnum } from './notifications-types.enum';
import { RiskAssessmentDetailsRoutesEnum } from 'src/shared/widgets/advisory-services/utils/risk-assessment-details-routes.enum';

interface IAssessmentRedirectParams {
  assessmentId: string;
  clientId: string;
  clientType?: NotificationClientTypeEnum;
  entityId?: string;
  type: NotificationTypesEnum;
}

interface IUseAssessmentRedirect {
  assessmentRedirect: (data: IAssessmentRedirectParams) => Promise<void>;
}

const { DOCUMENT_REQUEST, TECHNICAL_CONTROLS_SURVEY, ONSITE_PHYSICAL_WALKTHROUGH } = RiskAssessmentDetailsRoutesEnum;
const {
  RA_WALKTHROUGH_ASSIGN,
  RA_WALKTHROUGH_COMPLETE,
  NEW_RISK_ASSESSMENT,
  ASSESSMENT_ASSIGNMENT,
  RA_SURVEY_ASSIGN,
  RA_SURVEY_COMPLETE,
  ASSESSMENT_COMPLETE,
} = NotificationTypesEnum;

const defaultTabs: Record<string, RiskAssessmentDetailsRoutesEnum> = {
  [NEW_RISK_ASSESSMENT]: DOCUMENT_REQUEST,
  [ASSESSMENT_ASSIGNMENT]: DOCUMENT_REQUEST,
  [RA_SURVEY_ASSIGN]: TECHNICAL_CONTROLS_SURVEY,
  [RA_SURVEY_COMPLETE]: TECHNICAL_CONTROLS_SURVEY,
  [RA_WALKTHROUGH_ASSIGN]: ONSITE_PHYSICAL_WALKTHROUGH,
  [RA_WALKTHROUGH_COMPLETE]: ONSITE_PHYSICAL_WALKTHROUGH,
};

const entityDetailedViewTypes = [RA_SURVEY_ASSIGN, RA_SURVEY_COMPLETE, RA_WALKTHROUGH_ASSIGN, RA_WALKTHROUGH_COMPLETE];

const { DASHBOARD, ASSESSMENT } = RiskAssessmentRoutesEnum;

export default function useAssessmentRedirect(): IUseAssessmentRedirect {
  const navigate = useNavigate();
  const { checkSelectedClient } = useSelectedClient();

  const assessmentRedirect = async (data: IAssessmentRedirectParams): Promise<void> => {
    const { clientId, assessmentId, clientType, type, entityId } = data;
    const isOrgAssessment = clientType === NotificationClientTypeEnum.ORGANIZATION;
    await checkSelectedClient(clientId, isOrgAssessment);

    const isAssessmentComplete = type === ASSESSMENT_COMPLETE;
    const detailsPath = !isAssessmentComplete ? getDetailsPath(type, assessmentId, entityId) : '';
    const basePath = getBasePath(clientId, isOrgAssessment);
    const state = isAssessmentComplete ? { assessmentId } : null;

    navigate(`${basePath}${DASHBOARD}${detailsPath}`, { state });
  };

  const getBasePath = (clientId: string, isOrgAssessment: boolean): string => {
    const clientPath = isOrgAssessment ? `organizations/${clientId}` : `tenants/${clientId}`;
    return CurrentUserService.isUserInCoreTeam || (CurrentUserService.isOrgUser && !isOrgAssessment) ? clientPath : '';
  };

  const getDetailsPath = (type: NotificationTypesEnum, assessmentId: string, entityId: string): string => {
    const openEntityDetails = entityDetailedViewTypes.includes(type);
    return `/${ASSESSMENT}/${assessmentId}/${defaultTabs[type]}${openEntityDetails ? `/${entityId}` : ''}`;
  };

  return { assessmentRedirect };
}
