import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import noop from 'lodash/noop';

import { ICssProperties } from '../../interfaces/css-style.type';
import { searchIconStyle } from '../../assets/styles/search-icon.style';

interface IProps {
  autoFocus?: boolean;
  fullWidth?: boolean;
  sx?: ICssProperties;
  loading?: boolean;
  maxLength?: number;
  onBlur?: (value?: string) => void;
  placeHolder?: string;
  search: (value: string) => void;
  tooltip?: string;
  value?: string;
}

const minSymbolsQuantity = 3;

const SearchControl = ({
  autoFocus,
  fullWidth,
  sx,
  loading,
  maxLength = 50,
  onBlur = noop,
  placeHolder = 'Search',
  search,
  tooltip = '',
  value: searchValue,
}: IProps): React.ReactElement => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(searchValue || '');
  }, []);

  useEffect(() => {
    setValue(searchValue || '');
  }, [searchValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = event.target.value.trimStart();
    value && !currentValue && search('');
    setValue(currentValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && value && value.trim().length >= minSymbolsQuantity) {
      search(value);
    }
  };

  const handleBlur = (): void => {
    onBlur(value);
  };

  return (
    <Box sx={sx}>
      <FormControl variant="outlined" fullWidth={fullWidth}>
        <InputLabel htmlFor="search-control">{placeHolder}</InputLabel>
        <OutlinedInput
          label={placeHolder}
          id="search-control"
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          inputProps={{ maxLength }}
          onBlur={handleBlur}
          autoFocus={autoFocus}
          endAdornment={
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  disableRipple={true}
                  disableFocusRipple={true}
                  sx={searchIconStyle.searchIcon}
                  disabled={value.trim().length < minSymbolsQuantity}
                  onClick={(): void => search(value)}
                  edge="end"
                  size="large"
                  aria-label="Search icon"
                >
                  <Tooltip title={tooltip}>
                    <SearchIcon />
                  </Tooltip>
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};

export default SearchControl;
