import { useNavigate, useParams } from 'react-router-dom';

import { ClientContextService } from '../services/client-context.service';

interface IUseActiveService {
  checkClientRouteContext: () => Promise<[void, void] | void>;
}

export default function useClientRouteContext(): IUseActiveService {
  const { organizationId, tenantId } = useParams();
  const navigate = useNavigate();

  const checkClientRouteContext = (): Promise<[void, void] | void> => {
    return ClientContextService.getClientContext(organizationId, tenantId).catch(() => navigate('/'));
  };

  return { checkClientRouteContext };
}
