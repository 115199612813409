import React from 'react';

import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import ReportProblem from '@mui/icons-material/ReportProblem';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import ReportsIcon from 'src/shared/components/icons/reports-icon.component';
import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';
import Physical_Walkthrough from 'src/assets/Physical_Walkthrough.svg';
import Technical_Control_Survey from 'src/assets/Technical_Control_Survey.svg';

const classes = {
  defaultIcon: { color: 'text.secondary', pt: 0.5 },
  alertIcon: { color: '#b4292a', pt: 0.5 },
};

export default function NotificationIcon({ type }: { type: NotificationTypesEnum }): React.ReactElement {
  switch (type) {
    case NotificationTypesEnum.QUESTION_ASKED:
    case NotificationTypesEnum.QUESTION_REPLIED:
    case NotificationTypesEnum.THREAD_MESSAGE:
      return <ChatBubbleOutlineOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.SCAN_COMPLETED:
    case NotificationTypesEnum.DOCUMENT_ACCEPTED:
      return <DoneOutlineOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.DOCUMENT_COMPLETE:
      return <CheckCircleOutlineOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.DOCUMENT_REJECTED:
      return <ClearOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.ALERT_NOTIFICATION:
      return <ReportProblem fontSize="small" sx={classes.alertIcon} />;
    case NotificationTypesEnum.VULNERABILITY_ASSIGNMENT:
    case NotificationTypesEnum.ESCALATION_ASSIGNMENT:
    case NotificationTypesEnum.RISK_ASSIGNMENT:
    case NotificationTypesEnum.RA_DOC_ASSIGN:
    case NotificationTypesEnum.RA_SURVEY_ASSIGN:
    case NotificationTypesEnum.RA_WALKTHROUGH_ASSIGN:
    case NotificationTypesEnum.ASSESSMENT_ASSIGNMENT:
      return <AssignmentIndOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.ESCALATION:
    case NotificationTypesEnum.ESCALATION_CREATED:
      return <ReportProblemOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.NEW_RISK_ASSESSMENT:
      return <EqualizerOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.SCHEDULED_PDF_REPORT:
      return <ReportsIcon fontSize="small" sx={classes.defaultIcon} />;
    case NotificationTypesEnum.RA_WALKTHROUGH_COMPLETE:
      return <img src={Physical_Walkthrough} alt="Physical Walkthrough" />;
    case NotificationTypesEnum.RA_SURVEY_COMPLETE:
      return <img src={Technical_Control_Survey} alt="Technical Control Survey" />;
    case NotificationTypesEnum.ASSESSMENT_COMPLETE:
      return <CheckCircleOutlinedIcon fontSize="small" sx={classes.defaultIcon} />;
    default:
      return null;
  }
}
