import { ClientContextService } from '../../../../services/client-context.service';
import { EventListEnum, EventService } from '../../../../services/event.service';

interface IProps {
  checkSelectedClient: (clientId: string, isOrgClient?: boolean) => Promise<void>;
}

export default function useSelectedClient(): IProps {
  const checkSelectedClient = (clientId: string, isOrgClient: boolean): Promise<void> => {
    const orgClientId: string = isOrgClient ? clientId : null;
    const tenantClientId = isOrgClient ? null : clientId;

    return ClientContextService.getClientContext(orgClientId, tenantClientId).then(() => {
      EventService.emit(EventListEnum.CLIENT_SELECTION);
    });
  };

  return { checkSelectedClient };
}
