import React, { CSSProperties, RefObject } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import FccIconButton from '../icons/fcc-icon-button.component';
import CustomDialogFooter from './components/custom-dialog-footer.component';
import { ICssProperties, ISxProperties } from '../../../interfaces/css-style.type';
import { ThemesEnum } from '../../../enums/themes.enum';

interface IProps {
  children?: React.ReactElement;
  customFooter?: React.ReactElement;
  close?: () => void;
  confirm?: () => void;
  confirmButtonColor?: ButtonProps['color'];
  data?: ICustomDialogData;
  dialogClasses?: CSSProperties;
  dialogContentStyles?: ICssProperties;
  isConfirmDisabled?: boolean;
  isActionSubmitting?: boolean;
  isRejectDisabled?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  message?: string;
  open: boolean;
  reject?: () => void;
  rejectButtonColor?: ButtonProps['color'];
  rejectButtonVariant?: ButtonProps['variant'];
  showCloseIcon?: boolean;
  fullWidth?: boolean;
  contentRef?: RefObject<unknown>;
  hideFooter?: boolean;
  contentHeight?: string;
  footerStyles?: ICssProperties;
  dialogStyles?: ISxProperties;
}

export interface ICustomDialogData {
  title: string | React.ReactElement;
  closeButtonTitle?: string | React.ReactElement;
  confirmButtonTitle?: string | React.ReactElement;
  confirmButtonTooltip?: string | React.ReactElement;
}

type ICloseReason = 'escapeKeyDown' | 'backdropClick';

export default function CustomDialog({
  children,
  close,
  confirm,
  confirmButtonColor = 'primary',
  customFooter,
  data,
  dialogClasses,
  dialogContentStyles = {},
  fullWidth,
  isConfirmDisabled,
  isRejectDisabled,
  maxWidth,
  message,
  open,
  reject,
  rejectButtonColor = 'info',
  rejectButtonVariant = 'contained',
  showCloseIcon = true,
  contentRef,
  hideFooter = false,
  isActionSubmitting,
  contentHeight = 'calc(100% - 64px)',
  footerStyles,
  dialogStyles,
}: IProps): React.ReactElement {
  return (
    <Dialog
      PaperProps={{ style: { ...dialogClasses, backgroundImage: 'none' } }}
      maxWidth={maxWidth}
      open={open}
      fullWidth={fullWidth}
      onClose={(event: unknown, reason: ICloseReason): void => reason !== 'backdropClick' && close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={dialogStyles}
    >
      <Box
        id="alert-dialog-title"
        sx={{
          paddingX: 6,
          paddingY: 4,
          backgroundColor: (t): string => (t.palette.mode === ThemesEnum.LIGHT ? '#F6F6F6' : 'background.default'),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '20px' }}>
          {data.title}
          {showCloseIcon && (
            <Box ml="auto" mr={-3}>
              <FccIconButton size="medium" onClick={close} ariaLabel="close dialog">
                <CloseIcon />
              </FccIconButton>
            </Box>
          )}
        </Box>
      </Box>
      <DialogContent ref={contentRef} sx={dialogContentStyles}>
        <Box height={contentHeight}>
          {children || (
            <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary', textAlign: 'justify' }}>
              {message}
            </DialogContentText>
          )}
        </Box>
      </DialogContent>
      {!hideFooter && (
        <DialogActions sx={footerStyles}>
          {customFooter || (
            <CustomDialogFooter
              confirm={confirm}
              confirmButtonColor={confirmButtonColor}
              data={data}
              isActionSubmitting={isActionSubmitting}
              isConfirmDisabled={isConfirmDisabled}
              isRejectDisabled={isRejectDisabled}
              reject={reject}
              rejectButtonColor={rejectButtonColor}
              rejectButtonVariant={rejectButtonVariant}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
