import React, { createContext, useMemo, useState } from 'react';

import noop from 'lodash/noop';

interface IProps {
  children: React.ReactElement;
}

const HeaderTitleContext = createContext({
  title: null,
  setHeaderTitle: noop,
});

export const HeaderTitleProvider = ({ children }: IProps): React.ReactElement => {
  const [currentTitle, setCurrentTitle] = useState<string | React.ReactElement>();

  const value = useMemo(() => ({ title: currentTitle, setHeaderTitle: setCurrentTitle }), [currentTitle]);

  return <HeaderTitleContext.Provider value={value}>{children}</HeaderTitleContext.Provider>;
};

export const SettingsConsumer = HeaderTitleContext.Consumer;
export default HeaderTitleContext;
