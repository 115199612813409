import AnnouncementOutlined from '@mui/icons-material/AnnouncementOutlined';
import Assessment from '@mui/icons-material/Assessment';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import LockOutlined from '@mui/icons-material/LockOutlined';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Security from '@mui/icons-material/Security';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';

import { ClientServicesEnum } from '../enums/client-services.enum';

export const servicesIconMap = {
  [ClientServicesEnum.VTM]: Security,
  [ClientServicesEnum.SIEM]: Assessment,
  [ClientServicesEnum.IOMT]: ImportantDevicesIcon,
  [ClientServicesEnum.MDR]: NewReleasesIcon,
  [ClientServicesEnum.RISK_REGISTER]: BorderColorOutlinedIcon,
  [ClientServicesEnum.RISK_ASSESSMENT]: BusinessCenterIcon,
  [ClientServicesEnum.VISP]: LockOutlined,
  [ClientServicesEnum.PENTESTING]: VpnKeyOutlined,
  [ClientServicesEnum.INCIDENT_RESPONSE]: AnnouncementOutlined,
  [ClientServicesEnum.XDR]: ZoomOutMap,
};
