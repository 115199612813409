import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BackToLogin from './back-to-login.component';
import CustomAlert from 'src/shared/components/custom-alert.component';
import LoginPasswordField from 'src/views/auth/components/login-password-field.component';
import { SessionService } from 'src/api/session.service';
import { AuthService } from 'src/services/auth.service';
import { TrackUserActionsService } from 'src/services/track-user-actions.service';
import useDocumentTitle from 'src/hooks/use-document-title.hook';
import { IUserSignError } from '../../../interfaces/user.type';
import { IFormicActions } from 'src/interfaces/formic.type';
import { AuthorizationTypesEnum } from '../../../enums/authorization-types.enum';
import { ChallengeNameEnum } from '../../../enums/challenge-name.enum';
import { UserSignInErrorCodeEnum } from '../../../enums/user-sign-in-error-code.enum';
import { AuthMethodEnum } from 'src/enums/gtm/auth-method.enum';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { AuditLogData } from 'src/constants/audit-log-data.constant';
import { authComponentStyles } from '../styles/auth.style';
import { backToLoginStyles } from '../styles/back-to-login.style';
import FccTextField from '../../../shared/components/controls/text-field';
import FccButton from 'src/shared/components/controls/button';

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  link: `${PREFIX}-link`,
  separator: `${PREFIX}-separator`,
  separatorText: `${PREFIX}-separatorText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.form}`]: {
    width: '250px',
  },

  [`& .${classes.link}`]: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    padding: 0,
    fontWeight: 400,
    textTransform: 'none',
  },

  [`& .${classes.separator}`]: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    marginBottom: '40px',
    marginTop: '15px',
    opacity: 0.6,
  },

  [`& .${classes.separatorText}`]: {
    position: 'relative',
    top: '9px',
    zIndex: 1,
    background: 'white',
    width: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

interface LoginFormState {
  username: string;
  password: string;
  submit: boolean | null;
}

const incorrectPasswordError = 'Incorrect password';

const initialState: LoginFormState = {
  username: '',
  password: '',
  submit: null,
};

const validationSchema = Yup.object().shape({
  username: Yup.string().max(255).required('Username is required'),
  password: Yup.string().max(255).required('Password is required'),
});

export default function Login(): React.ReactElement {
  const navigate = useNavigate();
  useDocumentTitle('Credentials - Login');

  const [showSSOOption, setShowSSOOption] = useState(false);

  useEffect(() => {
    setShowSSOOption(AuthService.config?.authorization_type === AuthorizationTypesEnum.SSO);
  }, []);

  const submitHandler = async (values: LoginFormState, actions: IFormicActions): Promise<void> => {
    try {
      await signIn(values);
    } catch (error: unknown) {
      handleSignInErrors(error as IUserSignError, values, actions);
    }
  };

  const customProviderLogin = async (): Promise<void> => {
    await AuthService.federatedSignIn();
  };

  const signIn = async (values: LoginFormState): Promise<void> => {
    const response = await AuthService.signIn(values.username, values.password);

    if (!response.challengeName) {
      AuthService.checkUserChangedPassword();
    }

    if (response.challengeName === ChallengeNameEnum.CUSTOM_CHALLENGE && !!response.challengeParam.DUO_AUTH_URL) {
      window.location.href = response.challengeParam.DUO_AUTH_URL;
      return;
    }

    if (response.challengeName === ChallengeNameEnum.NEW_PASSWORD_REQUIRED) {
      navigate('/login/new-password');
      return;
    }

    if (response.challengeName === ChallengeNameEnum.SMS_MFA) {
      navigate('/login/verification');
      return;
    }

    SessionService.setAuthMethod(AuthMethodEnum.EMAIL);
    const shouldVerifyEmail = await AuthService.checkShouldVerifyEmail(response);

    if (shouldVerifyEmail) {
      navigate('/login/email-verification');
    } else {
      TrackUserActionsService.logUserAction(AuditLogData.LOG_IN);
      navigate(SessionService.getUserRedirectUrl());
    }
  };

  const handleSignInErrors = (error: IUserSignError, values: LoginFormState, actions: IFormicActions): void => {
    if (error.code === UserSignInErrorCodeEnum.PASSWORD_RESET_REQUIRED_EXCEPTION) {
      AuthService.verifiedUserName = values.username;
      navigate('/login/reset-password');
    } else {
      const errorCodes = [UserSignInErrorCodeEnum.NOT_AUTHORIZED_EXCEPTION, UserSignInErrorCodeEnum.ERR_BAD_REQUEST];
      const errorMessage = errorCodes.includes(error.code) ? incorrectPasswordError : error.message;
      actions.setErrors({ submit: errorMessage });
    }
  };

  const forgotPasswordHandler = async (setErrors: (data: unknown) => void): Promise<void> => {
    try {
      await AuthService.forgotPassword(AuthService.verifiedUserName);
      navigate('/login/reset-password');
    } catch (error: unknown) {
      error && setErrors({ submit: (error as IUserSignError)?.message });
    }
  };

  const navigateToLogin = (): void => {
    AuthService.cognitoUser = null;
    navigate('/login');
  };

  return (
    <StyledBox className={classes.root}>
      <Box sx={backToLoginStyles.root} onClick={navigateToLogin}>
        <BackToLogin title={'Back'} />
      </Box>
      <Typography component="h6" sx={authComponentStyles.title}>
        Log in to Fortified Central Command
      </Typography>
      <Box className={classes.form}>
        <Formik
          initialValues={{ ...initialState, username: AuthService.verifiedUserName }}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({
            setErrors,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            isValid,
            dirty,
          }): React.ReactElement => (
            <form noValidate onSubmit={handleSubmit}>
              <FccTextField
                autoFocus
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.username}
                disabled
              />
              <LoginPasswordField
                error={Boolean(touched.password && errors.password)}
                handleBlur={handleBlur}
                handleChange={handleChange}
                helperText={touched.password && errors.password}
                label="Password"
                name="password"
                value={values.password}
              />
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <FccButton
                  variant="text"
                  className={classes.link}
                  onClick={(): Promise<void> => forgotPasswordHandler(setErrors)}
                  onKeyDown={(event): Promise<void> => event.key === 'Enter' && forgotPasswordHandler(setErrors)}
                  size="small"
                >
                  Forgot password?
                </FccButton>
              </Box>
              {errors.submit && <CustomAlert message={errors.submit} severity={ToastMessageSeveritiesEnum.ERROR} />}
              <Box sx={authComponentStyles.submitButton}>
                <FccButton
                  color="primary"
                  disabled={!dirty || !isValid}
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Log In
                </FccButton>
              </Box>
            </form>
          )}
        </Formik>

        {showSSOOption && (
          <>
            <Box className={classes.separator}>
              <Box className={classes.separatorText}>or</Box>
            </Box>

            <FccButton fullWidth={true} variant="outlined" color="primary" onClick={customProviderLogin}>
              LOG IN WITH SSO
            </FccButton>
          </>
        )}
      </Box>
    </StyledBox>
  );
}
