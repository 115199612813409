import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';

export const assessmentTypes = [
  NotificationTypesEnum.NEW_RISK_ASSESSMENT,
  NotificationTypesEnum.ASSESSMENT_ASSIGNMENT,
  NotificationTypesEnum.ASSESSMENT_COMPLETE,
];

export const assessmentDetailsTabsTypes = [
  NotificationTypesEnum.RA_SURVEY_ASSIGN,
  NotificationTypesEnum.RA_SURVEY_COMPLETE,
  NotificationTypesEnum.RA_WALKTHROUGH_ASSIGN,
  NotificationTypesEnum.RA_WALKTHROUGH_COMPLETE,
];
