import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import { Loader } from '../../shared/components/loader.component';
import TermsOfUseAndPrivacyPolicyDialog from '../../shared/widgets/terms-of-use-and-privacy-policy-dialog.component';
import MainContainer from './components/main-container.component';
import { ClientService } from '../../services/client.service';
import { CurrentUserService } from '../../services/current-user.service';
import { EventListEnum } from '../../services/event.service';
import { MainLayoutService } from './main-layout.service';
import { GtmService } from 'src/services/gtm.service';
import useScrollToTop from '../../hooks/use-scroll-to-top.hook';
import { useGeneratedReport } from './utils/use-generated-report.hook';
import useEventService from 'src/hooks/use-event-service.hook';
import { IUser } from '../../interfaces/user.type';
import sectionList from './navbar/utils/section-list.constant';
import { fetchStaticAdvisoryServicesData } from '../../store/slices/static-advisory-services/static-advisory-services.slice';
import { fetchStaticUserData } from '../../store/slices/static-user/static-user.slice';
import { fetchTenantServicesData } from '../../store/slices/tenant-services/tenant-services.slice';
import { fetchUserData } from '../../store/slices/user/user.slice';
import { fetchUserPreferencesData } from '../../store/slices/user-preferences/user-preferences.slice';
import { fetchStaticData } from 'src/store/slices/static/static.slice';
import { getTenantServicesListSelector } from '../../store/slices/tenant-services/tenant-services.selector';
import { getUserDataSelector } from 'src/store/slices/user/user.selector';
import NavBar from './navbar';
import TopBar from './topbar';
import { HeaderTitleProvider } from './utils/header-title.context';
import UploadingAttachmentsProvider from 'src/shared/widgets/uploading-attachments/uploading-attachments.provider';
import { useDispatch, useSelector } from 'src/store';

const PREFIX = 'mainLayout';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
}));

export default function MainLayout(): React.ReactElement {
  useScrollToTop();
  const dispatch = useDispatch();
  const userGeneralData: IUser = useSelector(getUserDataSelector);
  const tenantServices = useSelector(getTenantServicesListSelector);
  useGeneratedReport();
  useEventService([EventListEnum.CLIENT_SELECTION], setSectionsByUserAccess);

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    dispatch(fetchStaticData());
    dispatch(fetchStaticUserData());
    dispatch(fetchStaticAdvisoryServicesData());
    dispatch(fetchUserPreferencesData());
    getUserData();
  }, []);

  useEffect(() => {
    if (userGeneralData) {
      GtmService.sendAuthEvent(userGeneralData);
      GtmService.sendAssociatedUserEvent(userGeneralData);
      dispatch(fetchTenantServicesData(userGeneralData));
    }
  }, [userGeneralData]);

  useEffect(() => {
    userGeneralData && tenantServices && handleMenuData();
  }, [userGeneralData, tenantServices]);

  const getUserData = (): void => {
    dispatch(fetchUserData()).then((res) => CurrentUserService.setUserData(res.payload as IUser));
  };

  function handleMenuData(): void {
    setClientServices();
    setSectionsByUserAccess();
  }

  function setSectionsByUserAccess(): void {
    const filteredSections = MainLayoutService.filterMenuOptions(sectionList);
    setSections(filteredSections);
  }

  function setClientServices(): void {
    ClientService.clientServices = !CurrentUserService.clientsOriented() ? tenantServices : [];
  }

  return (
    <Root>
      {!tenantServices ? (
        <Loader isGlobal={true} />
      ) : (
        <HeaderTitleProvider>
          <UploadingAttachmentsProvider>
            <div className={classes.root}>
              <TermsOfUseAndPrivacyPolicyDialog />
              <NavBar
                sections={sections}
                onMobileClose={(): void => setIsMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
              />
              <TopBar onMobileNavOpen={(): void => setIsMobileNavOpen(true)} />
              <MainContainer />
            </div>
          </UploadingAttachmentsProvider>
        </HeaderTitleProvider>
      )}
    </Root>
  );
}
