import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ReportsIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M15.1218 4H5.08255V20H19.1375V8H15.1218V4ZM3.07471 2.992C3.07471 2.444 3.52346 2 4.07762 2H16.1257L21.1453 7V20.993C21.1462 21.1243 21.1212 21.2545 21.0716 21.3762C21.022 21.4979 20.9488 21.6087 20.8562 21.7022C20.7637 21.7957 20.6535 21.8701 20.5321 21.9212C20.4106 21.9723 20.2802 21.9991 20.1484 22H4.0716C3.80795 21.9982 3.5556 21.8931 3.36907 21.7075C3.18255 21.5219 3.07681 21.2706 3.07471 21.008V2.992ZM12.11 8V12H16.1257C16.1257 12.7911 15.8902 13.5645 15.4489 14.2223C15.0077 14.8801 14.3805 15.3928 13.6467 15.6955C12.913 15.9983 12.1055 16.0775 11.3266 15.9231C10.5476 15.7688 9.83209 15.3878 9.27048 14.8284C8.70888 14.269 8.32642 13.5563 8.17148 12.7804C8.01653 12.0044 8.09605 11.2002 8.39999 10.4693C8.70393 9.73836 9.21863 9.11365 9.87901 8.67412C10.5394 8.2346 11.3158 8 12.11 8Z" />
    </SvgIcon>
  );
}
