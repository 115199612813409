import { createSlice } from '@reduxjs/toolkit';

import { IDataState } from './data-state.type';

const initialState: IDataState = {
  data: {},
};

export const dataStateSlice = createSlice({
  name: 'dataState',
  initialState,
  reducers: {
    setDataState(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    updateServiceDataState(state, action) {
      const { service, data } = action.payload;
      const serviceData = state.data[service];

      if (serviceData) {
        state.data = { ...state.data, [service]: { ...serviceData, ...data } };
      }
    },
    clearDataState(state) {
      state.data = {};
    },
  },
});

const { actions } = dataStateSlice;
export const setDataState = actions.setDataState;
export const updateServiceDataState = actions.updateServiceDataState;
export const clearDataState = actions.clearDataState;
