import { createSelector } from '@reduxjs/toolkit';

import { IUserPreferencesSlice } from './utils/user-preferences-slice.type';
import { UserPreferenceKeysEnum } from '../../../enums/user-preference-keys.enum';
import { RootState } from '../../root.reducer';

const getUsePreferencesSelector = (state: RootState): IUserPreferencesSlice => state.userPreferences;
const getUserPreferencesSelector = createSelector(
  getUsePreferencesSelector,
  (slice: IUserPreferencesSlice) => slice.data,
);

const getThemeSelector = createSelector(getUsePreferencesSelector, (slice: IUserPreferencesSlice) =>
  slice?.data?.find((item) => item?.key === UserPreferenceKeysEnum.THEME),
);

const getCommunicationPreferencesSelector = createSelector(getUsePreferencesSelector, (slice: IUserPreferencesSlice) =>
  slice?.data?.find((item) => item?.key === UserPreferenceKeysEnum.COMMUNICATION),
);

export { getUserPreferencesSelector, getThemeSelector, getCommunicationPreferencesSelector };
