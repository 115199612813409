import { AxiosResponse } from 'axios';

import apiService from '../api/api.service';
import { ClientService } from './client.service';
import { IOrganizationDetails } from '../interfaces/organization.type';
import { ITenantShortDetails } from '../interfaces/tenant.type';
import { apiUrls } from '../api/api-urls.constant';

export class ClientContextService {
  static getClientContext(organizationId: string, tenantId: string): Promise<[void, void]> {
    return Promise.all([
      ClientContextService.getOrganizationContext(organizationId, tenantId),
      ClientContextService.getTenantContext(tenantId, organizationId),
    ]);
  }

  public static getTenantShortInfo(tenantId: string): Promise<ITenantShortDetails> {
    const url = `${apiUrls.tenantShortDetails}${tenantId}/`;

    return apiService
      .get<AxiosResponse<ITenantShortDetails>>({ url })
      .then((response) => response?.data);
  }

  public static getOrganizationShortInfo(id: string): Promise<IOrganizationDetails> {
    const url = `${apiUrls.organizationShortDetails}${id}/`;

    return apiService
      .get<AxiosResponse>({ url })
      .then((res) => res.data);
  }

  private static getOrganizationContext(organizationId: string, tenantId: string): Promise<void> {
    !tenantId && ClientService.setSelectedClient(null);
    const selectedOrgId = ClientService.getClientId();
    return organizationId && selectedOrgId !== organizationId
      ? ClientContextService.getOrganizationShortInfo(organizationId).then((organization) => {
          ClientService.setSelectedOrganization(organization);
        })
      : Promise.resolve();
  }

  private static getTenantContext(tenantId: string, organizationId: string): Promise<void> {
    !organizationId && ClientService.setSelectedOrganization(null);
    const selectedClientId = ClientService.getClientId();
    return tenantId && selectedClientId !== tenantId
      ? ClientContextService.getTenantShortInfo(tenantId).then((tenant) => {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          ClientService.setSelectedClient(tenant as any);
        })
      : Promise.resolve();
  }
}
