import apiService from 'src/api/api.service';
import { IUserPreference, IUserPreferenceValue } from 'src/interfaces/user-preferences.type';
import { ThemesEnum } from '../enums/themes.enum';
import { apiUrls } from 'src/api/api-urls.constant';

export class UserPreferencesService {
  public static async getUserPreferences(): Promise<IUserPreference[]> {
    return apiService
      .get<{ data: IUserPreference[] }>({ url: apiUrls.userPreferences })
      .then((response) => response?.data);
  }

  public static saveUserPreferences(
    data: { value: IUserPreferenceValue },
    preferenceId: number,
  ): Promise<IUserPreference> {
    const url = `${apiUrls.userPreferences}${preferenceId}/`;
    return apiService
      .patch<{ data: IUserPreference }>({ url, data })
      .then((res) => res.data);
  }

  public static getUserTheme(): ThemesEnum {
    return localStorage.getItem('current-theme') as ThemesEnum;
  }

  public static setUserTheme(theme: ThemesEnum): void {
    localStorage.setItem('current-theme', theme);
  }
}
