import React from 'react';
import { Navigate } from 'react-router-dom';

interface IProps {
  guards: (() => boolean)[];
  redirectUrl: string;
  children: React.ReactElement;
}

export default function ProtectedRoute({ guards, redirectUrl, children }: IProps): React.ReactElement {
  const hasAccess = guards.every((guard) => guard());

  return hasAccess ? children : <Navigate to={redirectUrl} />;
}
