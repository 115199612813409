import React from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const PREFIX = 'BackToLogin';

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.text}`]: {
    marginLeft: theme.spacing(2),
  },
}));

interface IProps {
  title?: string;
}

export default function BackToLogin({ title = 'Back to log in' }: IProps): React.ReactElement {
  return (
    <StyledButton className={classes.root}>
      <ArrowBack />
      <span className={classes.text}>{title}</span>
    </StyledButton>
  );
}
