import { useEffect, useRef } from 'react';

interface IAbortSignal {
  signal: AbortSignal;
  abortController: AbortController;
  getSignal: () => AbortSignal;
}

export default function useAbortController(): IAbortSignal {
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const exchangeableAbortControllerRef = useRef<AbortController>(null);

  useEffect(() => {
    const controller = abortControllerRef.current;

    return (): void => {
      controller.abort();

      if (exchangeableAbortControllerRef.current) {
        exchangeableAbortControllerRef.current.abort();
      }
    };
  }, []);

  const getSignal = (): AbortSignal => {
    if (exchangeableAbortControllerRef.current) {
      exchangeableAbortControllerRef.current.abort();
    }

    exchangeableAbortControllerRef.current = new AbortController();
    return exchangeableAbortControllerRef.current.signal;
  };

  return { signal: abortControllerRef.current.signal, abortController: abortControllerRef.current, getSignal };
}
