import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AxiosResponse } from 'axios';
import trim from 'lodash/trim';

import { CurrentUserService } from './current-user.service';
import { TrackUserActionsService } from './track-user-actions.service';
import apiService from 'src/api/api.service';
import { ICognitoUser, IUserConfig } from '../interfaces/user.type';
import { ChallengeNameEnum } from '../enums/challenge-name.enum';
import { AuthenticationTypeEnum } from '../views/settings/multi-factor/utils/authentication-type.enum';
import { RegistrationStatusValueEnum } from 'src/enums/registration-status-value.enum';
import { AuditLogData } from '../constants/audit-log-data.constant';
import { apiUrls } from 'src/api/api-urls.constant';
import { baseURL } from '../api/axios';

enum AuthEventsEnum {
  USER_CHANGED_PASSWORD = 'user-changed-password',
}

export const incorrectUserNameError = 'You entered an incorrect username';

export class AuthService {
  public static config: IUserConfig;

  private static _cognitoUser: ICognitoUser;
  private static _verifiedUserName: string;

  public static get cognitoUser(): ICognitoUser {
    return AuthService._cognitoUser;
  }

  public static set cognitoUser(user: ICognitoUser) {
    AuthService._cognitoUser = user;
  }

  public static get verifiedUserName(): string {
    return AuthService._verifiedUserName || JSON.parse(localStorage.getItem('verifiedUserName'));
  }

  public static set verifiedUserName(userName: string) {
    AuthService._verifiedUserName = userName;
    localStorage.setItem('verifiedUserName', JSON.stringify(userName));
  }

  public static async configure(username: string): Promise<IUserConfig> {
    const config = await AuthService.getUserConfig(username);
    AuthService.config = config;

    const cognitoConfig = AuthService.buildCognitoConfig(config);
    Auth.configure(cognitoConfig);
    return config;
  }

  public static async signIn(username: string, password: string): Promise<ICognitoUser> {
    const changeStatus = ['FORCE_CHANGE_PASSWORD', 'RESET_REQUIRED'].includes(AuthService.config.idp_status);
    const user =
      changeStatus || AuthService.config.mfa_type !== AuthenticationTypeEnum.DUO
        ? await AuthService.signInWithPassword(username, password)
        : await AuthService.signInWithDUO(username, password);

    AuthService.cognitoUser = user;
    return user;
  }

  private static async signInWithPassword(username: string, password: string): Promise<ICognitoUser> {
    return await Auth.signIn({ username, password });
  }

  /**
   * Sing in user with DUO
   *
   * Here we need to initialize CUSTOM_AUTH as described in {@link https://docs.amplify.aws/lib/auth/switch-auth/q/platform/js#custom_auth-flow|here}
   * but taking into account {@link https://github.com/aws-amplify/amplify-js/issues/3159|this }.
   * it doesn't work.
   *
   * @param username {string}
   * @param password {string}
   * @private
   * @see {@link https://docs.amplify.aws/lib/auth/switch-auth/q/platform/js#custom_auth-flow|Switching authentication flows}
   * @see {@link https://github.com/aws-amplify/amplify-js/issues/3159|#3159 CUSTOM_AUTH with password does not work with Auth.signIn() }
   */
  private static async signInWithDUO(username: string, password: string): Promise<ICognitoUser> {
    sessionStorage.setItem('password', password);
    const res = await apiService.post<AxiosResponse>({
      url: `${apiUrls.users}${username}/auth`,
      data: { password },
    });
    return {
      username: res.data.ChallengeParameters.USERNAME,
      challengeName: res.data.ChallengeName,
      challengeParam: {
        DUO_AUTH_URL: res.data.ChallengeParameters?.DUO_AUTH_URL,
      },
    } as ICognitoUser;
  }

  public static async federatedSignIn(): Promise<void> {
    try {
      await Auth.federatedSignIn({ customProvider: AuthService.config?.sso_identity_provider_id });
    } catch (e) {
      console.error('error', e);
    }
  }

  public static async completeNewPassword(user: ICognitoUser, password: string): Promise<ICognitoUser> {
    AuthService.cognitoUser = await Auth.completeNewPassword(user, password);
    return AuthService.cognitoUser;
  }

  public static async confirmSignIn(code: string): Promise<ICognitoUser> {
    return Auth.confirmSignIn(AuthService.cognitoUser, trim(code), ChallengeNameEnum.SMS_MFA);
  }

  public static async confirmSignInWithDUO(code: string, state: string): Promise<ICognitoUser> {
    const url = `${apiUrls.user}${AuthService.verifiedUserName}/auth-callback`;
    const params = { state, duo_code: code };

    return apiService
      .get<AxiosResponse>({ url, params })
      .then(async () => {
        return await Auth.signIn(AuthService.verifiedUserName, sessionStorage.getItem('password'));
      })
      .finally(() => sessionStorage.removeItem('password'));
  }

  public static async currentAuthenticatedUser(): Promise<ICognitoUser> {
    return await Auth.currentAuthenticatedUser();
  }

  public static async signOut(): Promise<void> {
    TrackUserActionsService.logUserAction(AuditLogData.LOG_OUT);
    await Auth.signOut();
    AuthService.cognitoUser = null;
  }

  public static async forgotPassword(username: string): Promise<void> {
    return await Auth.forgotPassword(username);
  }

  public static async forgotPasswordSubmit(username: string, code: string, newPassword: string): Promise<void> {
    return await Auth.forgotPasswordSubmit(username, code, newPassword).then(() =>
      AuthService.setAuthLogEventToStorage(AuthEventsEnum.USER_CHANGED_PASSWORD, 'changed'),
    );
  }

  public static async changePassword(user: ICognitoUser, oldPassword: string, newPassword: string): Promise<string> {
    return await Auth.changePassword(user, oldPassword, newPassword);
  }

  private static async getUserConfig(username: string): Promise<IUserConfig> {
    const url = `${baseURL}${apiUrls.users}${username}/auth`;
    return await fetch(url).then((res) => {
      if (!res.ok) {
        throw new Error(incorrectUserNameError);
      }
      return res.json();
    });
  }

  public static async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  public static setAuthLogEventToStorage(event: string, value: string): void {
    sessionStorage.setItem(event, value);
  }

  public static checkUserChangedPassword(): void {
    const isPasswordChanged = sessionStorage.getItem(AuthEventsEnum.USER_CHANGED_PASSWORD);

    if (isPasswordChanged) {
      sessionStorage.removeItem(AuthEventsEnum.USER_CHANGED_PASSWORD);
      TrackUserActionsService.logUserAction(AuditLogData.CHANGED_PASSWORD);
    }
  }

  public static checkShouldVerifyEmail(cognitoUser: ICognitoUser): Promise<boolean> {
    return cognitoUser.attributes?.email_verified
      ? Promise.resolve(false)
      : CurrentUserService.getCurrentUserData().then(
          (user) => user.status === RegistrationStatusValueEnum.REQUIRES_EMAIL_VERIFICATION,
        );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static buildCognitoConfig(config: IUserConfig): Record<string, any> {
    const { region, user_pool_id, user_pool_web_client_id } = config;
    const federatedConfig = config.sso_identity_provider_id
      ? {
          oauth: {
            domain: config.domain?.split('//')?.[1],
            scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${baseURL}/sso-login`,
            redirectSignOut: `${baseURL}/login`,
            responseType: 'code',
          },
          federationTarget: 'COGNITO_USER_POOLS',
        }
      : {};

    return {
      region,
      userPoolId: user_pool_id,
      userPoolWebClientId: user_pool_web_client_id,
      ...federatedConfig,
    };
  }
}
