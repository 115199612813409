import React from 'react';

import Box from '@mui/material/Box';
import isNumber from 'lodash/isNumber';

import AttachmentHeader from './attachment-header.component';
import UploadInfo from './upload-info.component';
import FccLinearProgress from 'src/shared/components/fcc-linear-progress.component';
import { IAttachmentData } from '../uploading-attachments.type';

interface IProps {
  attachmentData: IAttachmentData;
  uploadedPercentage: number;
  error: string;
  isUploaded: boolean;
  removeFromQueue: (id: string) => void;
  removeFromView: (id: string) => void;
  retryUpload: (data: IAttachmentData) => void;
}

export default function AttachmentInfo({
  attachmentData,
  error,
  isUploaded,
  removeFromQueue,
  removeFromView,
  retryUpload,
  uploadedPercentage = null,
}: IProps): React.ReactElement {
  const isPending = !isNumber(uploadedPercentage);
  const handleRemoveFromView = (): void => removeFromView(attachmentData.id);
  const handleRemoveFromQueue = (): void => removeFromQueue(attachmentData.id);
  const handleRetryUpload = (): void => retryUpload(attachmentData);
  const handleStopUpload = (): void => attachmentData.tokenSource.abort();

  return (
    <Box sx={{ px: 2, '&:not(:last-of-type)': { mb: 5 } }}>
      <AttachmentHeader
        attachmentName={attachmentData.attachment.name}
        error={error}
        removeFromQueue={handleRemoveFromQueue}
        removeFromView={handleRemoveFromView}
        retryUpload={handleRetryUpload}
        stopUpload={handleStopUpload}
        isPending={isPending}
        isUploaded={isUploaded}
      />
      <FccLinearProgress value={uploadedPercentage} />
      <UploadInfo
        uploadedPercentage={uploadedPercentage}
        error={error}
        attachmentSize={attachmentData.attachment.size}
        isPending={isPending}
        isUploaded={isUploaded}
      />
    </Box>
  );
}
