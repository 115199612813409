import React from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const PREFIX = 'MainContainer';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: '1 1 auto',
    overflow: 'auto',
    marginTop: '64px',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 256,
    },
  },
}));

const MainContainer = (): React.ReactElement => {
  return (
    <Root className={classes.root}>
      <Outlet />
    </Root>
  );
};

export default MainContainer;
