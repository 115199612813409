import React, { CSSProperties, ReactNode } from 'react';

import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';

interface IProps {
  children: ReactNode;
  isDrawerOpen: boolean;
  onClose: () => void;
  width?: string;
  transitionDuration?: number;
  customPadding?: string;
  isDialogDrawer?: boolean;
  ModalProps?: { container: Element; style: CSSProperties };
  BackdropProps?: { style: CSSProperties };
  ariaLabel?: string;
}

export default function DrawerPanel({
  isDrawerOpen,
  onClose,
  children,
  width = '340px',
  transitionDuration,
  customPadding = '',
  isDialogDrawer,
  ModalProps,
  BackdropProps,
  ariaLabel,
}: IProps): React.ReactElement {
  const theme = useTheme();
  const topOffset = isDialogDrawer ? '72px' : '64px';

  return (
    <Drawer
      aria-label={ariaLabel}
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
      transitionDuration={transitionDuration}
      componentsProps={{ backdrop: { style: { background: 'transparent' }, ...BackdropProps } }}
      ModalProps={ModalProps}
      PaperProps={{
        style: {
          top: topOffset,
          width: width,
          height: `calc(100% - ${topOffset})`,
          padding: customPadding || `${theme.spacing(2)} ${theme.spacing(4)}`,
          boxSizing: 'border-box',
          position: isDialogDrawer ? 'absolute' : 'fixed',
        },
      }}
    >
      {children}
    </Drawer>
  );
}
