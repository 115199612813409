import React from 'react';

import useUnhandledPromiseErrors from './hooks/use-unhandled-promise-errors.hook';
import MainLayout from './layouts/main-layout';
import './App.css';

function App(): React.ReactElement {
  useUnhandledPromiseErrors();

  return <MainLayout />;
}

export default App;
