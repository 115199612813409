import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import uniq from 'lodash/fp/uniq';
import map from 'lodash/map';
import omit from 'lodash/omit';

import { ClientService } from './client.service';
import { CurrentUserService } from './current-user.service';
import apiService from 'src/api/api.service';
import {
  ITenantList,
  ITenantListQueryParams,
  ITenantListQueryParamsBE,
  ITenantsDataUI,
} from '../interfaces/tenant.type';
import { ClientServicesEnum } from '../enums/client-services.enum';
import { TenantStatusEnum } from '../shared/widgets/tenant-management/utils/tenant-status.enum';
import { chatServices } from '../constants/chat-services.constant';
import { apiUrls } from 'src/api/api-urls.constant';
import { rowsPerPageValues } from 'src/constants/rows-per-page-values.constant';

export const chatTenantsParams = {
  status: [TenantStatusEnum.LIVE],
  page: 0,
  rowsPerPage: rowsPerPageValues.optimistic,
  services: chatServices,
};

export class TenantsService {
  public static getTenants(
    searchParams: Partial<ITenantListQueryParams>,
    signal: AbortSignal,
  ): Promise<ITenantsDataUI> {
    const url = CurrentUserService.isOrgUser ? apiUrls.tenantsByOrganization : apiUrls.tenants;
    const paramsObj = CurrentUserService.isOrgUser
      ? omit(searchParams, ['organizationId', 'noOrganization'])
      : searchParams;

    const params = TenantsService.buildTenantListParams(paramsObj);

    return apiService
      .get<{ data: ITenantList }>({ url, signal, params })
      .then(TenantsService.mapTenantListResponse);
  }

  public static getServicesFilterParam(): ClientServicesEnum[] {
    return CurrentUserService.isGlobalAdmin() ? null : ClientService.getUserServicesByRole();
  }

  public static getOrgChatServices(signal: AbortSignal): Promise<ClientServicesEnum[]> {
    return TenantsService.getTenants(chatTenantsParams, signal).then((data) => {
      const tenantServices = data?.data?.reduce((acc, tenant) => [...acc, ...tenant.services], []);

      return flow(
        uniq,
        filter((item: ClientServicesEnum) => chatServices.includes(item)),
      )(ClientService.getActiveServiceNames(tenantServices));
    });
  }

  private static mapTenantListResponse(res: { data: ITenantList }): ITenantsDataUI {
    return {
      data: map(res.data.data, (data) => ({ ...data.tenant, number_of_users: data.users_count })),
      total: res.data.total,
    };
  }

  private static buildTenantListParams(params: Partial<ITenantListQueryParams>): ITenantListQueryParamsBE {
    return {
      limit: params.rowsPerPage,
      no_organization: params.noOrganization,
      organization: params.organizationId,
      page: +params.page + 1,
      q: params.search || null,
      sort_column: params.orderBy,
      sort_type: params.order,
      status: params.status?.length ? params.status : null,
      service: params.services?.length ? params.services : null,
    };
  }
}
