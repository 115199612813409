import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TprmIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M22.3939 6.82839C22.9462 6.82839 23.3939 7.27611 23.3939 7.82839C23.3939 8.38068 22.9462 8.82839 22.3939 8.82839L19.3939 8.82839C18.8416 8.82839 18.3939 8.38067 18.3939 7.82839C18.3939 7.27611 18.8416 6.82839 19.3939 6.82839L22.3939 6.82839Z" />
      <path d="M14.9142 1.47971C14.9142 0.927423 15.362 0.479707 15.9142 0.479707C16.4665 0.479707 16.9142 0.927423 16.9142 1.47971L16.9142 4.47971C16.9142 5.03199 16.4665 5.47971 15.9142 5.47971C15.362 5.47971 14.9142 5.03199 14.9142 4.47971L14.9142 1.47971Z" />
      <path d="M19.4797 2.91418C19.8702 2.52366 20.5034 2.52366 20.8939 2.91418C21.2844 3.30471 21.2844 3.93787 20.8939 4.3284L19.4797 5.74261C19.0892 6.13314 18.456 6.13314 18.0655 5.74261C17.675 5.35209 17.675 4.71892 18.0655 4.3284L19.4797 2.91418Z" />
      <path d="M6.82839 22.3937C6.82839 22.946 7.27611 23.3937 7.82839 23.3937C8.38068 23.3937 8.82839 22.946 8.82839 22.3937L8.82839 19.3937C8.82839 18.8414 8.38067 18.3937 7.82839 18.3937C7.27611 18.3937 6.82839 18.8414 6.82839 19.3937L6.82839 22.3937Z" />
      <path d="M1.47971 14.914C0.927423 14.914 0.479707 15.3617 0.479707 15.914C0.479707 16.4663 0.927423 16.914 1.47971 16.914L4.47971 16.914C5.03199 16.914 5.47971 16.4663 5.47971 15.914C5.47971 15.3617 5.03199 14.914 4.47971 14.914L1.47971 14.914Z" />
      <path d="M2.91418 19.4795C2.52366 19.87 2.52366 20.5032 2.91418 20.8937C3.30471 21.2842 3.93787 21.2842 4.3284 20.8937L5.74261 19.4795C6.13314 19.0889 6.13314 18.4558 5.74261 18.0652C5.35209 17.6747 4.71892 17.6747 4.3284 18.0652L2.91418 19.4795Z" />
      <path d="M20.9204 16.6776L18.799 14.5563C18.2555 14.0128 17.4769 13.8475 16.7905 14.0605C16.7797 14.0494 16.7689 14.0385 16.758 14.0275L15.2914 12.561C16.8328 11.6129 18.8777 11.8066 20.2133 13.1421L22.3346 15.2634C23.8967 16.8255 23.8967 19.3582 22.3346 20.9203L20.9204 22.3345C19.3583 23.8966 16.8256 23.8966 15.2635 22.3345L13.1422 20.2132C11.842 18.9129 11.624 16.9403 12.4883 15.4146L13.9296 16.856C13.9593 16.8856 13.9893 16.9146 14.0198 16.9428C13.8739 17.5898 14.0527 18.2953 14.5564 18.7989L16.6777 20.9203C17.4588 21.7013 18.7251 21.7013 19.5062 20.9203L20.9204 19.5061C21.7014 18.725 21.7014 17.4587 20.9204 16.6776Z" />
      <path d="M7.48526 3.24264L9.60658 5.36396C10.1306 5.88797 10.303 6.63042 10.1239 7.2982C10.1448 7.31725 10.1652 7.33685 10.1854 7.357L11.6388 8.81039C12.5447 7.27648 12.3387 5.26768 11.0208 3.94975L8.89947 1.82843C7.33738 0.26633 4.80472 0.26633 3.24262 1.82843L1.8284 3.24264C0.266307 4.80474 0.266307 7.3374 1.8284 8.89949L3.94972 11.0208C5.26766 12.3387 7.27646 12.5447 8.81036 11.6388L7.35697 10.1854C7.33682 10.1653 7.31723 10.1448 7.29817 10.1239C6.63039 10.3031 5.88795 10.1306 5.36394 9.6066L3.24262 7.48528C2.46157 6.70423 2.46157 5.4379 3.24262 4.65685L4.65683 3.24264C5.43788 2.46159 6.70421 2.46159 7.48526 3.24264ZM8.42807 9.3709L9.84228 10.7851L10.7851 9.8423L9.37088 8.42809L8.42807 9.3709Z" />
      <path d="M12.435 13.8495L15.9705 17.385C16.361 17.7755 16.9942 17.7755 17.3847 17.385C17.7752 16.9945 17.7752 16.3613 17.3847 15.9708L13.8492 12.4352L12.435 13.8495ZM11.7279 10.3139L8.19234 6.7784C7.80181 6.38787 7.16865 6.38787 6.77812 6.7784C6.3876 7.16892 6.3876 7.80209 6.77812 8.19261L10.3137 11.7281L11.7279 10.3139Z" />
    </SvgIcon>
  );
}
