import { IEmailRedirectParams, IEntityDetailsData, IEntityListData, INotification } from '../notifications.type';
import { ISelectedEntity } from 'src/store/slices/global-search/utils/selected-entity.type';
import { NotificationServiceValueEnum } from './notification-services.enum';
import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';
import { emailDetailsTypes } from './constants/email-details-types.constant';
import { emailListTypes } from './constants/email-list-types.constant';

export class EmailRedirectService {
  public static getRedirectEventData(params: Partial<IEmailRedirectParams>): Partial<INotification> {
    const { entityType, service, client_id } = params;

    const eventDetails = emailDetailsTypes.includes(entityType)
      ? EmailRedirectService.getEntityDetailsData(params)
      : emailListTypes.includes(entityType)
      ? EmailRedirectService.getEntityListData(params)
      : {};

    return { service, client_id, type: entityType, ...eventDetails };
  }

  private static getEntityDetailsData(params: Partial<IEmailRedirectParams>): { data: IEntityDetailsData } {
    const { entityType, id, action, groupId: group_id, isRealTime, assessment_id, tab } = params;

    const data: Record<string, IEntityDetailsData> = {
      [NotificationTypesEnum.ASSESSMENT_DOCUMENT]: { id, assessment_id, tab },
      [NotificationTypesEnum.ESCALATION_ASSIGNMENT]: { id },
      [NotificationTypesEnum.ESCALATION_DETAILS]: { id, action },
      [NotificationTypesEnum.ESCALATION_THREAD]: { id },
      [NotificationTypesEnum.VULNERABILITY_ASSIGNMENT]: { id, group_id },
      [NotificationTypesEnum.EMAIL_REDIRECT_DETAILS]: { id, group_id, is_realtime: !!isRealTime },
      [NotificationTypesEnum.RISK_ASSIGNMENT]: { id, action },
    };

    return { data: data[entityType] };
  }

  private static getEntityListData(params: Partial<IEmailRedirectParams>): IEntityListData {
    return {
      description: params.message,
      name: params.title,
      data: params.q
        ? { q: params.q }
        : params.service === NotificationServiceValueEnum.VTM
        ? EmailRedirectService.getVtmData(params)
        : params.ids?.split(',')?.map((item) => ({ id: item })),
    };
  }

  private static getVtmData(params: Partial<IEmailRedirectParams>): ISelectedEntity[] {
    return params.ids.split(',').map((id) => {
      const [pluginId, groupId] = id.split(':');

      return { id: pluginId, client_ids: [{ client_id: params.client_id, group_id: Number(groupId) }] };
    });
  }
}
