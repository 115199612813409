export enum ClientServicesEnum {
  VTM = 'VTM',
  SIEM = 'SIEM',
  MDR = 'MDR',
  IOMT = 'IOMT',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  RISK_REGISTER = 'RISK_REGISTER',
  VISP = 'VISP',
  PENTESTING = 'PENTESTING',
  INCIDENT_RESPONSE = 'INCIDENT_RESPONSE',
  TPRM = 'TPRM',
  MSAT = 'MSAT',
  XDR = 'XDR',
}
