import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import invert from 'lodash/invert';
import reduce from 'lodash/reduce';

import apiService from '../api/api.service';
import { DateTimeService } from './date-time.service';
import { IPermissions } from '../interfaces/permission.type';
import { IUser } from '../interfaces/user.type';
import { MenuOptionsEnum } from '../enums/menu-options.enum';
import { UserPermissionsEnum } from '../enums/user-permissions.enum';
import { UserRolesEnum } from '../enums/user-roles.enum';
import { apiUrls } from '../api/api-urls.constant';
import { uiPermissionsMap } from '../constants/user-permissions-ui.constant';
import { riskAssessmentRoles, riskRegisterRoles, userRolesMap } from '../constants/user-roles.constant';

export class CurrentUserService {
  public static permissions: IPermissions;
  public static roles: UserRolesEnum[];
  public static user: IUser;
  public static isUserInCoreTeam: boolean;

  public static isOrgUser: boolean;
  public static userOrgClientId: string;
  public static userOrganizationId: number;
  public static userOrganizationName: string;

  public static userClientId: string;
  public static userTenantId: number;
  public static userTenantName: string;
  public static userTimezone: string;

  public static getDefaultRoute(): string {
    const { UNIFIED_DASHBOARD, ORG_TENANTS } = MenuOptionsEnum;
    const isOrgUser = CurrentUserService.isOrgUser;

    const adminPath = CurrentUserService.getAdminPath();
    const orgPath = isOrgUser ? `/${ORG_TENANTS}` : null;
    const clientPath = `/${UNIFIED_DASHBOARD}`;

    return adminPath || orgPath || clientPath;
  }

  public static clientsOriented(): boolean {
    return CurrentUserService.permissions?.VIEW_CLIENTS_AND_SERVICES;
  }

  public static notOperationalClientRoles(): boolean {
    return CurrentUserService.isUserRolesInclude([UserRolesEnum.OBSERVER, UserRolesEnum.LEADER]);
  }

  public static setUserData(user: IUser): void {
    CurrentUserService.roles = user?.roles.map((role) => role.name);
    CurrentUserService.user = user;
    CurrentUserService.isUserInCoreTeam = user?.in_core_team;

    CurrentUserService.isOrgUser = !!user?.organization;
    CurrentUserService.userOrgClientId = user?.organization?.client_id;
    CurrentUserService.userOrganizationId = user?.organization?.id;
    CurrentUserService.userOrganizationName = user?.organization?.name;

    const tenant = user?.tenant;

    CurrentUserService.userClientId = get(tenant, 'client_id');
    CurrentUserService.userTenantName = get(tenant, 'name');
    CurrentUserService.userTenantId = get(tenant, 'id');

    const bePermissions = CurrentUserService.configureBePermissions(user);
    const uiPermissions = CurrentUserService.configureUiPermissions();

    CurrentUserService.permissions = { ...bePermissions, ...uiPermissions };
    CurrentUserService.userTimezone = DateTimeService.getCurrentTimezone();
  }

  public static async getCurrentUserData(): Promise<IUser> {
    const response = await apiService.get<AxiosResponse<IUser>>({ url: `${apiUrls.users}me/` });
    return response.data;
  }

  public static async getUserDataByUserIdAndTenantId(
    userId: number,
    clientId: string,
    signal: AbortSignal,
  ): Promise<IUser> {
    const params = { client_id: clientId };
    const response = await apiService.get<AxiosResponse<IUser>>({
      url: `${apiUrls.users}${userId}/`,
      params,
      signal,
    });
    return response.data;
  }

  public static acceptTermsAndConditions(userId: number): Promise<void> {
    const url = `${apiUrls.users}${userId}/accept`;

    return apiService.patch({ url });
  }

  public static confirmEmailVerification(code: string): Promise<void> {
    const url = `${apiUrls.users}confirm_email_verification`;
    const data = { code };

    return apiService.post({ url, data });
  }

  private static configureBePermissions(user: IUser): Record<keyof typeof UserPermissionsEnum, boolean> {
    const permissionsMap = invert(UserPermissionsEnum);
    return (
      user?.permissions
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((data: any) => data.machine_name)
        .reduce((acc, item) => ({ ...acc, [permissionsMap[item]]: true }), {})
    );
  }

  private static configureUiPermissions(): Record<string, boolean> {
    return reduce(uiPermissionsMap, (acc, item) => ({ ...acc, [item.permission]: item.checkAccess() }), {});
  }

  public static isUserRolesInclude(
    roles: UserRolesEnum[],
    userRoles: UserRolesEnum[] = CurrentUserService.roles,
  ): boolean {
    return userRoles?.some((role) => roles.includes(role));
  }

  public static isUserRolesWithin(
    roles: UserRolesEnum[],
    userRoles: UserRolesEnum[] = CurrentUserService.roles,
  ): boolean {
    return userRoles?.every((role) => roles.includes(role));
  }

  public static isSpecificRole(role: UserRolesEnum): boolean {
    const roles = CurrentUserService.roles;
    return roles?.length === 1 && roles[0] === role;
  }

  public static isRiskRegisterUser(): boolean {
    return CurrentUserService.isUserRolesWithin(riskRegisterRoles);
  }

  public static isGlobalAdmin(): boolean {
    return CurrentUserService.isUserRolesInclude([UserRolesEnum.GLOBAL_ADMIN]);
  }

  public static isAdvisoryServiceUser(): boolean {
    return CurrentUserService.isUserRolesWithin([...riskRegisterRoles, ...riskAssessmentRoles]);
  }

  public static isRiskAssessmentViewer(): boolean {
    return CurrentUserService.isUserRolesInclude([UserRolesEnum.RA_FCC_VIEWER, UserRolesEnum.RA_CLIENT_VIEWER]);
  }

  public static mapUserRoleNamesToUI(role: UserRolesEnum): UserRolesEnum {
    const mappedRole = userRolesMap[role] as UserRolesEnum;
    return mappedRole || role;
  }

  private static getAdminPath(): string {
    const { CLIENTS, USER_MANAGEMENT } = MenuOptionsEnum;
    const isHelpDesk = CurrentUserService.isSpecificRole(UserRolesEnum.HELP_DESK);
    const isAdmin = CurrentUserService.isUserInCoreTeam;

    if (isHelpDesk) {
      return `/${USER_MANAGEMENT}`;
    }

    return isAdmin ? `/${CLIENTS}` : null;
  }
}
