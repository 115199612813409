import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import getTime from 'date-fns/getTime';
import parseISO from 'date-fns/parseISO';
import isNull from 'lodash/isNull';

enum TimeUnits {
  MINUTE = 'Min',
  HOUR = 'Hour',
  DAY = 'Day',
}

export const dateTimeFormat = {
  MM_DD_YYYY: 'MM/dd/yyyy',
  EEEE_MM_DD_YYYY: 'EEEE, MM/dd/yyyy',
  DD_MM_YYYY: 'dd.MM.yyyy',
  MM_DD_YYYY_H_MM_A: 'MM/dd/yyyy, h:mm a',
  MM_DD_YYYY_H_MM_A_TIMEZONE: "MM/dd/yyyy, h:mm a '(UTC 'xxx')'",
  TIMEZONE: 'xxx',
  MM_DD_YYYY_H_MM_SS_A: 'MM/dd/yyyy, h:mm:ss a',
  MM_DD_YYYY_AT_H_MM_A: "MM/dd/yyyy 'at' h:mm a",
  YYYY_MM_DD_H_MM_A: 'yyyy/MM/dd, h:mm a',
  EEEE_LLLL_d_yyyy: 'EEEE, LLLL d, yyyy',
  EEEE_YYYY_MM_DD: 'EEEE, yyyy/MM/dd',
  YYYY_MM_DD: 'yyyy-MM-dd',
  YYYY_MM_DD_HH_MM_Z: 'yyyy-MM-dd HH:mm, z',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd HH:mm:ss',
  H_MMAAA: 'h:mmaaa',
};

export class DateTimeService {
  public static getLocaleDateTimeFormat(date: string, formatData: string): string {
    return date ? format(getTime(parseISO(date)), formatData) : date;
  }

  public static formatDate(date: Date, formatData: string): string {
    return format(date, formatData);
  }

  public static getTimeInMilliSeconds(date: string): number {
    return getTime(parseISO(date));
  }

  public static getLocalTimeInMilliSeconds(timestamp: number): number {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    return timestamp - timeZoneOffset;
  }

  public static getCurrentDateTime(): string {
    return formatISO(new Date());
  }

  public static getCurrentTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public static getDifferenceInMilliseconds(end: Date, start: Date): number {
    return differenceInMilliseconds(end, start);
  }

  public static getUnits(minutes: number): Record<string, number> {
    const inHours = (minutes - (minutes % 60)) / 60;

    const minutesLeftOver = minutes % 60 || null;
    const hoursLeftOver = inHours % 24 || null;
    const daysLeftOver = (inHours - hoursLeftOver) / 24 || null;

    const unitsData: Record<string, number> = {
      Day: daysLeftOver,
      Hour: hoursLeftOver,
      Min: hoursLeftOver || daysLeftOver ? minutesLeftOver : minutesLeftOver || 0,
    };

    return Object.keys(unitsData).reduce(
      (res, key) => (isNull(unitsData[key]) ? res : { ...res, [key]: unitsData[key] }),
      {},
    );
  }

  public static getDurationString(seconds: number): string {
    const secondsInteger = Math.trunc(seconds);

    const hours = (secondsInteger - (secondsInteger % 3600)) / 3600;
    if (hours >= 1) {
      const minutes = Math.trunc((secondsInteger % 3600) / 60);
      const minutesString = minutes >= 1 ? `${minutes} min` : '';
      return `${hours} h ${minutesString}`;
    }

    const minutes = (secondsInteger - (secondsInteger % 60)) / 60;
    if (minutes >= 1) return `${minutes} min`;

    return `${secondsInteger} sec`;
  }

  public static mapUnitsValueToString(data: Record<TimeUnits, number>): string {
    return Object.keys(data).reduce((acc, unit) => {
      const unitSuffix = unit !== TimeUnits.MINUTE && data[unit as TimeUnits] > 1 ? 's' : '';
      return `${acc} ${data[unit as TimeUnits]} ${unit.toLowerCase()}${unitSuffix}`;
    }, '');
  }
}
