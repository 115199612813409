import { AxiosResponse } from 'axios';

import apiService from 'src/api/api.service';
import { IAttachmentData, IAttachmentResponse } from './uploading-attachments.type';

export class UploadingAttachmentsService {
  public static uploadAttachment(
    data: IAttachmentData,
    onUploadProgress: (event: ProgressEvent, attachmentId: string, attachmentSize: number) => void,
  ): Promise<IAttachmentResponse> {
    const { url, attachment, id, clientId, tokenSource } = data;
    const params = { client_id: clientId };

    return apiService
      .post<AxiosResponse>({
        url,
        params,
        data: attachment,
        headers: {
          'Content-Type': attachment.type,
          'Content-Disposition': `form-data; name="attachment"; filename="${attachment.name}"`,
        },
        onUploadProgress: (event) => onUploadProgress(event, id, attachment.size),
        signal: tokenSource.signal,
      })
      .then((res) => res?.data);
  }
}
