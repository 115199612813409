import React from 'react';

import { SnackbarProvider } from 'notistack';

function FccSnackbarProvider({ children }: { children: React.ReactElement }): React.ReactElement {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      variant="success"
    >
      {children}
    </SnackbarProvider>
  );
}

export default FccSnackbarProvider;
