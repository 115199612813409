import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';

import { MainLayoutService } from '../../main-layout.service';
import { SessionService } from 'src/api/session.service';
import { AuthService } from 'src/services/auth.service';
import { logout } from 'src/store/slices/user/user.slice';
import { useDispatch } from 'src/store';

interface IProps {
  title: string;
  showChatHistory: boolean;
}

const Account = ({ title, showChatHistory }: IProps): React.ReactElement => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    await AuthService.signOut();
    navigate('/login');
    SessionService.clearSession();
    dispatch(logout());
    queryClient.removeQueries();
  };

  const goToProfile = (): void => {
    navigate(`${MainLayoutService.getRoutePath()}/user-profile`);
    setIsOpen(false);
  };

  const goToCommunicationPreferences = (): void => {
    navigate(`${MainLayoutService.getRoutePath()}/communication-preferences`);
    setIsOpen(false);
  };

  const goToChatHistory = (): void => {
    navigate(`${MainLayoutService.getRoutePath()}/chat-history`);
    setIsOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar
          alt={title}
          sx={{ height: 36, width: 36, marginRight: 2, backgroundColor: '#8E7FAE', fontSize: '14px' }}
        >
          {title}
        </Avatar>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        slotProps={{ paper: { style: { width: 250 } } }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={goToProfile}>User Profile</MenuItem>
        {showChatHistory && <MenuItem onClick={goToChatHistory}>Chat History</MenuItem>}
        <MenuItem onClick={goToCommunicationPreferences}>Communication Preferences</MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
