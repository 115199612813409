import React from 'react';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

export default function FccCheckbox({
  icon,
  checkedIcon,
  checked,
  indeterminate,
  onChange,
  inputProps,
  name,
  value,
  color,
  disabled,
  className,
}: CheckboxProps): React.ReactElement {
  return (
    <Checkbox
      icon={icon}
      checkedIcon={checkedIcon}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      inputProps={inputProps}
      name={name}
      value={value}
      color={color}
      disabled={disabled}
      className={className}
    />
  );
}
