import React, { useEffect, useState } from 'react';

import Brightness2 from '@mui/icons-material/Brightness2';
import WbSunny from '@mui/icons-material/WbSunny';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { UserPreferencesService } from 'src/services/user-preferences.service';
import { IUserPreference } from '../../../../interfaces/user-preferences.type';
import { ThemesEnum } from 'src/enums/themes.enum';
import { updateTheme } from 'src/store/slices/user-preferences/user-preferences.slice';
import { getThemeSelector } from 'src/store/slices/user-preferences/user-preferences.selector';
import { useDispatch, useSelector } from 'src/store';

export default function ThemeSwitcher(): React.ReactElement {
  const theme: IUserPreference = useSelector(getThemeSelector);
  const [currentTheme, setCurrentTheme] = useState<ThemesEnum>(ThemesEnum.LIGHT);
  const [themePreference, setThemePreference] = useState<IUserPreference>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    theme && setCurrentTheme(theme?.value as ThemesEnum);
    setThemePreference(theme);
  }, [theme]);

  const handleChangeMode = (): void => {
    const updatedTheme = currentTheme === ThemesEnum.LIGHT ? ThemesEnum.DARK : ThemesEnum.LIGHT;
    UserPreferencesService.setUserTheme(updatedTheme);
    UserPreferencesService.saveUserPreferences({ value: updatedTheme }, themePreference.id).then((res) => {
      dispatch(updateTheme(res));
    });
  };

  return (
    <IconButton
      aria-label="theme-switcher"
      color="inherit"
      onClick={handleChangeMode}
      sx={{ color: 'text.secondary', transform: 'rotate(160deg)', opacity: 0.8 }}
      size="large"
    >
      <SvgIcon>{currentTheme === ThemesEnum.LIGHT ? <Brightness2 /> : <WbSunny />}</SvgIcon>
    </IconButton>
  );
}
