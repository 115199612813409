import { ClientService } from './client.service';
import { CurrentUserService } from './current-user.service';
import { UserRolesEnum } from 'src/enums/user-roles.enum';

export class UserPermissionService {
  static hasAuditLogViewAccess(): boolean {
    const hasAdminPermission = CurrentUserService.permissions.VIEW_AUDIT_LOG_LIST;
    const hasClientPermission =
      !ClientService.isAdminViewMode() && CurrentUserService.permissions.VIEW_TENANT_AUDIT_LOG_LIST;

    return hasAdminPermission || hasClientPermission;
  }

  static hasTenantManagementViewAccess(): boolean {
    const hasPermission = CurrentUserService.permissions.VIEW_TENANT_LIST;
    const hideByRole = CurrentUserService.isUserRolesWithin([
      UserRolesEnum.THREAT_ANALYST,
      UserRolesEnum.HELP_DESK,
      UserRolesEnum.ESCALATION_APPROVER,
      UserRolesEnum.RA_FCC_ADMIN,
      UserRolesEnum.RA_FCC_ANALYST,
      UserRolesEnum.RA_FCC_VIEWER,
      UserRolesEnum.RR_FCC_ADMIN,
      UserRolesEnum.RR_FCC_ANALYST,
      UserRolesEnum.RR_FCC_VIEWER,
    ]);

    return hasPermission && !hideByRole;
  }
}
