import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { SessionService } from '../../api/session.service';
import { AuthService } from '../../services/auth.service';
import { CurrentUserService } from 'src/services/current-user.service';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { ToastMessages } from 'src/constants/toast-messages.constant';
import { logout } from '../../store/slices/user/user.slice';
import FccCheckbox from '../components/controls/checkbox';
import CustomDialog from '../components/custom-dialog';
import { useDispatch } from 'src/store';

const classes = {
  text: { fontSize: '16px', lineHeight: '24px' },
  label: { fontWeight: 500, fontSize: '14px', lineHeight: '22px' },
  link: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: 'primary.main',
    textDecorationColor: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
};

const dialogData = {
  title: 'Terms of Use & Privacy Policy',
  closeButtonTitle: 'DECLINE AND LOG OUT',
  confirmButtonTitle: 'ACCEPT',
};

const links = [
  { href: 'https://fortifiedhealthsecurity.com/digital-platform-services-terms-of-use/', title: 'View Terms of Use' },
  { href: 'https://fortifiedhealthsecurity.com/privacy-notice/', title: 'View Privacy Policy' },
];

const confirmButtonTooltip = 'Terms of Use & Privacy Policy can’t be accepted until the checkbox has been checked.';

export default function TermsOfUseAndPrivacyPolicyDialog(): React.ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = CurrentUserService.user;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(
    !currentUser?.in_core_team && !currentUser?.is_conditions_accepted,
  );
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isAcceptingInProgress, setIsAcceptingInProgress] = useState<boolean>(false);

  const handleConfirm = (): void => {
    setIsAcceptingInProgress(true);

    CurrentUserService.acceptTermsAndConditions(currentUser.id)
      .then(() => {
        CurrentUserService.setUserData({ ...currentUser, is_conditions_accepted: true });
        setIsDialogOpen(false);
      })
      .catch(() => enqueueSnackbar(ToastMessages.generalError, { variant: ToastMessageSeveritiesEnum.ERROR }))
      .finally(() => setIsAcceptingInProgress(false));
  };

  const handleReject = async (): Promise<void> => {
    await AuthService.signOut();
    navigate('/login');
    SessionService.clearSession();
    dispatch(logout());
    queryClient.removeQueries();
  };

  return (
    <CustomDialog
      confirm={handleConfirm}
      data={{ ...dialogData, confirmButtonTooltip: !isAccepted ? confirmButtonTooltip : '' }}
      open={isDialogOpen}
      reject={handleReject}
      maxWidth="md"
      dialogClasses={{ width: '656px' }}
      isConfirmDisabled={!isAccepted}
      dialogStyles={{ '&.MuiDialog-root': { zIndex: 1310 } }}
      showCloseIcon={false}
      isActionSubmitting={isAcceptingInProgress}
    >
      <Box>
        <Box sx={[classes.text, { mb: 6 }]}>
          By checking the box and clicking “accept” below, I agree to Fortified’s Terms of Use and Privacy Policy.
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          {links.map(({ href, title }) => (
            <Link
              key={title}
              onClick={(event): void => event.stopPropagation()}
              href={href}
              target="_blank"
              rel="noreferrer"
              sx={classes.link}
            >
              {title} <OpenInNewIcon sx={{ color: 'text.secondary' }} />
            </Link>
          ))}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 7 }}>
          <FccCheckbox
            onChange={(e, value): void => setIsAccepted(value)}
            name="checked"
            checked={isAccepted}
            inputProps={{ 'aria-label': 'accept terms' }}
          />
          <Box sx={classes.label}>I have read and agreed to Fortified’s Terms of Use & Privacy Policy</Box>
        </Box>
      </Box>
    </CustomDialog>
  );
}
