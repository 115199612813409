import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { UserPreferencesService } from 'src/services/user-preferences.service';
import { IUserPreferencesSlice } from './utils/user-preferences-slice.type';

const initialState: IUserPreferencesSlice = {
  data: null,
};

export const fetchUserPreferencesData = createAsyncThunk('userPreferences/data', () =>
  UserPreferencesService.getUserPreferences(),
);

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    updatePreference(state, action) {
      state.data.forEach((preference) => {
        if (preference?.key === action.payload.key) {
          preference.value = action.payload.value;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreferencesData.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});

const { actions } = userPreferencesSlice;
export const updateTheme = actions.updatePreference;
export const updateCommunicationPreferences = actions.updatePreference;
