import { AxiosResponse } from 'axios';
import map from 'lodash/map';

import apiService from 'src/api/api.service';
import { dateTimeFormat, DateTimeService } from 'src/services/date-time.service';
import { IOrganizationTenant } from '../../../interfaces/organization.type';
import { ITenantUI } from '../../../interfaces/tenant.type';
import {
  INotification,
  INotificationPage,
  INotificationParams,
  INotificationsCount,
  IUnreadNotificationsCountParams,
} from './notifications.type';
import { AllOptionValueEnum } from './utils/notification-services.enum';
import { apiUrls } from 'src/api/api-urls.constant';

export class NotificationsService {
  public static getNotifications(
    queryParams: INotificationParams,
    tenants: ITenantUI[],
    organizationClientId: string,
    signal: AbortSignal,
  ): Promise<INotificationPage> {
    const params = {
      page: queryParams.page,
      limit: queryParams.limit,
      service: queryParams.service === AllOptionValueEnum.ALL ? null : queryParams.service,
      client_id: NotificationsService.getClientIdParam(queryParams.clientId, tenants, organizationClientId),
    };
    return apiService
      .get<AxiosResponse>({ url: apiUrls.notifications, params, signal })
      .then((response) => {
        return {
          total_count: response?.data?.total_count,
          data: map(response?.data?.data, (notification) => NotificationsService.mapNotification(notification)),
        };
      });
  }

  public static getNotificationsCount(
    queryParams: INotificationParams,
    tenants: ITenantUI[],
    organizationClientId: string,
    signal: AbortSignal,
  ): Promise<INotificationsCount> {
    const params = {
      client_id: NotificationsService.getClientIdParam(queryParams.clientId, tenants, organizationClientId),
    };

    return apiService
      .get<AxiosResponse>({ url: apiUrls.notificationsCount, params, signal })
      .then((response) => response?.data);
  }

  public static getUnreadNotificationsCount(
    queryParams: IUnreadNotificationsCountParams,
    tenants: IOrganizationTenant[],
    signal: AbortSignal,
  ): Promise<number> {
    const { tenantId, isAdminViewMode } = queryParams;
    const params = {
      client_id: isAdminViewMode ? null : tenantId || tenants?.map((tenant) => tenant.client_id),
    };

    return apiService
      .get<AxiosResponse>({ url: apiUrls.unreadNotificationsCount, params, signal })
      .then((response) => response?.data?.count);
  }

  public static readNotification(id: number): Promise<void> {
    return apiService
      .put<AxiosResponse>({ url: apiUrls.notificationRead, data: { notification_id: id } })
      .then((response) => response.data);
  }

  public static mapNotification(notification: INotification): INotification {
    return {
      ...notification,
      created_at: DateTimeService.getLocaleDateTimeFormat(notification.created_at, dateTimeFormat.MM_DD_YYYY_H_MM_A),
    };
  }

  public static getClientIdParam(
    tenantId: string,
    tenants: ITenantUI[],
    organizationClientId: string,
  ): string | string[] {
    const tenantIds = tenants ? tenants.map((tenant) => tenant.client_id) : [];
    return tenantId === AllOptionValueEnum.ALL ? [organizationClientId, ...tenantIds] : tenantId;
  }

  public static markAllNotificationsAsRead(): Promise<void> {
    const url = `${apiUrls.notificationRead}all/`;

    return apiService
      .post<AxiosResponse>({ url })
      .then((response) => response.data);
  }
}
