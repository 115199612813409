import { useNavigate } from 'react-router-dom';

import { CurrentUserService } from 'src/services/current-user.service';
import useSelectedClient from './use-selected-client.hook';
import { buildQueryParamsFromObject } from 'src/utils/query-params-mapper.util';

interface IProps {
  vtmRedirect: (clientId: string, source: string, groupId: string) => Promise<void>;
}

export default function useVtmRedirect(): IProps {
  const navigate = useNavigate();
  const { checkSelectedClient } = useSelectedClient();

  const vtmRedirect = async (clientId: string, source: string, scanGroup: string): Promise<void> => {
    await checkSelectedClient(clientId);

    const queryParams = buildQueryParamsFromObject({ source, scanGroup });
    const redirectRoute = getRedirectPath(clientId);
    navigate(`${redirectRoute}vtm/vulnerabilities?${queryParams}`);
  };

  const getRedirectPath = (clientId: string): string => {
    return CurrentUserService.isOrgUser || CurrentUserService.isUserInCoreTeam ? `tenants/${clientId}/` : '';
  };

  return { vtmRedirect };
}
