import { CurrentUserService } from '../services/current-user.service';
import { ClientService } from 'src/services/client.service';

export function settingsGuard(): boolean {
  const hasAdminPermission = CurrentUserService.permissions.TENANT_MASTER_PREFERENCE_GET;
  const hasClientPermission =
    CurrentUserService.permissions.TENANT_PREFERENCE_UPDATE && !ClientService.isAdminViewMode();

  return hasAdminPermission || hasClientPermission;
}
