import { useEffect, useRef } from 'react';

interface IUseDocumentTitleOptions {
  restoreOnUnmount?: boolean;
}

const defaultOptions: IUseDocumentTitleOptions = {
  restoreOnUnmount: false,
};

const titleEnding = ' | Fortified';

export default function useDocumentTitle(title: string, options: IUseDocumentTitleOptions = defaultOptions): void {
  const prevTitleRef = useRef(document.title);
  const newTitle = title + titleEnding;

  if (document.title !== newTitle) document.title = newTitle;

  useEffect(() => {
    if (options?.restoreOnUnmount) {
      return (): void => {
        document.title = prevTitleRef.current;
      };
    }
  }, []);
}
