import isEmpty from 'lodash/fp/isEmpty';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildQueryParamsFromObject(filteringData: { [key: string]: any }): string {
  return Object.keys(filteringData)
    .filter((key) => !isEmpty(filteringData[key]))
    .map((key) => `${key}=${filteringData[key]}`)
    .join('&');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildObjectFromQueryParams(queryParams: string): { [key: string]: any } {
  const urlSearchParams = new URLSearchParams(queryParams);

  return Object.fromEntries(urlSearchParams.entries());
}

export function aggregateQueryParam(params: string, param: string, value: string): string {
  const queryParamsObj = buildObjectFromQueryParams(params);
  return buildQueryParamsFromObject({ ...queryParamsObj, [param]: value });
}

export function aggregateQueryParams(search: string, params: Record<string, string>): string {
  const queryParamsObj = buildObjectFromQueryParams(search);
  return buildQueryParamsFromObject({ ...queryParamsObj, ...params });
}
