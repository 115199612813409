import React from 'react';

import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';

import FccIconButton from 'src/shared/components/icons/fcc-icon-button.component';

interface IProps {
  error: string;
  removeFromQueue: () => void;
  removeFromView: () => void;
  retryUpload: () => void;
  stopUpload: () => void;
  isPending: boolean;
  isUploaded: boolean;
}

export default function AttachmentActions({
  error,
  removeFromQueue,
  removeFromView,
  retryUpload,
  isPending,
  isUploaded,
}: IProps): React.ReactElement {
  // TODO return stop uploading action when there is a solution for SSFCC-9670

  return (
    <>
      {error ? (
        <Box>
          <FccIconButton onClick={retryUpload} ariaLabel="Retry upload">
            <Refresh />
          </FccIconButton>
          <FccIconButton onClick={removeFromView} ariaLabel="Remove from view">
            <DeleteOutlined />
          </FccIconButton>
        </Box>
      ) : isUploaded ? null : isPending ? (
        <FccIconButton onClick={removeFromQueue} ariaLabel="Remove from queue">
          <DeleteOutlined />
        </FccIconButton>
      ) : null}
    </>
  );
}
