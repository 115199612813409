export enum AuditLogOperationNameTypesEnum {
  ATTACHMENT_EXPORT = 'AttachmentExport',
  ATTACHMENT_NOTE_PREVIEW = 'AttachmentNotePreview',
  ATTACHMENT_PREVIEW = 'AttachmentPreview',
  CHANGED_PASSWORD = 'ChangedPassword',
  CHAT_ANALYST_JOINED = 'ChatAnalystJoined',
  CHAT_ENDED = 'ChatEnded',
  CHAT_STARTED = 'ChatStarted',
  CLIENT_SELECTED = 'ClientSelected',
  DOWNLOAD_REPORT = 'DownloadReport',
  DOWNLOADED_ATTACHMENT_PHYSICAL_WALKTHROUGH = 'DownloadAttachmentPhysicalWalkthrough',
  FINAL_REPORT_DOWNLOAD = 'FinalReportDownload',
  FINAL_REPORT_PREVIEW = 'FinalReportPreview',
  FORGOT_PASSWORD = 'ForgotPassword',
  LOG_IN = 'LogIn',
  LOG_OUT = 'LogOut',
  PAGE_VISIT = 'PageVisit',
  PHYSICAL_WALKTHROUGH_ATTACHMENT_PREVIEW = 'PhysicalWalkthroughAttachmentPreview',
  PREVIEW_REPORT = 'PreviewReport',
  RISK_ITEM_ATTACHMENT_EXPORT = 'RiskItemAttachmentExport',
  RISK_ITEM_ATTACHMENT_PREVIEW = 'RiskItemAttachmentPreview',
  TECHNICAL_CONTROL_SURVEY_UPDATE = 'TechnicalControlSurveyUpdate',
  USERS_SEARCHING = 'UsersSearching',
}
