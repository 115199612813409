import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Loader } from '../shared/components/loader.component';
import { SessionService } from '../api/session.service';
import { AuthService } from '../services/auth.service';
import useClientRouteContext from '../hooks/use-client-route-context.hook';
import App from '../App';

export const AppGuardComponent = (): React.ReactElement => {
  const { checkClientRouteContext } = useClientRouteContext();

  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getCurrentAuthenticatedUser();
  }, []);

  const getCurrentAuthenticatedUser = async (): Promise<void> => {
    try {
      await AuthService.configure(AuthService.verifiedUserName);
      await AuthService.currentAuthenticatedUser();
      await checkClientRouteContext();
      setLoggedIn(true);
    } catch (e) {
      SessionService.preserveRoute();
      setLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const content = loggedIn ? <App /> : <Navigate to="/login" />;

  return isLoading ? <Loader isGlobal={true} /> : content;
};
