import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { ICssProperties, ICssStyle } from 'src/interfaces/css-style.type';

interface IProps {
  value: number;
  color?: string;
  height?: string;
  customStyles?: ICssProperties;
  progressClick?: () => void;
}

const classes: ICssStyle = {
  progress: (color: string) => ({
    '& .MuiLinearProgress-bar': {
      backgroundColor: color,
      transition: 'none',
      borderRadius: '4px',
    },
    '&.MuiLinearProgress-determinate': {
      backgroundColor: '#e7e9ed',
      borderRadius: '4px',
    },
  }),
};

export default function FccLinearProgress({
  value,
  color = 'primary.main',
  height = '4px',
  customStyles = {},
  progressClick,
}: IProps): React.ReactElement {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{ ...classes.progress(color), height, ...customStyles }}
      onClick={progressClick}
    />
  );
}
