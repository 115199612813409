export enum TenantPreferenceKeysEnum {
  AUTHORIZATION_DUO_CONFIGURATION = 'Authorization.DUO.Configuration',
  AUTHORIZATION_MFA_ENABLED = 'Authorization.MFA.Enabled',
  AUTHORIZATION_MFA_TYPE = 'Authorization.MFA.Type',
  AUTHORIZATION_SSO_CONFIGURATIONS = 'Authorization.SSO.Configurations',
  AUTHORIZATION_SSO_WHO_ALLOWED_SIGN_IN_WITH_PASSWORD = 'Authorization.SSO.WhoAllowedSignInWithPassword',
  AUTHORIZATION_TYPE = 'Authorization.Type',
  PREMIUM_ANALYST_CHAT = 'Premium.Analyst.Chat',
  ESCALATIONS_EMAILS = 'Escalations.Emails',
  ESCALATIONS_EMAIL_ENABLED = 'Escalations.Email.Enabled',
  COMPARATIVE_ANALYTICS_SIEM = 'SIEM.Comparative.Analytics',
  COMPARATIVE_ANALYTICS_VTM = 'VTM.Comparative.Analytics',
  COMPARATIVE_ANALYTICS_IoMT = 'IOMT.Comparative.Analytics',
  COMPARATIVE_ANALYTICS_MDR = 'MDR.Comparative.Analytics',
}
