import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';

import FccOptionItem from './fcc-option-item.component';
import { ISxProperties } from '../../../../interfaces/css-style.type';

export default function FccSelect<T>({
  children,
  disabled,
  endAdornment,
  error,
  formHelperText,
  fullWidth,
  id,
  label,
  labelId,
  MenuProps,
  inputProps,
  multiple,
  name,
  noneOption,
  onBlur,
  onChange,
  renderValue,
  required,
  startAdornment,
  formControlStyles,
  value,
  variant = 'outlined',
  displayEmpty,
}: {
  formHelperText?: string | string[];
  noneOption?: boolean;
  formControlStyles?: ISxProperties;
} & SelectProps<T>): React.ReactElement {
  return (
    <FormControl
      className="a-select"
      error={error}
      fullWidth={fullWidth}
      required={required}
      sx={formControlStyles}
      variant={variant}
      disabled={disabled}
    >
      <InputLabel className="a-select-title" id={labelId}>
        {label}
      </InputLabel>
      <Select<T>
        endAdornment={endAdornment}
        id={id}
        label={label}
        labelId={labelId}
        MenuProps={MenuProps}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        renderValue={renderValue}
        startAdornment={startAdornment}
        value={value}
        inputProps={inputProps}
        displayEmpty={displayEmpty}
      >
        {isEmpty(children) && !noneOption && <FccOptionItem disabled>No options</FccOptionItem>}
        {noneOption && (
          <FccOptionItem value="">
            <em>None</em>
          </FccOptionItem>
        )}
        {children}
      </Select>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}
