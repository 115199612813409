export enum UserRolesEnum {
  // FCC
  DODSON_MODE = 'Dodson Mode',
  ESCALATION_APPROVER = 'Escalation Approver',
  GLOBAL_ADMIN = 'Global Admin',
  HELP_DESK = 'Help Desk',
  TENANT_ADMIN = 'Tenant Admin',
  THREAT_ANALYST = 'Threat Analyst',

  // Client
  ANALYST = 'Analyst',
  BUSINESS_UNIT_LEADER = 'Business Unit Leader',
  LEADER = 'Leader',
  OBSERVER = 'Observer',
  OWNER = 'Owner',
  REVIEWER = 'Reviewer',

  // Risk Assessment
  RA_FCC_ADMIN = 'RA FCC Admin',
  RA_FCC_ANALYST = 'RA FCC Analyst',
  RA_FCC_VIEWER = 'RA FCC Viewer',
  RA_CLIENT_ADMIN = 'RA Client Admin',
  RA_CLIENT_ANALYST = 'RA Client Analyst',
  RA_CLIENT_VIEWER = 'RA Client Viewer',

  // Risk Register
  RR_FCC_ADMIN = 'RR FCC Admin',
  RR_FCC_ANALYST = 'RR FCC Analyst',
  RR_FCC_VIEWER = 'RR FCC Viewer',
  RR_CLIENT_ADMIN = 'RR Client Admin',
  RR_CLIENT_ANALYST = 'RR Client Analyst',
  RR_CLIENT_VIEWER = 'RR Client Viewer',
}
