export enum NotificationServiceValueEnum {
  VTM = 'vtm',
  SIEM = 'siem',
  MDR = 'mdr',
  IOMT = 'iomt',
  XDR = 'xdr',
  RISK_REGISTER = 'risk_register',
  RISK_ASSESSMENT = 'risk_assessment',
  REPORTS = 'reports',
}

export enum NotificationServiceLabelEnum {
  VTM = 'VTM',
  SIEM = 'SIEM',
  MDR = 'MDR',
  IOMT = 'IoMT',
  XDR = 'XDR',
  RISK_ASSESSMENT = 'Risk Assessment',
  RISK_REGISTER = 'Risk Register',
  REPORTS = 'Reports',
}

export enum AllOptionValueEnum {
  ALL = 'all',
}

export enum AllOptionLabelEnum {
  ALL = 'All',
}
