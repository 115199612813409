import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';

import FccOptionItem from 'src/shared/components/controls/select/fcc-option-item.component';
import { ClientService } from 'src/services/client.service';
import useUpdateEffect from '../../../../hooks/use-update-effect.hook';
import { IOrganization } from '../../../../interfaces/organization.type';
import { ITenantsDataUI, ITenantUI } from '../../../../interfaces/tenant.type';
import { INotificationFilters, INotificationsCount, INotificationService } from '../notifications.type';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { ClientServicesEnum } from '../../../../enums/client-services.enum';
import { TenantServiceStatusEnum } from '../../../../enums/tenant-service-status.enum';
import {
  AllOptionLabelEnum,
  AllOptionValueEnum,
  NotificationServiceLabelEnum,
  NotificationServiceValueEnum,
} from '../utils/notification-services.enum';
import { notificationServiceList } from '../utils/constants/notification-service-list.constant';
import FccSelect from 'src/shared/components/controls/select';

const classes: ICssStyle = {
  reportsOption: {
    borderTop: '1px solid',
    borderTopColor: 'text.tip',
    mt: 2,
  },
};

interface IProps {
  queryParams: INotificationFilters;
  updateQueryParams: (service: string, tenantId: string) => void;
  tenants: ITenantsDataUI;
  notificationsCount: INotificationsCount;
  isTenantsLoading: boolean;
  isCountLoading: boolean;
  selectedClientId: string;
  organization: IOrganization;
}

export default function NotificationsFilter({
  queryParams,
  updateQueryParams,
  tenants,
  notificationsCount,
  isTenantsLoading,
  isCountLoading,
  selectedClientId,
  organization,
}: IProps): React.ReactElement {
  const organizationClientId = organization?.client_id;

  const [totalNotificationsCount, setTotalNotificationsCount] = useState<number>(null);
  const [services, setServices] = useState<INotificationService[]>([]);
  const [tenantsByService, setTenantsByService] = useState<ITenantUI[]>(null);
  const [isOrgOptionPresent, setIsOrgOptionPresent] = useState<boolean>(true);

  const { values, handleChange, errors, touched, handleBlur } = useFormik({
    initialValues: {
      service: AllOptionValueEnum.ALL,
      tenantId: organizationClientId && selectedClientId ? selectedClientId : AllOptionValueEnum.ALL,
    },
    onSubmit: noop,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (tenants) {
      const isServiceSelected = !!services?.find((service) => service.value === queryParams.service);
      const filteredTenants = isServiceSelected ? getFilteredTenantsBySelectedService() : tenants?.data;
      setTenantsByService(filteredTenants);
      const shouldShowOrgOption = isServiceSelected ? checkIsOrganizationHasSelectedService() : true;
      setIsOrgOptionPresent(shouldShowOrgOption);
    }
  }, [queryParams.service, tenants]);

  useEffect(() => {
    const notificationServices =
      queryParams.clientId === AllOptionValueEnum.ALL
        ? getAllAccessibleServices()
        : queryParams.clientId === organizationClientId
        ? getOrganizationAccessibleServices()
        : getTenantAccessibleServices();
    setServices(notificationServices);
  }, [queryParams.clientId]);

  useEffect(() => {
    if (notificationsCount) {
      const total = reduce(Object.values(notificationsCount), (acc, value) => acc + value, 0);
      setTotalNotificationsCount(total);
    }
  }, [notificationsCount]);

  useUpdateEffect(() => {
    updateQueryParams(values.service, values.tenantId);
  }, [values.service, values.tenantId]);

  const getAllAccessibleServices = (): INotificationService[] => {
    const servicesByRole = ClientService.getUserServicesByRole();
    return filterAccessibleServices(servicesByRole);
  };

  const getOrganizationAccessibleServices = (): INotificationService[] => {
    const accessibleServices = ClientService.getClientServicesByUserAccess();
    return filterAccessibleServices(accessibleServices);
  };

  const getTenantAccessibleServices = (): INotificationService[] => {
    const getSelectedTenant = (): ITenantUI =>
      tenants?.data?.find((tenant) => tenant.client_id === queryParams.clientId);
    const tenantServices = tenants && getSelectedTenant()?.services;
    const accessibleServices = ClientService.getClientServicesByUserAccess(tenantServices);
    return filterAccessibleServices(accessibleServices);
  };

  const filterAccessibleServices = (serviceList: ClientServicesEnum[]): INotificationService[] => {
    return notificationServiceList?.filter((service) => serviceList.includes(service.name));
  };

  const getFilteredTenantsBySelectedService = (): ITenantUI[] => {
    const selectedService = services?.find((service) => service.value === queryParams.service);
    return tenants?.data?.filter((tenant) => {
      const liveServices = tenant.services?.filter((service) => service.status === TenantServiceStatusEnum.LIVE);
      return liveServices?.find((liveService) => liveService.name === selectedService?.name);
    });
  };

  const checkIsOrganizationHasSelectedService = (): boolean => {
    const selectedService = services?.find((service) => service.value === queryParams.service);
    const serviceList = ClientService.getClientServicesByUserAccess();
    return !!serviceList?.find((service) => service === selectedService.name);
  };

  const getServiceName = (service: NotificationServiceLabelEnum, count: number): string => {
    return `${service} (${isNumber(count) ? count : ''})`;
  };

  return (
    <Box px={4} mb={4} display="flex">
      <FccSelect
        label="Service"
        name="service"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.service}
        error={Boolean(touched.service && errors.service)}
        formHelperText={touched.service && errors.service}
        formControlStyles={{ width: '40%', mr: 4 }}
        endAdornment={
          isCountLoading && (
            <InputAdornment position="end">
              <CircularProgress size={16} />
            </InputAdornment>
          )
        }
      >
        <FccOptionItem key={AllOptionValueEnum.ALL} value={AllOptionValueEnum.ALL}>
          {AllOptionLabelEnum.ALL} ({totalNotificationsCount})
        </FccOptionItem>
        {services?.map((service: INotificationService) => (
          <FccOptionItem
            key={service.value}
            value={service.value}
            sx={service.value === NotificationServiceValueEnum.REPORTS ? classes.reportsOption : {}}
          >
            {getServiceName(service.label, notificationsCount?.[service.value])}
          </FccOptionItem>
        ))}
        <FccOptionItem
          key={NotificationServiceValueEnum.REPORTS}
          value={NotificationServiceValueEnum.REPORTS}
          sx={classes.reportsOption}
        >
          {NotificationServiceLabelEnum.REPORTS} ({notificationsCount?.reports})
        </FccOptionItem>
      </FccSelect>
      {organizationClientId && (
        <FccSelect
          label="Tenant"
          name="tenantId"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.tenantId}
          error={Boolean(touched.tenantId && errors.tenantId)}
          formHelperText={touched.tenantId && errors.tenantId}
          formControlStyles={{ width: '60%' }}
          endAdornment={
            isTenantsLoading && (
              <InputAdornment position="end">
                <CircularProgress size={16} />
              </InputAdornment>
            )
          }
        >
          <FccOptionItem key={AllOptionValueEnum.ALL} value={AllOptionValueEnum.ALL}>
            {AllOptionLabelEnum.ALL}
          </FccOptionItem>
          {isOrgOptionPresent && (
            <FccOptionItem key={organizationClientId} value={organizationClientId}>
              {organization.name}
            </FccOptionItem>
          )}
          {tenantsByService?.map((tenant: ITenantUI) => (
            <FccOptionItem key={tenant.client_id} value={tenant.client_id}>
              {tenant.name}
            </FccOptionItem>
          ))}
        </FccSelect>
      )}
    </Box>
  );
}
