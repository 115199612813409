import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomAlert from '../../../shared/components/custom-alert.component';
import { SessionService } from '../../../api/session.service';
import { AuthService, incorrectUserNameError } from 'src/services/auth.service';
import useDocumentTitle from 'src/hooks/use-document-title.hook';
import { IFormicActions } from '../../../interfaces/formic.type';
import { AuthorizationTypesEnum } from '../../../enums/authorization-types.enum';
import { UserSignInErrorCodeEnum } from '../../../enums/user-sign-in-error-code.enum';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { authComponentStyles } from '../styles/auth.style';
import FccTextField from '../../../shared/components/controls/text-field';
import FccButton from 'src/shared/components/controls/button';

const PREFIX = 'VerifyUserName';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: { width: '300px' },
});

interface VerifyUserNameState {
  username: string;
  submit: boolean | null;
}

const initialFormState: VerifyUserNameState = {
  username: '',
  submit: null,
};

const validationSchema = Yup.object().shape({
  username: Yup.string().trim().max(255).required('Username is required'),
});

export default function VerifyUserName(): React.ReactElement {
  const navigate = useNavigate();
  useDocumentTitle('Credentials - Login');

  useEffect(() => {
    SessionService.clearSession();
  }, []);

  const submitHandler = async (values: VerifyUserNameState, actions: IFormicActions): Promise<void> => {
    try {
      const config = await AuthService.configure(values.username);
      AuthService.verifiedUserName = values.username;

      if (config.authorization_type === AuthorizationTypesEnum.INTEGRATED || config.sso_allowed_sign_in_with_password) {
        navigate('/login/creds');
      }

      if (config.authorization_type === AuthorizationTypesEnum.SSO && !config.sso_allowed_sign_in_with_password) {
        await AuthService.federatedSignIn();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage =
        error.code === UserSignInErrorCodeEnum.ERR_BAD_REQUEST ? incorrectUserNameError : error.message;
      error && actions.setErrors({ submit: errorMessage });
    }
  };

  return (
    <Root className={classes.root}>
      <Typography component="h6" sx={authComponentStyles.title}>
        Log in to Fortified Central Command
      </Typography>
      <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={submitHandler}>
        {({
          errors,
          handleBlur,
          setValues,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
          dirty,
        }): React.ReactElement => (
          <form noValidate onSubmit={handleSubmit}>
            <FccTextField
              autoFocus
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              label="Username"
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={(event): void => {
                setValues({ ...values, username: event.target.value.trim() });
              }}
              type="text"
              value={values.username}
            />
            {errors.submit && <CustomAlert message={errors.submit} severity={ToastMessageSeveritiesEnum.ERROR} />}
            <Box sx={authComponentStyles.submitButton}>
              <FccButton
                color="primary"
                disabled={!dirty || !isValid}
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Next
              </FccButton>
            </Box>
          </form>
        )}
      </Formik>
    </Root>
  );
}
