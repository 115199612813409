import { AxiosRequestConfig } from 'axios';

import axiosInstance from './axios';

function get<T>(options: AxiosRequestConfig): Promise<T> {
  return axiosInstance.request({ ...options, method: 'GET' });
}

function post<T>(options: AxiosRequestConfig): Promise<T> {
  return axiosInstance.request({ ...options, method: 'POST' });
}

function put<T>(options: AxiosRequestConfig): Promise<T> {
  return axiosInstance.request({ ...options, method: 'PUT' });
}

function patch<T>(options: AxiosRequestConfig): Promise<T> {
  return axiosInstance.request({ ...options, method: 'PATCH' });
}

function remove<T>(options: AxiosRequestConfig): Promise<T> {
  return axiosInstance.request({ ...options, method: 'DELETE' });
}

export default { get, post, put, patch, remove };
