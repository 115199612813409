import { createSelector } from '@reduxjs/toolkit';

import { ITenantServicesSlice } from './utils/tenant-services-slice.type';
import { RootState } from '../../root.reducer';

const getTenantServicesSelector = (state: RootState): ITenantServicesSlice => state.tenantServices;

export const getTenantServicesListSelector = createSelector(
  getTenantServicesSelector,
  (tenantServicesSlice: ITenantServicesSlice) => tenantServicesSlice.data,
);
