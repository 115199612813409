import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IStaticUserSlice } from './utils/static-user-slice.type';
import { apiUrls } from 'src/api/api-urls.constant';
import axios from 'src/api/axios';

const initialState: IStaticUserSlice = {
  data: null,
};

export const fetchStaticUserData = createAsyncThunk('staticUser/data', async () => {
  const response = await axios.get(apiUrls.userStaticData);
  return response.data;
});

export const staticUserSlice = createSlice({
  name: 'staticUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaticUserData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
