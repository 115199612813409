import { AxiosResponse } from 'axios';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { ClientService } from '../../../services/client.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { dateTimeFormat, DateTimeService } from '../../../services/date-time.service';
import apiService from 'src/api/api.service';
import { IDownloadEntityData } from '../../../interfaces/download-entity-data.type';
import { IEntity } from '../../../interfaces/entity.type';
import { IErrorData } from '../../../interfaces/error-data.type';
import { IUploadFormData } from '../../../interfaces/upload-form-data.type';
import { IUser } from '../../../interfaces/user.type';
import { IEscalationFeedbackBE } from './escalation-actions/utils/escalation-feedback.type';
import { IChatEscalation } from './types/chat-escalation.type';
import { IEscalation, IEscalationDetails, IEscalationWithDetails } from './types/escalation.type';
import { IEscalationFilters, IEscalationFiltersBE } from './types/escalation-filters.type';
import { IEscalationStatic } from './types/escalation-static.type';
import { EscalationStatusesEnum } from './utils/escalation-statuses.enum';
import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';
import { downloadFileByUrl } from '../../../utils/download-file.util';
import { getUserFullName } from '../../../utils/get-user-full-name.util';
import { uploadFormData } from '../../../utils/upload-form-data.util';
import { ToastMessages } from '../../../constants/toast-messages.constant';
import { apiUrls } from 'src/api/api-urls.constant';
import { baseURL } from '../../../api/axios';
import { getUserTimezoneParams } from 'src/utils/user-timezone-params';

export class EscalationsService {
  public static getEscalations(
    queryParams: IEscalationFilters,
    signal: AbortSignal,
  ): Promise<{ data: IEscalation[]; total: number }> {
    const url = apiUrls.escalations;
    const params = EscalationsService.mapEscalationQueryParams(queryParams);
    return apiService
      .get<AxiosResponse>({ url, params, signal })
      .then((response) => ({
        data: EscalationsService.mapEscalationsToUI(response?.data?.data),
        total: response?.data?.total,
      }));
  }

  public static getEscalationStatic(signal: AbortSignal): Promise<IEscalationStatic> {
    const url = apiUrls.escalationStatic;
    return apiService
      .get<AxiosResponse<IEscalationStatic>>({ url, signal })
      .then((response) => response?.data);
  }

  private static mapEscalationsToUI(escalations: IEscalation[]): IEscalation[] {
    return map(escalations, (i: IEscalation) => ({
      ...i,
      creation_time: DateTimeService.getLocaleDateTimeFormat(i.creation_time, dateTimeFormat.MM_DD_YYYY_H_MM_A),
    }));
  }

  private static mapEscalationQueryParams(params: IEscalationFilters): IEscalationFiltersBE {
    const assignees = [
      ...map(params.assignedTo, (i) => i.id),
      ...(params.assignedToMe ? [CurrentUserService.user?.id] : []),
    ];

    return {
      acknowledged: params.acknowledged || null,
      assignee_id: assignees,
      client_id: ClientService.getClientId(),
      limit: params.rowsPerPage,
      page: params.page + 1,
      priority: params.priority,
      q: params.search || null,
      reporter_id: params.assignedByMe ? CurrentUserService.user?.id : null,
      service: params.service,
      sort_column: params.orderBy || null,
      sort_type: params.order || null,
      status: params.status,
      unassigned: params.unassigned || null,
      period: params.period || null,
      prioritize_pending: params.prioritizePending,
    };
  }

  public static getEscalationDetails(
    id: number,
    clientId: string,
    signal: AbortSignal,
  ): Promise<IEscalationWithDetails> {
    const url = `${apiUrls.escalations}${id}/`;
    const params = { client_id: clientId };

    return apiService
      .get<{ data: IEscalationWithDetails }>({ url, params, signal })
      .then((response) => ({
        ...response.data,
        creation_time: DateTimeService.getLocaleDateTimeFormat(
          response.data.creation_time,
          dateTimeFormat.MM_DD_YYYY_H_MM_A,
        ),
        acknowledged_at: DateTimeService.getLocaleDateTimeFormat(
          response.data.acknowledged_at,
          dateTimeFormat.MM_DD_YYYY_H_MM_A,
        ),
        remediated_at: DateTimeService.getLocaleDateTimeFormat(
          response.data.remediated_at,
          dateTimeFormat.MM_DD_YYYY_H_MM_A,
        ),
      }));
  }

  public static getChatEscalationData(id: number, clientId: string, signal: AbortSignal): Promise<IChatEscalation> {
    const url = `${apiUrls.escalations}${id}/`;
    const params = { client_id: clientId };

    return apiService
      .get<{ data: IEscalationWithDetails }>({ url, params, signal })
      .then((response) => EscalationsService.mapChatEscalationData(response.data, clientId));
  }

  public static mapChatEscalationData(escalation: IEscalationWithDetails, clientId: string): IChatEscalation {
    const data = pick(escalation, ['name', 'id', 'priority_label', 'status', 'service']);
    const description = escalation.escalation_details?.description;

    return { ...data, description, clientId };
  }

  public static approveEscalationTemplate(escalationId: number, clientId: string): Promise<void> {
    const url = `${apiUrls.escalations}${escalationId}/approve/`;
    const data = {
      client_id: clientId || ClientService.getClientId(),
      ...getUserTimezoneParams(),
    };

    return apiService.patch({ url, data });
  }

  public static updateTemplateAttributes(
    escalationId: number,
    attributes: IEscalationDetails,
    clientId: string,
  ): Promise<void> {
    const url = `${apiUrls.escalations}${escalationId}/edit/`;
    const data = {
      client_id: clientId || ClientService.getClientId(),
      data: attributes,
    };

    return apiService.patch({ url, data });
  }

  public static getEscalationPreviewTemplate(
    escalationId: number,
    attributes: IEscalationDetails,
    signal: AbortSignal,
    clientId: string,
  ): Promise<string> {
    const url = `${apiUrls.escalations}${escalationId}/preview/`;
    const data = {
      client_id: clientId || ClientService.getClientId(),
      data: attributes,
      ...getUserTimezoneParams(),
    };

    return apiService
      .post<AxiosResponse>({ url, data, signal })
      .then((response) => response?.data?.html);
  }

  public static assignUser(
    escalationId: number,
    clientId: string,
    assigneeId: number = null,
    assigneeClientId: string = null,
  ): Promise<AxiosResponse> {
    const client_id = clientId || ClientService.getClientId();
    const url = `${apiUrls.escalations}${
      assigneeId ? `v2/${escalationId}/assign` : `${escalationId}/remove_assignment`
    }/`;
    const data = { ...(assigneeId && { assignee_id: assigneeId, assignee_client_id: assigneeClientId }), client_id };

    return apiService[assigneeId ? 'post' : 'patch']<AxiosResponse>({ url, data });
  }

  public static acknowledgeEscalation(
    escalationId: number,
    client_id = ClientService.getClientId(),
  ): Promise<Partial<IEscalation>> {
    const url = `${apiUrls.escalations}${escalationId}/acknowledge/`;
    const data = { client_id, alert_id: escalationId };

    return apiService.patch({ url, data }).then(() => {
      const user = CurrentUserService?.user;

      return {
        status: EscalationStatusesEnum.IN_PROGRESS,
        acknowledged: true,
        acknowledger: getUserFullName(user),
        acknowledged_at: DateTimeService.getLocaleDateTimeFormat(
          DateTimeService.getCurrentDateTime(),
          dateTimeFormat.MM_DD_YYYY_H_MM_A,
        ),
      };
    });
  }

  public static escalationComplete(id: number, actionData: IUploadFormData): Promise<void> {
    const url = `${apiUrls.escalations}${id}/remediate/`;
    return uploadFormData<void>(actionData, url);
  }

  public static getEscalationCompleteNotes(
    id: number,
    clientId: string,
  ): Promise<{ notes: string; attachments: IEntity[] }> {
    const params = { client_id: clientId || ClientService.getClientId() };
    const url = `${apiUrls.escalations}${id}/notes/`;

    return apiService
      .get<{ data: { notes: string; attachments: IEntity[] } }>({ url, params })
      .then((response) => response?.data);
  }

  public static downloadAttachmentById(fileId: number): void {
    const url = `${baseURL}${
      apiUrls.escalationAttachments
    }${fileId}/?client_id=${ClientService.getClientId()}&type=remediation`;

    apiService
      .get<AxiosResponse>({ url })
      .then((response) => downloadFileByUrl(response?.data));
  }

  public static updateEscalationAfterItWasMarkedAsRemediated(
    escalation: IEscalation | IEscalationWithDetails,
    user: IUser,
  ): IEscalation | IEscalationWithDetails {
    const fullName = getUserFullName(user);
    const currentDate = DateTimeService.getLocaleDateTimeFormat(
      DateTimeService.getCurrentDateTime(),
      dateTimeFormat.MM_DD_YYYY_H_MM_A,
    );

    return {
      ...escalation,
      assignee: escalation?.assignee || fullName,
      assignee_id: escalation?.assignee_id || user.id,
      reporter: escalation?.reporter || fullName,
      status: EscalationStatusesEnum.CLOSED,
      acknowledged: true,
      acknowledger: escalation.acknowledger || fullName,
      acknowledged_at: escalation?.acknowledged_at || currentDate,
      remediator: fullName,
      remediated_at: currentDate,
    };
  }

  public static sendFeedback(data: IEscalationFeedbackBE, id: number): Promise<void> {
    return apiService.post({
      url: `${apiUrls.escalations}${id}/feedback/`,
      data,
    });
  }

  public static generateExcelReport(
    list: IDownloadEntityData[],
    filters: IEscalationFilters,
    service: string,
    signal: AbortSignal,
  ): Promise<string> {
    const url = `${baseURL}${apiUrls.escalationsExcelReport}`;
    const params = isNil(list)
      ? omit(EscalationsService.mapEscalationQueryParams(filters), ['limit', 'page'])
      : {
          escalations: list.reduce((accumulator, escalation) => [...accumulator, escalation.id], []).join(','),
          client_id: ClientService.getClientId(),
        };

    return apiService
      .get<AxiosResponse>({ url, signal, params: { ...params, service, ...getUserTimezoneParams() } })
      .then((response) => response?.data);
  }

  public static getErrorData(
    errorData: IErrorData,
  ): { message: string; severity: ToastMessageSeveritiesEnum; updateEntityOnError: boolean } {
    const alreadyCompleted = errorData?.code === 400 && errorData?.source?.alert_id;
    const error = alreadyCompleted || ToastMessages.generalError;
    const severity = alreadyCompleted ? ToastMessageSeveritiesEnum.WARNING : ToastMessageSeveritiesEnum.ERROR;

    return { message: error, severity, updateEntityOnError: !!alreadyCompleted };
  }

  public static addEscalationToRR(escalationId: number, escalationService: string): Promise<void> {
    const url = `${apiUrls.riskRegisterRisks}/escalations/`;
    const data = {
      client_id: ClientService.getClientId(),
      escalation_id: escalationId,
      service: escalationService,
    };
    return apiService.post({ url, data });
  }
}
