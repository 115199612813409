export enum EventListEnum {
  ASSESSMENT_COMPLETED = 'assessment-completed',
  ATTACHMENT_UPLOADED = 'attachment-uploaded',
  CLIENT_SELECTION = 'client-selection',
  NEW_ASSESSMENT_CREATED = 'new-assessment-created',
  NEW_CHAT = 'new-chat-vent',
  NEW_RISK_REGISTER_ADDED = 'new-risk-register-added',
  RISK_EDITED_ACCEPTED_REMEDIATED = 'risk-edited-accepted-remediated',
  RISK_MARKED_UNMARKED = 'risk-marked-unmarked',
  RISK_REGISTERS_LIST_UPDATED = 'risk-registers-list-updated',
}

export class EventService {
  public static subscribe<T>(event: EventListEnum, callback: (details: CustomEvent<T>) => void): void {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    document.addEventListener(event, callback as any);
  }

  public static emit<T>(event: EventListEnum, data?: Partial<T>): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  public static unsubscribe<T>(event: EventListEnum, callback: (details: CustomEvent<T>) => void): void {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    document.removeEventListener(event, callback as any);
  }
}
