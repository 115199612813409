import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

import { ISelectAllRowsPayload, ISelectRowPayload } from './utils/global-search-payload.type';
import { ISelectedEntity } from './utils/selected-entity.type';
import { SearchResultServiceType } from './utils/search-result-service-type.enum';

export type IGlobalSearchSelectedData = Record<SearchResultServiceType, ISelectedEntity[]>;

const initialState: { data: IGlobalSearchSelectedData } = {
  data: {} as IGlobalSearchSelectedData,
};

const checkEqual = (row: ISelectedEntity, nextRow: ISelectedEntity, type: SearchResultServiceType): boolean => {
  return type === SearchResultServiceType.VTM
    ? row.id == nextRow.id && row.source == nextRow.source
    : type === SearchResultServiceType.MDR
    ? row.id === nextRow.id && isEqual(row.client_ids, nextRow.client_ids)
    : row.id === nextRow.id;
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    selectRow(state, action: PayloadAction<ISelectRowPayload>) {
      const { dataType, isChecked, selectedEntity } = action.payload;
      const selectedRowsByDateType = get(state.data, dataType) || [];
      const updatedData = isChecked
        ? [...selectedRowsByDateType, selectedEntity]
        : selectedRowsByDateType.filter((entity: ISelectedEntity) => !checkEqual(entity, selectedEntity, dataType));

      state.data = {
        ...state.data,
        [dataType]: updatedData,
      };
    },
    handleSelectAll(state, action: PayloadAction<ISelectAllRowsPayload>) {
      const { dataType, isChecked, entities } = action.payload;
      const selectedRowsByType: ISelectedEntity[] = get(state.data, dataType) || [];
      const entityRows: ISelectedEntity[] = entities.map(({ entityId, client_ids, group_type }) => ({
        id: entityId,
        client_ids,
        ...(dataType === SearchResultServiceType.VTM ? { source: group_type } : {}),
      }));

      const allSelected = !entityRows.some(
        (entityRow) => !selectedRowsByType.find((row) => checkEqual(row, entityRow, dataType)),
      );

      const updatedData =
        isChecked && !allSelected
          ? uniqWith([...selectedRowsByType, ...entityRows], (row, nextRow) => checkEqual(row, nextRow, dataType))
          : selectedRowsByType.filter(
              (selectedRow) => !entityRows.find((row) => checkEqual(row, selectedRow, dataType)),
            );

      state.data = {
        ...state.data,
        [dataType]: updatedData,
      };
    },
    clearState(state) {
      state.data = {} as IGlobalSearchSelectedData;
    },
  },
});

const { actions } = globalSearchSlice;
export const selectRow = actions.selectRow;
export const handleSelectAll = actions.handleSelectAll;
export const clearSelectedSearchResultsState = actions.clearState;
