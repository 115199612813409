import { useEffect, useState } from 'react';

import { EmailRedirectService } from './email-redirect.service';
import useAssessmentRedirect from './use-assessment-redirect.hook';
import useChatEmailRedirect from './use-chat-email-redirect.hook';
import useVtmRedirect from './use-vtm-redirect.hook';
import { IEmailRedirectParams, INotification } from '../notifications.type';
import { NotificationTypesEnum } from './notifications-types.enum';
import { buildObjectFromQueryParams } from 'src/utils/query-params-mapper.util';

interface IOutput {
  emailRedirectData: INotification;
  clearEmailRedirectData: () => void;
}

export default function useEmailRedirectListener(): IOutput {
  const [data, setData] = useState(null);
  const { chatRedirect } = useChatEmailRedirect();
  const { vtmRedirect } = useVtmRedirect();
  const { assessmentRedirect } = useAssessmentRedirect();

  const eventTypesHandlerMap = {
    [NotificationTypesEnum.CHAT]: generalEventHandler,
    [NotificationTypesEnum.EMAIL]: generalEventHandler,
    [NotificationTypesEnum.ESCALATION_CHAT]: escalationChatHandler,
    [NotificationTypesEnum.NEW_RISK_ASSESSMENT]: assessmentHandler,
    [NotificationTypesEnum.ASSESSMENT_ASSIGNMENT]: assessmentHandler,
    [NotificationTypesEnum.RA_SURVEY_ASSIGN]: assessmentDetailsTabsHandler,
    [NotificationTypesEnum.RA_SURVEY_COMPLETE]: assessmentDetailsTabsHandler,
    [NotificationTypesEnum.RA_WALKTHROUGH_ASSIGN]: assessmentDetailsTabsHandler,
    [NotificationTypesEnum.RA_WALKTHROUGH_COMPLETE]: assessmentDetailsTabsHandler,
    [NotificationTypesEnum.SCAN_COMPLETED]: scanCompletedHandler,
    [NotificationTypesEnum.ASSESSMENT_COMPLETE]: assessmentHandler,
  };

  useEffect(() => {
    const params = buildObjectFromQueryParams(location.search) as IEmailRedirectParams;
    // TODO: will be possible to remove ignore after all types will be aligned in email and web notifications
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handler = eventTypesHandlerMap[params?.type];

    handler?.(params);
  }, [location]);

  function generalEventHandler(params: IEmailRedirectParams): void {
    const eventData = EmailRedirectService.getRedirectEventData(params);
    setData(eventData);
  }

  function escalationChatHandler(params: IEmailRedirectParams): void {
    const chatParams = { clientId: params.client_id, escalationId: Number(params.escalationId) };
    chatRedirect(chatParams);
  }

  function scanCompletedHandler(params: IEmailRedirectParams): void {
    const { client_id: clientId, source, groupId } = params;
    vtmRedirect(clientId, source, groupId);
  }

  function assessmentHandler(params: IEmailRedirectParams): void {
    const { client_id, id, type, entity_type } = params;
    assessmentRedirect({ clientId: client_id, assessmentId: id, type, clientType: entity_type });
  }

  function assessmentDetailsTabsHandler(params: IEmailRedirectParams): void {
    const { client_id, id, assessment_id, type, entity_type } = params;
    assessmentRedirect({
      clientId: client_id,
      entityId: id,
      assessmentId: assessment_id,
      type,
      clientType: entity_type,
    });
  }

  return { emailRedirectData: data, clearEmailRedirectData: (): void => setData(null) };
}
