import React, { ChangeEvent } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';

import CharacterLimit from './character-limit.component';

type ITrimPosition = 'none' | 'both' | 'start';

export default function FccTextField({
  autoFocus,
  classes,
  className,
  disabled,
  error,
  fullWidth,
  helperText,
  id,
  InputProps,
  inputProps,
  label,
  margin,
  minRows,
  maxRows,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required,
  rows,
  sx,
  spellCheck = true,
  type,
  value,
  variant = 'outlined',
  inputRef,
  trimPosition = 'none',
  characterLimit,
  autoComplete,
  ariaLabel,
}: {
  value: string | number;
  trimPosition?: ITrimPosition;
  characterLimit?: number;
  ariaLabel?: string;
} & TextFieldProps): React.ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (trimPosition === 'both') {
      e.target.value = trim(e.target.value);
    }
    if (trimPosition === 'start') {
      e.target.value = trimStart(e.target.value);
    }
    onChange(e);
  };

  return (
    <TextField
      aria-label={ariaLabel}
      inputRef={inputRef}
      autoFocus={autoFocus}
      classes={classes}
      className={className}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={
        characterLimit && !error ? <CharacterLimit characterLimit={characterLimit} value={value} /> : helperText
      }
      id={id}
      spellCheck={spellCheck}
      InputProps={InputProps}
      inputProps={inputProps}
      label={label}
      margin={margin}
      minRows={minRows}
      maxRows={maxRows}
      multiline={multiline}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      rows={rows}
      sx={sx}
      type={type}
      value={value}
      variant={variant}
      autoComplete={autoComplete}
    />
  );
}
