import React from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import LiveOffIcon from 'src/shared/components/icons/not-live-service-icon.component';
import { ClientService } from 'src/services/client.service';
import { INavBarItem } from '../navbar.type';
import { AppMessages } from 'src/constants/app-messages.constant';

const PREFIX = 'NavItem';

const classes = {
  itemLeaf: `${PREFIX}-itemLeaf`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.itemLeaf}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.buttonLeaf}`]: {
    display: 'flex',
    color: theme.palette.text.primary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'none',
    textDecoration: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
  },

  [`& .${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '20px',
  },

  [`& .${classes.title}`]: {
    marginRight: 'auto',
  },

  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
    backgroundColor: '#CCF2ED',
    fontWeight: theme.typography.fontWeightMedium,
    [`& .${classes.icon}`]: {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: '#CCF2ED',
    },
  },
}));

const NavItem = ({ tab }: { tab: Partial<INavBarItem> }): React.ReactElement => {
  const services = ClientService.getClientServicesByUserAccess();
  const inactive = tab?.isInactive ? tab.isInactive(services) : false;
  const defaultClasses = tab.className ? `${classes.buttonLeaf} ${tab.className}` : classes.buttonLeaf;
  const showTailIcon = tab.checkShowTailIcon?.() ?? false;

  return (
    <StyledListItem className={classes.itemLeaf} style={{ opacity: inactive ? '0.5' : '1' }} disableGutters>
      <NavLink
        to={tab.href}
        className={({ isActive }): string => (isActive ? `${defaultClasses} ${classes.active}` : defaultClasses)}
      >
        {tab.icon && <tab.icon className={classes.icon} />}
        {tab.title}
        {showTailIcon && (
          <Tooltip title={AppMessages.notLiveService}>
            <Box sx={{ ml: 'auto', height: '20px' }}>
              <LiveOffIcon sx={{ color: '#F3BB1C', fontSize: '20px' }} />
            </Box>
          </Tooltip>
        )}
      </NavLink>
    </StyledListItem>
  );
};

export default NavItem;
