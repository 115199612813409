import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MsatIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M6 2H22V16H15.4286V18H22C23.1046 18 24 17.1046 24 16V2C24 0.895431 23.1046 0 22 0H6C4.89543 0 4 0.89543 4 2V6.35352H6V2Z" />
      <path d="M4.5 9.72697C3.52078 9.72697 2.72697 10.5208 2.72697 11.5C2.72697 12.4792 3.52079 13.273 4.5 13.273C5.47922 13.273 6.27303 12.4792 6.27303 11.5C6.27303 10.5208 5.47922 9.72697 4.5 9.72697ZM1 11.5C1 9.567 2.567 8 4.5 8C6.433 8 8 9.567 8 11.5C8 13.433 6.433 15 4.5 15C2.567 15 1 13.433 1 11.5Z" />
      <path d="M13.7657 14.1889C14.0604 14.4565 14.0797 14.9094 13.8089 15.2005C12.0071 17.1373 10.7373 17.7479 9.48301 17.6493C8.98835 17.6104 8.52816 17.4576 8.15009 17.332C8.09679 17.3143 8.04513 17.2972 7.99523 17.281C7.56558 17.1413 7.18089 17.0358 6.72947 17.0358H4.86473C3.84761 17.0358 2.98419 17.3137 2.39109 17.7836C1.8153 18.2397 1.44927 18.9091 1.44927 19.8234V23.284C1.44927 23.6794 1.12484 24 0.724637 24C0.324431 24 0 23.6794 0 23.284V19.8234C0 18.4799 0.561509 17.3975 1.48451 16.6663C2.39021 15.9488 3.59683 15.6038 4.86473 15.6038H6.72947C7.42056 15.6038 7.98575 15.7704 8.44815 15.9207C8.50679 15.9398 8.56292 15.9582 8.61691 15.9759C9.0124 16.1058 9.29272 16.1978 9.5979 16.2218C10.1661 16.2665 11.0267 16.0751 12.7419 14.2315C13.0127 13.9403 13.4711 13.9213 13.7657 14.1889Z" />
      <path d="M14.5912 3L10 5.04056V8.10139C10 9.80705 10.711 11.4461 11.8518 12.6202C12.2918 12.2341 12.8686 12 13.5 12C13.8913 12 14.2616 12.0899 14.5914 12.2502V8.60919H11.0204V5.70139L14.5914 4.11486V8.60408H18.1623C17.9304 10.407 16.8651 12.041 15.369 12.8396C15.5951 13.0938 15.77 13.3946 15.8778 13.7257C17.837 12.6682 19.1825 10.4479 19.1825 8.10139V5.04056L14.5912 3Z" />
    </SvgIcon>
  );
}
