import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import FccSnackbarProvider from './shared/components/fcc-snackbar-provider.component';
import { initializeGtm } from './utils/gtm-data-layer.util';
import reportWebVitals from './reportWebVitals';
import store from './store';
import CustomThemeProvider from './theme/CustomThemeProvider';
import Views from './views';
import './create-nonce';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './__mocks__';
import './index.css';

if (window.location.pathname !== '/report') {
  initializeGtm();
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

root.render(
  <Provider store={store}>
    <CustomThemeProvider>
      <FccSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Views />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} position="bottom" />
        </QueryClientProvider>
      </FccSnackbarProvider>
    </CustomThemeProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
