import { EscalationsService } from 'src/shared/modules/escalations/escalations.service';
import useAbortController from 'src/hooks/use-abort-controller.hook';
import { setChatEscalation } from 'src/store/slices/chat/chat.slice';
import { useDispatch } from 'src/store';

export interface IChatRedirectData {
  escalationId: number;
  clientId: string;
}

interface IProps {
  chatRedirect: (params: IChatRedirectData) => void;
}

export default function useChatEmailRedirect(): IProps {
  const { signal } = useAbortController();
  const dispatch = useDispatch();

  const chatRedirect = (params: IChatRedirectData): void => {
    const { escalationId: id, clientId } = params;
    EscalationsService.getChatEscalationData(id, clientId, signal).then((data) => dispatch(setChatEscalation(data)));
  };

  return { chatRedirect };
}
