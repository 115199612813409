export enum NotificationTypesEnum {
  ALERT_NOTIFICATION = 'alert_notification',
  ASSESSMENT_ASSIGNMENT = 'assessment_assignment',
  ASSESSMENT_DOCUMENT = 'assessment_document',
  ASSESSMENT_COMPLETE = 'assessment_complete',
  CHAT = 'chat',
  DOCUMENT_ACCEPTED = 'document_accepted',
  DOCUMENT_COMPLETE = 'document_complete',
  DOCUMENT_REJECTED = 'document_rejected',
  EMAIL = 'email',
  EMAIL_REDIRECT_DETAILS = 'details',
  EMAIL_REDIRECT_LIST = 'list',
  ESCALATION = 'escalation',
  ESCALATION_ASSIGNMENT = 'escalation_assignment',
  ESCALATION_CHAT = 'escalation-chat',
  ESCALATION_CREATED = 'escalation_created',
  ESCALATION_DETAILS = 'escalation_details',
  ESCALATION_THREAD = 'escalation_thread',
  NEW_RISK_ASSESSMENT = 'new_risk_assessment',
  QUESTION_ASKED = 'question_asked',
  QUESTION_REPLIED = 'question_replied',
  RA_DOC_ASSIGN = 'ra_doc_assign',
  RA_SURVEY_ASSIGN = 'ra_survey_assign',
  RA_SURVEY_COMPLETE = 'ra_survey_complete',
  RA_WALKTHROUGH_ASSIGN = 'ra_walkthrough_assign',
  RA_WALKTHROUGH_COMPLETE = 'ra_walkthrough_complete',
  RISK_ASSIGNMENT = 'risk_assignment',
  SCAN_COMPLETED = 'scan_completed',
  SCHEDULED_PDF_REPORT = 'scheduled_pdf_report',
  THREAD_MESSAGE = 'thread_message',
  VULNERABILITY_ASSIGNMENT = 'vulnerability_assignment',
}
