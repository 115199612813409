import apiService from '../api/api.service';
import { apiUrls } from '../api/api-urls.constant';

export interface IActionData {
  logLevel?: string;
  message: string;
  method?: string;
  operationName: string;
  serverUri?: string;
  successfully?: boolean;
}

interface IAuditLogData {
  operation_name: string;
  successfully: boolean;
  payload: {
    message: string;
  };
  meta: {
    log_level: string;
    server_uri: string;
    method: string;
  };
}

export class TrackUserActionsService {
  public static logUserAction(actionData: IActionData): Promise<void> {
    const data = TrackUserActionsService.buildAuditLogData({ ...actionData, successfully: true });
    return apiService.post<void>({ url: apiUrls.auditLogList, data });
  }

  private static buildAuditLogData(data: IActionData): IAuditLogData {
    return {
      operation_name: data?.operationName,
      successfully: data.successfully,
      payload: {
        message: data?.message,
      },
      meta: {
        log_level: 'INFO',
        server_uri: '',
        method: '',
      },
    };
  }
}
