import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';

export const notificationDetailsTypes = [
  NotificationTypesEnum.DOCUMENT_ACCEPTED,
  NotificationTypesEnum.DOCUMENT_COMPLETE,
  NotificationTypesEnum.DOCUMENT_REJECTED,
  NotificationTypesEnum.RA_DOC_ASSIGN,
  NotificationTypesEnum.ESCALATION,
  NotificationTypesEnum.ESCALATION_ASSIGNMENT,
  NotificationTypesEnum.ESCALATION_CREATED,
  NotificationTypesEnum.ESCALATION_DETAILS,
  NotificationTypesEnum.QUESTION_ASKED,
  NotificationTypesEnum.QUESTION_REPLIED,
  NotificationTypesEnum.RISK_ASSIGNMENT,
  NotificationTypesEnum.SCHEDULED_PDF_REPORT,
  NotificationTypesEnum.THREAD_MESSAGE,
  NotificationTypesEnum.VULNERABILITY_ASSIGNMENT,
];
