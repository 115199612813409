import TagManager from 'react-gtm-module';

export function initializeGtm(): void {
  fetch(`${process.env.PUBLIC_URL}/config/env.json`)
    .then((res) => res.json())
    .then((data) => {
      const gtmId = data.REACT_APP_GA_MEASUREMENT_ID;
      TagManager.initialize({ gtmId });
    });
}

export function gtmSendEvent(data: Record<string, unknown>): void {
  TagManager.dataLayer({
    dataLayer: data,
  });
}
