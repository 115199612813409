/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

type ISelectedRows = Record<string, any[]>;

const initialState: ISelectedRows = {};

export const selectedRowsSlice = createSlice({
  name: 'selectedRows',
  initialState,
  reducers: {
    setSelectedRows(state, action) {
      const tableId = action.payload.tableId;

      return { ...state, [tableId]: action.payload.rows };
    },
    clearSelectedRows(state, action) {
      return omit(state, action.payload);
    },
  },
});

const { actions } = selectedRowsSlice;

export const setSelectedRowsData = actions.setSelectedRows;
export const destroySelectedRows = actions.clearSelectedRows;
