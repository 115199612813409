import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IStaticSlice } from './utils/static-slice.type';
import { apiUrls } from 'src/api/api-urls.constant';
import axios from 'src/api/axios';

const initialState: IStaticSlice = {
  data: null,
};

export const fetchStaticData = createAsyncThunk('static/data', async () => {
  const response = await axios.get(apiUrls.static);
  return response.data;
});

export const staticSlice = createSlice({
  name: 'static',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaticData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
