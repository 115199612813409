import { ICssStyle } from '../../../interfaces/css-style.type';

export const authComponentStyles: ICssStyle = {
  title: {
    fontSize: '18px',
    fontWeight: 'fontWeightBold',
    marginBottom: 8,
  },
  submitButton: {
    marginTop: 8,
  },
};
