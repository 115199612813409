import React, { useEffect, useState } from 'react';

import { lazy } from '@loadable/component';
import IconButton from '@mui/material/IconButton';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import NotificationBellIcon from '../../components/icons/notification-bell-icon.component';
import NotificationsPanel from './components/notifications-panel.component';
import { ClientService } from '../../../services/client.service';
import { NotificationsService } from './notifications.service';
import { EventListEnum } from 'src/services/event.service';
import useEmailRedirectListener from './utils/use-email-redirect-listener.hook';
import useEventService from 'src/hooks/use-event-service.hook';
import useWebSocketService from 'src/hooks/use-web-socket-service.hook';
import { INotification } from './notifications.type';
import { IWSMessageWithPayload } from 'src/interfaces/ws-message.type';
import { QueryKeysEnum } from '../../../enums/query-keys.enum';
import { emailDetailsTypes } from './utils/constants/email-details-types.constant';
import { emailListTypes } from './utils/constants/email-list-types.constant';
import { wsBaseUrl } from 'src/api/axios';

const EntityDetailsModal = lazy(() => import('./entity-details-modal'));
const EntityListModal = lazy(() => import('./entity-list-modal'));

export default function Notifications(): React.ReactElement {
  const queryClient = useQueryClient();
  const selectedClient = ClientService.getSelectedClient();
  const selectedOrganization = ClientService.getSelectedOrganization();
  const isAdminViewMode = ClientService.isAdminViewMode();
  const { emailRedirectData, clearEmailRedirectData } = useEmailRedirectListener();
  const { lastMessage } = useWebSocketService<IWSMessageWithPayload<INotification>>({
    url: `${wsBaseUrl}/notification`,
  });

  const [panelState, setPanelState] = useState<boolean>(false);

  const unreadCountQueryKey = [
    QueryKeysEnum.NOTIFICATIONS_UNREAD_COUNT,
    {
      clientId: selectedClient?.client_id,
      organizationId: selectedOrganization?.client_id,
    },
  ];

  const { data: unreadNotificationsCount } = useQuery({
    queryKey: unreadCountQueryKey,
    queryFn: ({ signal }) =>
      NotificationsService.getUnreadNotificationsCount(
        {
          tenantId: selectedClient?.client_id,
          isAdminViewMode,
        },
        selectedOrganization?.active_tenants,
        signal,
      ),
  });

  useEventService([EventListEnum.NEW_CHAT], (): void => setPanelState(false));

  useEffect(() => {
    if (lastMessage) {
      handleNewNotification();
    }
  }, [lastMessage]);

  const handleNewNotification = (): void => {
    queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.NOTIFICATIONS_UNREAD_COUNT] });
  };

  const handleReadNotification = (): void => {
    const unreadCount = queryClient.getQueryData<number>(unreadCountQueryKey);
    queryClient.setQueriesData({ queryKey: unreadCountQueryKey }, unreadCount - 1);
    queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.NOTIFICATIONS_UNREAD_COUNT] });
  };

  return (
    <>
      <IconButton color="inherit" onClick={(): void => setPanelState(true)} size="large">
        <NotificationBellIcon isBadgeShown={!!unreadNotificationsCount} />
      </IconButton>
      {panelState && (
        <NotificationsPanel
          panelState={panelState}
          setPanelState={setPanelState}
          newNotification={lastMessage}
          readNotification={handleReadNotification}
        />
      )}
      {emailListTypes.includes(emailRedirectData?.type) && (
        <EntityListModal notification={emailRedirectData} onCloseDialog={clearEmailRedirectData} />
      )}
      {emailDetailsTypes.includes(emailRedirectData?.type) && (
        <EntityDetailsModal notification={emailRedirectData} onCloseDialog={clearEmailRedirectData} />
      )}
    </>
  );
}
