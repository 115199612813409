import {
  AllOptionValueEnum,
  NotificationServiceLabelEnum,
  NotificationServiceValueEnum,
} from '../notification-services.enum';
import { ClientServicesEnum } from 'src/enums/client-services.enum';

export const notificationServiceList = [
  { label: NotificationServiceLabelEnum.SIEM, value: NotificationServiceValueEnum.SIEM, name: ClientServicesEnum.SIEM },
  { label: NotificationServiceLabelEnum.VTM, value: NotificationServiceValueEnum.VTM, name: ClientServicesEnum.VTM },
  { label: NotificationServiceLabelEnum.IOMT, value: NotificationServiceValueEnum.IOMT, name: ClientServicesEnum.IOMT },
  { label: NotificationServiceLabelEnum.MDR, value: NotificationServiceValueEnum.MDR, name: ClientServicesEnum.MDR },
  { label: NotificationServiceLabelEnum.XDR, value: NotificationServiceValueEnum.XDR, name: ClientServicesEnum.XDR },
  {
    label: NotificationServiceLabelEnum.RISK_ASSESSMENT,
    value: NotificationServiceValueEnum.RISK_ASSESSMENT,
    name: ClientServicesEnum.RISK_ASSESSMENT,
  },
  {
    label: NotificationServiceLabelEnum.RISK_REGISTER,
    value: NotificationServiceValueEnum.RISK_REGISTER,
    name: ClientServicesEnum.RISK_REGISTER,
  },
];

export const allServicesOption = { label: AllOptionValueEnum.ALL, value: AllOptionValueEnum.ALL };
