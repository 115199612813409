/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { combineReducers, PayloadAction } from '@reduxjs/toolkit';

import { chatSlice } from './slices/chat/chat.slice';
import { dataStateSlice } from './slices/data-state/data-state.slice';
import { globalSearchSlice } from './slices/global-search/global-search.slice';
import { reportSlice } from './slices/report/report.slice';
import { selectedRowsSlice } from './slices/selected-rows/selected-rows.slice';
import { staticSlice } from './slices/static/static.slice';
import { staticAdvisoryServicesSlice } from './slices/static-advisory-services/static-advisory-services.slice';
import { staticUserSlice } from './slices/static-user/static-user.slice';
import { tenantManagementSlice } from './slices/tenant-management/tenant-management.slice';
import { tenantServicesSlice } from './slices/tenant-services/tenant-services.slice';
import { userSlice } from './slices/user/user.slice';
import { userPreferencesSlice } from './slices/user-preferences/user-preferences.slice';

const combinedReducer = combineReducers({
  chat: chatSlice.reducer,
  globalSearch: globalSearchSlice.reducer,
  report: reportSlice.reducer,
  static: staticSlice.reducer,
  staticAdvisoryServices: staticAdvisoryServicesSlice.reducer,
  staticUser: staticUserSlice.reducer,
  tenantServices: tenantServicesSlice.reducer,
  user: userSlice.reducer,
  userPreferences: userPreferencesSlice.reducer,
  dataState: dataStateSlice.reducer,
  tenantManagement: tenantManagementSlice.reducer,
  selectedRows: selectedRowsSlice.reducer,
});

const rootReducer = (state: ReturnType<any>, action: PayloadAction<any>) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
