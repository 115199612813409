export const ToastMessages = {
  addAllRisksToRiskRegister: 'Risks have been added to Risk Register successfully',
  changeUserPasswordSuccess: 'Password has been updated successfully.',
  chatHistorySentError: 'An Error occurred. Chat History has not been sent. Please try again.',
  chatHistorySentSuccess: 'Chat History has been sent to the email successfully.',
  createUserSuccess: 'User has been created successfully.',
  deactivatedUser: 'User has been deactivated',
  escalationAcknowledgeSuccess: 'Escalation has been acknowledged successfully.',
  escalationIsAlreadyAddedInRiskRegister: 'This escalation has been already added as Risk to Risk Register.',
  escalationMarkAsRemediatedSuccess: 'Escalation has been marked as remediated successfully.',
  escalationTemplateApprovedSuccess: 'Escalation email template has been approved successfully.',
  generalError: 'Error occurs during the request.',
  onsitePhysicalWalkthroughCreationSuccess: 'Onsite Physical Walkthrough has been created successfully.',
  reactivatedUserSuccess: 'User has been reactivated successfully.',
  removedUser: 'User has been deleted',
  reportHasBeenCreatedSuccess: 'Report has been created successfully.',
  reportIncidentSuccess:
    'You IR has been sent to Security Incident Response Team successfully. SOC team will connect with you soon.',
  reportIncidentTesting: 'Your case ID has not been created. This is only for testing functionality.',
  reportIsCurrentlyProcessing: 'Report is currently processing.',
  resetUserPasswordSuccess: 'Password has been reset successfully.',
  resetUsersPasswordsSuccess: 'Passwords have been reset successfully.',
  sendEmailSuccess: 'Email has been sent successfully.',
  sendPostAlertSuccess: 'Notification has been sent successfully.',
  supportRequestHasBeenCreatedSuccess: 'Your service request has been created successfully.',
  updateTenantStatusSuccess: 'Tenant status has been updated successfully.',
  updateUserSuccess: 'User has been updated successfully.',
  vtmIsAlreadyAddedInRiskRegister: 'Vulnerability has been already added to Risk Register',
  vulnerabilityMarkAsRemediatedSuccess: 'Vulnerability has been marked as remediated successfully.',
};
