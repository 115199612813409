import React from 'react';

import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import isString from 'lodash/isString';

import FccButton from '../../controls/button';
import { ICustomDialogData } from '../index';

interface IProps {
  confirm: () => void;
  confirmButtonColor?: ButtonProps['color'];
  data: ICustomDialogData;
  isConfirmDisabled?: boolean;
  isActionSubmitting?: boolean;
  isRejectDisabled?: boolean;
  reject: () => void;
  rejectButtonColor?: ButtonProps['color'];
  rejectButtonVariant?: ButtonProps['variant'];
}

export default function CustomDialogFooter({
  confirm,
  data,
  reject,
  confirmButtonColor = 'primary',
  isActionSubmitting = false,
  isConfirmDisabled = false,
  isRejectDisabled = false,
  rejectButtonColor = 'info',
  rejectButtonVariant = 'contained',
}: IProps): React.ReactElement {
  const confirmStartIcon = isActionSubmitting ? <CircularProgress size="1em" sx={{ color: 'inherit' }} /> : null;

  return (
    <Box pt={4} pb={2} pr={4} display="flex" justifyContent="flex-end">
      {data.closeButtonTitle && (
        <FccButton
          disabled={isRejectDisabled}
          onClick={reject}
          variant={rejectButtonVariant}
          color={rejectButtonColor}
          ariaLabel={isString(data.closeButtonTitle) ? data.closeButtonTitle : 'close'}
        >
          {data.closeButtonTitle}
        </FccButton>
      )}
      <Tooltip title={data?.confirmButtonTooltip || ''}>
        <Box ml={4}>
          <FccButton
            color={confirmButtonColor}
            disabled={isConfirmDisabled}
            loading={isActionSubmitting}
            onClick={confirm}
            type="submit"
            ariaLabel={isString(data.confirmButtonTitle) ? data.confirmButtonTitle : 'submit'}
            variant="contained"
            sx={{ height: '100%' }}
            startIcon={confirmStartIcon}
          >
            {data.confirmButtonTitle}
          </FccButton>
        </Box>
      </Tooltip>
    </Box>
  );
}
