import { ClientServicesEnum } from '../enums/client-services.enum';
import { UserRolesEnum } from '../enums/user-roles.enum';
import { generalRoles, riskAssessmentRoles, riskRegisterRoles } from './user-roles.constant';

export const servicesByRole = [
  {
    service: ClientServicesEnum.VTM,
    checkAccess: (role: UserRolesEnum): boolean => generalRoles.includes(role),
  },
  {
    service: ClientServicesEnum.SIEM,
    checkAccess: (role: UserRolesEnum): boolean => generalRoles.includes(role),
  },
  {
    service: ClientServicesEnum.IOMT,
    checkAccess: (role: UserRolesEnum): boolean => generalRoles.includes(role),
  },
  {
    service: ClientServicesEnum.MDR,
    checkAccess: (role: UserRolesEnum): boolean => generalRoles.includes(role),
  },
  {
    service: ClientServicesEnum.XDR,
    checkAccess: (role: UserRolesEnum): boolean => generalRoles.includes(role),
  },
  {
    service: ClientServicesEnum.RISK_REGISTER,
    checkAccess: (role: UserRolesEnum): boolean => [UserRolesEnum.GLOBAL_ADMIN, ...riskRegisterRoles].includes(role),
  },
  {
    service: ClientServicesEnum.RISK_ASSESSMENT,
    checkAccess: (role: UserRolesEnum): boolean => [UserRolesEnum.GLOBAL_ADMIN, ...riskAssessmentRoles].includes(role),
  },
];
