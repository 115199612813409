import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import { ToastMessageSeveritiesEnum } from 'src/enums/toast-message-severities.enum';

const PREFIX = 'CustomAlert';

const classes = {
  alert: `${PREFIX}-alert`,
};

const StyledAlert = styled(Alert)(() => ({
  [`& .${classes.alert}`]: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '220px',

    '&.MuiAlert-standardSuccess': {
      backgroundColor: '#4CBB78',
    },

    '&.MuiAlert-standardError': {
      backgroundColor: '#FDECEA',
      color: '#B00020',

      '& .MuiAlert-icon': {
        color: '#B00020',
      },
    },

    '&.MuiAlert-standardWarning': {
      backgroundColor: '#f9dd8d',
      color: '#000',

      '& .MuiAlert-icon': {
        color: '#000',
      },
    },
  },
}));

interface IProps {
  message: string;
  severity: ToastMessageSeveritiesEnum;
}

export default function CustomAlert({ severity, message }: IProps): React.ReactElement {
  return (
    <StyledAlert
      iconMapping={{ success: <CheckCircleOutlineIcon />, warning: <InfoOutlinedIcon />, error: <ErrorOutlineIcon /> }}
      className={classes.alert}
      severity={severity}
    >
      {message}
    </StyledAlert>
  );
}
