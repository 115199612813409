import { ClientService } from '../services/client.service';
import { CurrentUserService } from '../services/current-user.service';

export function comparativeAnalyticsGuard(): boolean {
  return (
    CurrentUserService.permissions.VIEW_ANALYTIC_SERVICE_LINE_SCORE &&
    !CurrentUserService.isRiskRegisterUser() &&
    ClientService.isClientViewMode()
  );
}
