import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TenantServicesSliceService } from './services/tenant-services-slice.service';
import { ITenantServicesSlice } from './utils/tenant-services-slice.type';
import { IUser } from 'src/interfaces/user.type';

const initialState: ITenantServicesSlice = {
  data: null,
};

export const fetchTenantServicesData = createAsyncThunk('tenant/services', (user: IUser) =>
  TenantServicesSliceService.getServiceList(user),
);

export const tenantServicesSlice = createSlice({
  name: 'tenantServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTenantServicesData.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});
