import { ClientService } from '../services/client.service';
import { AuthService } from 'src/services/auth.service';
import { AuthMethodEnum } from '../enums/gtm/auth-method.enum';

export class SessionService {
  public static clearSession(): void {
    localStorage.clear();
    ClientService.clearSelectedClient();
  }

  public static redirectToLoginPage(): void {
    AuthService.cognitoUser = null;
    SessionService.clearSession();
    window.open('/login', '_self');
  }

  public static preserveRoute(): void {
    const ignoredRoutes = ['/login', '/callback', '/sso-login'];
    const isIgnoredRoute = ignoredRoutes.some((route) => location.pathname.startsWith(route));
    if (!isIgnoredRoute) {
      sessionStorage.setItem('redirectUrl', `${location.pathname}${location.search}`);
    }
  }

  public static setReportAccessToken(token: string): void {
    sessionStorage.setItem('reportToken', token);
  }

  public static get reportAccessToken(): string {
    return sessionStorage.getItem('reportToken');
  }

  public static setAuthMethod(authMethod: AuthMethodEnum): void {
    sessionStorage.setItem('authMethod', authMethod);
  }

  public static getUserRedirectUrl(): string {
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    sessionStorage.removeItem('redirectUrl');

    return redirectUrl || '/';
  }
}
