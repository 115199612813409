import { AuditLogOperationNameTypesEnum } from '../enums/audit-log-operation-name-types.enum';

export const AuditLogData = {
  VTM_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Dashboard page',
  },
  VTM_VULNERABILITIES: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Vulnerabilities page',
  },
  VTM_ACCEPTED_VULNERABILITIES: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Accepted Vulnerabilities tab',
  },
  VTM_HOSTS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Hosts page',
  },
  VTM_REPORTS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Reports page',
  },
  VTM_GROUPED_SCANS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Grouped Scans page',
  },
  VTM_UNGROUPED_SCANS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited VTM Ungrouped Scans page',
  },
  SIEM_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited SIEM Dashboard page',
  },
  SIEM_ALARMS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited SIEM ALARMS page',
  },
  IOMT: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited IOMT page',
  },
  IOMT_REAL_TIME_ALARMS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited IoMT real time alarms page',
  },
  IOMT_HISTORICAL_ALARMS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited IoMT historical alarms page',
  },
  MDR_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited MDR Dashboard page',
  },
  MDR_ALARMS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited MDR alarms page',
  },
  ORGANIZATION_PROFILE: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Organization Profile page',
  },
  USER_MANAGEMENT: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited User Management page',
  },
  TENANT_MANAGEMENT: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Tenant Management page',
  },
  COMPARATIVE_ANALYTICS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Comparative Analytics page',
  },
  UNIFIED_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Unified Dashboard page',
  },
  CLIENTS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Clients page',
  },
  CLIENT_SELECTED: {
    operationName: AuditLogOperationNameTypesEnum.CLIENT_SELECTED,
    message: 'User selected a Client',
  },
  AUDIT_LOG: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Audit Log page',
  },
  LOG_IN: {
    operationName: AuditLogOperationNameTypesEnum.LOG_IN,
    message: 'User logged in the application',
  },
  LOG_OUT: {
    operationName: AuditLogOperationNameTypesEnum.LOG_OUT,
    message: 'User logged out of the application',
  },
  CHANGED_PASSWORD: {
    operationName: AuditLogOperationNameTypesEnum.CHANGED_PASSWORD,
    message: 'User changed password',
  },
  CHAT_STARTED: {
    operationName: AuditLogOperationNameTypesEnum.CHAT_STARTED,
    message: 'User started chat for',
  },
  CHAT_ENDED: {
    operationName: AuditLogOperationNameTypesEnum.CHAT_ENDED,
    message: 'Chat ended',
  },
  CHAT_ANALYST_JOINED: {
    operationName: AuditLogOperationNameTypesEnum.CHAT_ANALYST_JOINED,
    message: 'Analyst joined chat',
  },
  ESCALATIONS_OPEN_PAGE: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Escalations page',
  },
  ESCALATIONS_SIEM: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited SIEM escalations page',
  },
  ESCALATIONS_MDR: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited MDR escalations page',
  },
  ESCALATIONS_IOMT: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited IoMT escalations page',
  },
  ESCALATIONS_XDR: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited XDR escalations page',
  },
  ESCALATION_DETAILS_TAB_OPEN: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User opened Escalations details tab',
  },
  ESCALATION_THREAD_TAB_OPEN: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User opened Escalations thread tab',
  },
  RISK_ASSESSMENT_CAP: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited CAP tab',
  },
  RISK_ASSESSMENT_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Risk Assessment Dashboard page',
  },
  RISK_ASSESSMENT_DOCUMENT_REQUEST: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Document request tab',
  },
  RISK_ASSESSMENT_TECHNICAL_CONTROL_SURVEY: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Technical control survey tab',
  },
  RISK_ASSESSMENT_WORKSHEET: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Assessment worksheet tab',
  },
  RISK_ASSESSMENT_WALKTHROUGH_SURVEY: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User opened Assessment Walkthrough details',
  },
  RISK_REGISTER_DASHBOARD: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Risk Register Dashboard page',
  },
  RISK_REGISTER_LIST: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Risk Register page',
  },
  REPORTS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Reports page',
  },
  REPORTS_SCHEDULER: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited Reports scheduler page',
  },
  REPORT_DOWNLOAD: {
    operationName: AuditLogOperationNameTypesEnum.DOWNLOAD_REPORT,
    message: 'User downloaded report to pdf',
  },
  REPORT_PREVIEW: {
    operationName: AuditLogOperationNameTypesEnum.PREVIEW_REPORT,
    message: 'User previewed report',
  },
  TECHNICAL_CONTROL_SURVEY_UPDATE: {
    operationName: AuditLogOperationNameTypesEnum.TECHNICAL_CONTROL_SURVEY_UPDATE,
    message: 'User updated technical control survey',
  },
  XDR_ALARMS: {
    operationName: AuditLogOperationNameTypesEnum.PAGE_VISIT,
    message: 'User visited XDR ALARMS page',
  },
};
