import React from 'react';

import Box from '@mui/material/Box';

interface IProps {
  characterLimit: number;
  value: string | number;
}

export default function CharacterLimit({ characterLimit, value }: IProps): React.ReactElement {
  return (
    <Box sx={{ display: 'block', textAlign: 'center' }} component="span">
      {characterLimit - String(value).length} Characters Remaining
    </Box>
  );
}
