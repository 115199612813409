import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import loadable, { lazy } from '@loadable/component';

import { AppGuardComponent } from './guards/app.guard.component';
import { Loader } from './shared/components/loader.component';
import ProtectedRoute from './shared/components/protected-route.component';
import Login from './views/auth/components/login.component';
import VerifyUserName from './views/auth/components/verify-username.component';
import { ClientServicesEnum } from './enums/client-services.enum';
import { MenuOptionsEnum } from './enums/menu-options.enum';
import { auditLogGuard } from './guards/audit-log.guard';
import { chatHistoryGuard } from './guards/chat-history.guard';
import { clientDashboardGuard } from './guards/client-dashboard.guard';
import { clientsGuard } from './guards/clients.guard';
import { comparativeAnalyticsGuard } from './guards/comparative-analytics.guard';
import { credentialsGuard } from './guards/credentials.guard';
import { emailVerificationGuard } from './guards/email-verification.guard';
import { escalationsGuard } from './guards/escalations.guard';
import { globalSearchGuard } from './guards/global-search.guard';
import { marketingServicesGuard } from './guards/marketing-services.guard';
import { newPasswordGuard } from './guards/new-password.guard';
import { organizationGuard } from './guards/organization.guard';
import { organizationProfileGuard } from './guards/organization-profile.guard';
import { reportsGuard } from './guards/reports.guard';
import { riskAssessmentGuard } from './guards/risk-assessment.guard';
import { riskRegisterGuard } from './guards/risk-register.guard';
import { selectedOrganizationGuard } from './guards/selected-organization.guard';
import { settingsGuard } from './guards/settings.guard';
import { tenantManagementGuard } from './guards/tenant-management.guard';
import { tenantOrganizationProfileGuard } from './guards/tenant-organization-profile.guard';
import { unifiedDashboardGuard } from './guards/unified-dashboard.guard';
import { userManagementGuard } from './guards/user-management.guard';
import { verificationCodeGuard } from './guards/verification-code.guard';
import AuthView from './views/auth';

const ClientServiceGuard = lazy(() => import('./guards/client-service.guard'));

interface IAsyncComponentProps {
  path: string;
}

interface IGuardedRoute {
  path: string;
  guards: (() => boolean)[];
}

const AsyncComponent = loadable((props: IAsyncComponentProps) => import(`${props.path}`), {
  fallback: <Loader isGlobal={true} />,
  cacheKey: (props) => props.path,
});

const getOrgAndTenantRoutes = (path: string): string[] => {
  return [
    path,
    `tenants/:tenantId/${path}`,
    `organizations/:organizationId/${path}`,
    `organizations/:organizationId/tenants/:tenantId/${path}`,
  ];
};

const getCommonRoutesWithGuards = (path: string): IGuardedRoute[] => {
  return [
    { path, guards: [] },
    { path: `tenants/:tenantId/${path}`, guards: [clientDashboardGuard] },
    { path: `organizations/:organizationId/${path}`, guards: [selectedOrganizationGuard] },
    {
      path: `organizations/:organizationId/tenants/:tenantId/${path}`,
      guards: [selectedOrganizationGuard, clientDashboardGuard],
    },
  ];
};

const getTenantRoutes = (path: string): string[] => {
  return [path, `tenants/:tenantId/${path}`, `organizations/:organizationId/tenants/:tenantId/${path}`];
};

const getTenantRoutesWithGuards = (path: string): IGuardedRoute[] => {
  return [
    { path, guards: [] },
    { path: `tenants/:tenantId/${path}`, guards: [clientDashboardGuard] },
    {
      path: `organizations/:organizationId/tenants/:tenantId/${path}`,
      guards: [selectedOrganizationGuard, clientDashboardGuard],
    },
  ];
};

const getOrgRoutes = (path: string): string[] => {
  return [path, `organizations/:organizationId/${path}`];
};

const getClientRoutes = (path: string): string[] => {
  return [path, `tenants/:tenantId/${path}`];
};

export default function Views(): React.ReactElement {
  return (
    <Routes>
      <Route path="login" element={<AuthView />}>
        <Route index element={<VerifyUserName />} />
        <Route
          path="creds"
          element={
            <ProtectedRoute guards={[credentialsGuard]} redirectUrl="/login">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="verification"
          element={
            <ProtectedRoute guards={[verificationCodeGuard]} redirectUrl="/login">
              <AsyncComponent path="./views/auth/components/verification-code.component" />
            </ProtectedRoute>
          }
        />
        <Route
          path="email-verification"
          element={
            <ProtectedRoute guards={[emailVerificationGuard]} redirectUrl="/login">
              <AsyncComponent path="./views/auth/components/email-verification.component" />
            </ProtectedRoute>
          }
        />
        <Route
          path="new-password"
          element={
            <ProtectedRoute guards={[newPasswordGuard]} redirectUrl="/login">
              <AsyncComponent path="./views/auth/components/new-password.component" />
            </ProtectedRoute>
          }
        />
        <Route
          path="reset-password"
          element={
            <ProtectedRoute guards={[credentialsGuard]} redirectUrl="/login">
              <AsyncComponent path="./views/auth/components/reset-password.component" />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<AppGuardComponent />}>
        {/*
          ************
                Common Routes
          ************
          */}
        {getCommonRoutesWithGuards(MenuOptionsEnum.AUDIT_LOG).map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[auditLogGuard, ...guards]} redirectUrl="/">
                <AsyncComponent path="./views/audit-log" />
              </ProtectedRoute>
            }
          />
        ))}
        {getCommonRoutesWithGuards(MenuOptionsEnum.SETTINGS).map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[settingsGuard, ...guards]} redirectUrl="/">
                <AsyncComponent path="./views/settings" />
              </ProtectedRoute>
            }
          />
        ))}
        {getCommonRoutesWithGuards(MenuOptionsEnum.USER_MANAGEMENT).map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[userManagementGuard, ...guards]} redirectUrl="/">
                <AsyncComponent path="./views/user-management" />
              </ProtectedRoute>
            }
          />
        ))}
        {getCommonRoutesWithGuards(MenuOptionsEnum.RISK_ASSESSMENT).map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[riskAssessmentGuard, ...guards]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/risk-assessment" />}
                    deny={<AsyncComponent path="./views/risk-assessment/marketing" />}
                    service={ClientServicesEnum?.RISK_ASSESSMENT}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          >
            <Route index element={<AsyncComponent path="./views/risk-assessment/dashboard" />} />
            <Route path="assessments" element={<AsyncComponent path="./views/risk-assessment/assessment-list" />} />
            <Route path="cap" element={<AsyncComponent path="./views/risk-assessment/cap" />} />
            <Route
              path="assessments/:assessmentId"
              element={<AsyncComponent path="./views/risk-assessment/assessment-details" />}
            >
              <Route index element={<Navigate to="document-request" />} />
              <Route
                path="document-request"
                element={<AsyncComponent path="./views/risk-assessment/assessment-details/document-request" />}
              />
              <Route
                path="technical-controls-survey"
                element={
                  <AsyncComponent path="./views/risk-assessment/assessment-details/technical-controls-survey/survey-list" />
                }
              />
              <Route
                path="technical-controls-survey/:surveyId"
                element={
                  <AsyncComponent path="./views/risk-assessment/assessment-details/technical-controls-survey/survey-details" />
                }
              />
              <Route
                path="onsite-physical-walkthrough"
                element={
                  <AsyncComponent path="./views/risk-assessment/assessment-details/walkthrough/walkthrough-list" />
                }
              />
              <Route
                path="onsite-physical-walkthrough/:surveyId"
                element={
                  <AsyncComponent path="./views/risk-assessment/assessment-details/walkthrough/walkthrough-details" />
                }
              />
              <Route
                path="assessment-worksheet"
                element={<AsyncComponent path="./views/risk-assessment/assessment-details/assessment-worksheet" />}
              />
            </Route>
          </Route>
        ))}
        {getCommonRoutesWithGuards(MenuOptionsEnum.RISK_REGISTER).map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[riskRegisterGuard, ...guards]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/risk-register" />}
                    deny={<AsyncComponent path="./views/risk-register/marketing" />}
                    service={ClientServicesEnum?.RISK_REGISTER}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
        {getClientRoutes('chat-history').map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[chatHistoryGuard]} redirectUrl="/">
                <AsyncComponent path="./views/chat-history" />
              </ProtectedRoute>
            }
          />
        ))}
        {getOrgAndTenantRoutes('user-profile').map((path) => (
          <Route key={path} path={path} element={<AsyncComponent path="./views/user-profile" />} />
        ))}
        {getOrgAndTenantRoutes('communication-preferences').map((path) => (
          <Route key={path} path={path} element={<AsyncComponent path="./views/communication-preferences" />} />
        ))}
        {getTenantRoutesWithGuards('global-search').map(({ path, guards }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[globalSearchGuard, ...guards]} redirectUrl="/">
                <AsyncComponent path={'./views/global-search'} />
              </ProtectedRoute>
            }
          />
        ))}

        {/*
          ************
                Admin Routes
          ************
          */}
        <Route
          path="clients"
          element={
            <ProtectedRoute guards={[clientsGuard]} redirectUrl="/">
              <AsyncComponent path="./views/clients" />
            </ProtectedRoute>
          }
        >
          <Route index element={<AsyncComponent path="./views/clients/components/tenant-list.component" />} />
          <Route
            path="organizations"
            element={<AsyncComponent path="./views/clients/components/organization-list.component" />}
          />
          <Route path="tenants" element={<AsyncComponent path="./views/clients/components/tenant-list.component" />} />
        </Route>
        <Route
          path="tenant-management"
          element={
            <ProtectedRoute guards={[tenantManagementGuard]} redirectUrl="/">
              <AsyncComponent path="./views/tenant-management" />
            </ProtectedRoute>
          }
        >
          <Route index element={<AsyncComponent path="./views/tenant-management/tenants" />} />
          <Route path="tenants" element={<AsyncComponent path="./views/tenant-management/tenants" />} />
          <Route
            path="tenants/:tenantClientId"
            element={<AsyncComponent path="./views/tenant-management/tenants/tenant-profile" />}
          />
          <Route path="organizations" element={<AsyncComponent path="./views/tenant-management/organizations" />} />
          <Route
            path="organizations/:organizationClientId"
            element={
              <AsyncComponent path="./views/tenant-management/organizations/components/organization-profile.component" />
            }
          />
          <Route
            path="organizations/:organizationClientId/:tenantClientId"
            element={<AsyncComponent path="./views/tenant-management/tenants/tenant-profile" />}
          />
        </Route>

        {/*
          ************
                Org Routes
          ************
          */}
        {getOrgRoutes(MenuOptionsEnum.ORG_TENANTS).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[organizationGuard]} redirectUrl="/">
                <AsyncComponent path="./views/organization-tenants" />
              </ProtectedRoute>
            }
          />
        ))}
        {getOrgRoutes(MenuOptionsEnum.ORGANIZATION_PROFILE).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[organizationProfileGuard, organizationGuard]} redirectUrl="/">
                <AsyncComponent path="./views/organization-profile" />
              </ProtectedRoute>
            }
          />
        ))}

        {/*
          ************
                Tenant Routes
          ************
          */}
        {getTenantRoutes(MenuOptionsEnum.COMPARATIVE_ANALYTICS).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[comparativeAnalyticsGuard]} redirectUrl="/">
                <AsyncComponent path="./views/comparative-analytics" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.ESCALATIONS).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[escalationsGuard]} redirectUrl="/">
                <AsyncComponent path="./views/escalations" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.IOMT).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[clientDashboardGuard]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/iomt" />}
                    deny={<AsyncComponent path="./views/iomt/marketing" />}
                    service={ClientServicesEnum?.IOMT}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.MDR).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[clientDashboardGuard]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/mdr" />}
                    deny={<AsyncComponent path="./views/mdr/marketing" />}
                    service={ClientServicesEnum?.MDR}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.TENANT_PROFILE).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[tenantOrganizationProfileGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/tenant-organization-profile" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.REPORTS).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[reportsGuard]} redirectUrl="/">
                <AsyncComponent path="./views/reports" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.SIEM).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[clientDashboardGuard]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/siem" />}
                    deny={<AsyncComponent path="./views/siem/marketing" />}
                    service={ClientServicesEnum.SIEM}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.UNIFIED_DASHBOARD).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[unifiedDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/unified-dashboard" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.VTM).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[clientDashboardGuard]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/vtm" />}
                    deny={<AsyncComponent path="./views/vtm/marketing" />}
                    service={ClientServicesEnum?.VTM}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          >
            <Route index element={<AsyncComponent path="./views/vtm/dashboard" />} />
            <Route path="vulnerabilities" element={<AsyncComponent path="./views/vtm/vulnerabilities" />} />
            <Route
              path="accepted-vulnerabilities"
              element={<AsyncComponent path="./views/vtm/accepted-vulnerabilities" />}
            />
            <Route path="hosts" element={<AsyncComponent path="./views/vtm/hosts" />} />
            <Route path="reports" element={<AsyncComponent path="./views/vtm/reports" />} />
            <Route path="grouped-scans" element={<AsyncComponent path="./views/vtm/grouped-scans" />} />
            <Route path="ungrouped-scans" element={<AsyncComponent path="./views/vtm/ungrouped-scans" />} />
          </Route>
        ))}
        {getTenantRoutes(MenuOptionsEnum.PENTESTING).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[marketingServicesGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/pentesting" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.VISP).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[marketingServicesGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/visp" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.INCIDENT_RESPONSE).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[marketingServicesGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/incident-response" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.XDR).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[clientDashboardGuard]} redirectUrl="/">
                <Suspense fallback={<Loader isGlobal={true} />}>
                  <ClientServiceGuard
                    accept={<AsyncComponent path="./views/xdr" />}
                    deny={<AsyncComponent path="./views/xdr/marketing" />}
                    service={ClientServicesEnum.XDR}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.TPRM).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[marketingServicesGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/tprm/marketing" />
              </ProtectedRoute>
            }
          />
        ))}
        {getTenantRoutes(MenuOptionsEnum.MSAT).map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute guards={[marketingServicesGuard, clientDashboardGuard]} redirectUrl="/">
                <AsyncComponent path="./views/msat/marketing" />
              </ProtectedRoute>
            }
          />
        ))}
        <Route path="*" element={<AsyncComponent path="./shared/widgets/wildcard.component" />} />
      </Route>
      <Route path="sso-login" element={<AsyncComponent path="./views/auth/components/sso-login.component" />} />
      <Route path="callback" element={<AsyncComponent path="./views/auth/components/duo-callback.component" />} />
      <Route path="report" element={<AsyncComponent path="./views/report-generation" />} />
    </Routes>
  );
}
