import { createSlice } from '@reduxjs/toolkit';

import { IReportSettings } from 'src/interfaces/report.type';

const initialState: { settings: IReportSettings } = {
  settings: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    updateSettings(state, action) {
      state.settings = { ...state.settings, ...action.payload };
    },
    clearSettings(state) {
      state.settings = {};
    },
  },
});

const { actions } = reportSlice;
export const updateReportSettings = actions.updateSettings;
export const clearReportSettings = actions.clearSettings;
