import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

import SearchControl from 'src/shared/components/search-control.component';
import { MainLayoutService } from '../../main-layout.service';
import { buildObjectFromQueryParams } from 'src/utils/query-params-mapper.util';

const PREFIX = 'Search';

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.text.secondary,
  },
}));

const Search = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const { globalSearch } = buildObjectFromQueryParams(location.search);
    setShowSearchInput(!!globalSearch);
    setSearchValue(globalSearch as string);
  }, [location]);

  const handleSearch = (value: string): void => {
    setSearchValue(value);

    if (value) {
      navigate(`${MainLayoutService.getRoutePath()}/global-search/?globalSearch=${encodeURIComponent(value)}`);
    }
  };

  return (
    <StyledBox>
      {!showSearchInput && (
        <IconButton
          aria-label="search"
          color="inherit"
          className={classes.icon}
          onClick={(): void => setShowSearchInput(true)}
          size="large"
        >
          <SvgIcon>
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      )}
      {showSearchInput && <SearchControl search={handleSearch} value={searchValue} autoFocus={true} />}
    </StyledBox>
  );
};

export default Search;
