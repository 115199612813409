import { ClientService } from '../services/client.service';
import { CurrentUserService } from '../services/current-user.service';

export function organizationGuard(): boolean {
  const isFccSide = CurrentUserService.isUserInCoreTeam;
  const isOrgUser = CurrentUserService.isOrgUser;
  const hasOrgViewAccess = isFccSide && !!ClientService.getSelectedOrganization();

  return isOrgUser || hasOrgViewAccess;
}
