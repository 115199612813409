import { ICssStyle } from 'src/interfaces/css-style.type';

export const searchIconStyle: ICssStyle = {
  searchIcon: {
    padding: 1,
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:not(.Mui-disabled)': {
      '& .MuiSvgIcon-root': {
        '&:hover': {
          color: 'text.primary',
        },
      },
    },
  },
};
