import React, { useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import noop from 'lodash/noop';

import FccTextField from 'src/shared/components/controls/text-field';

const PREFIX = 'LoginPasswordField';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledInputAdornment = styled(InputAdornment)(() => ({
  [`& .${classes.root}`]: {
    '& input::-ms-reveal': {
      display: 'none',
    },
  },
}));

interface IProps {
  autoComplete?: 'on' | 'off' | 'new-password';
  error?: boolean;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  helperText?: string;
  label: string;
  name: string;
  value: string;
}

export default function LoginPasswordField({
  autoComplete = 'on',
  error,
  handleBlur = noop,
  handleChange = noop,
  helperText,
  label,
  name,
  value,
}: IProps): React.ReactElement {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <FccTextField
      className={classes.root}
      error={error}
      fullWidth
      helperText={helperText}
      label={label}
      margin="normal"
      name={name}
      trimPosition="both"
      onBlur={handleBlur}
      onChange={handleChange}
      type={showPassword ? 'text' : 'password'}
      value={value}
      InputProps={{
        endAdornment: (
          <StyledInputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={(): void => setShowPassword(!showPassword)}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </StyledInputAdornment>
        ),
        autoComplete,
      }}
    />
  );
}
