import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IStaticAdvisoryServicesSlice } from './utils/static-advisory-services-slice.type';
import { apiUrls } from 'src/api/api-urls.constant';
import axios from 'src/api/axios';

const initialState: IStaticAdvisoryServicesSlice = {
  data: null,
};

export const fetchStaticAdvisoryServicesData = createAsyncThunk('staticAdvisoryServices/data', async () => {
  const response = await axios.get(apiUrls.staticAdvisoryServices);
  return response.data;
});

export const staticAdvisoryServicesSlice = createSlice({
  name: 'staticAdvisoryServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaticAdvisoryServicesData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
