const apiBasePath = '/api';
const fccBasePath = '/api/fcc';
const utmBasePathV2 = '/api/utm/api/v2.0.0';
const advisoryServicePath = '/api/advisory-service';

export const apiUrls = {
  advisoryServiceAssignments: `${advisoryServicePath}/general/`,
  alerts: `${fccBasePath}/vtm/alerts/`,
  attachments: `${fccBasePath}/general/attachments/`,
  auditLogList: `${apiBasePath}/logs/audit_logs/`,
  auditLogStatic: `${apiBasePath}/logs/general/static/`,
  averageVulnerabilityAge: `${fccBasePath}/vtm/vulnerabilities/exploitable-average-age/`,
  chatHistories: `${fccBasePath}/general/chat-history/`,
  chatHistory: `${fccBasePath}/general/send/chat-history/`,
  chatHistoryInitialize: `${fccBasePath}/general/chat-history/initialize/`,
  chatInitiate: `${fccBasePath}/general/new_chat/initiate/`,
  clientsByService: `${utmBasePathV2}/clients_by_service/`,
  communicationPreferences: `${utmBasePathV2}/communication-preferences/`,
  communicationThreads: `${apiBasePath}/communication-threads/communication_thread/`,
  communicationThreadsAttachments: `${apiBasePath}/communication-threads/communication_thread/attachments/`,
  comparativeAnalyticsIomtAverageRiskScore: `${fccBasePath}/iomt/comparative/average_risk_score/`,
  comparativeAnalyticsIomtCorporateDevicesTrend: `${fccBasePath}/iomt/comparative/corporate_device/chart/`,
  comparativeAnalyticsIomtHighRiskPercentage: `${fccBasePath}/iomt/comparative/high_risk_percentage/chart/`,
  comparativeAnalyticsIomtTotalNumberHighRiskDevices: `${fccBasePath}/iomt/comparative/total_high_risk_devices/chart/`,
  comparativeAnalyticsIomtTotalNumberOfDevices: `${fccBasePath}/iomt/comparative/devices_total_number/`,
  comparativeAnalyticsIomtTotalVulnerabilities: `${fccBasePath}/iomt/comparative/total_vulnerabilities/`,
  comparativeAnalyticsIomtVulnerabilitiesByCategory: `${fccBasePath}/iomt/comparative/vulnerabilities_by_category/chart/`,
  comparativeAnalyticsMdrAlarmsPerMachine: `${fccBasePath}/mdr/comparative/alarms-per-machine/chart/`,
  comparativeAnalyticsMdrBlockedAttacksPerMachine: `${fccBasePath}/mdr/comparative/blocked-attacks-per-machine/chart/`,
  comparativeAnalyticsMdrNotMeetBestPractices: `${fccBasePath}/mdr/comparative/sensor/not-meet-best-practices/`,
  comparativeAnalyticsSiemEscalationPercentageOvertime: `${fccBasePath}/siem/comparative/escalated_alarms/trend/`,
  comparativeAnalyticsSiemlAlarmsByIntentChart: `${fccBasePath}/siem/comparative/alarms_by_intent/`,
  comparativeAnalyticsSiemlAlarmsByPriorityChart: `${fccBasePath}/siem/comparative/alarms_by_priority/`,
  comparativeAnalyticsSiemMetricsCards: `${fccBasePath}/siem/comparative/metrics/`,
  comparativeAnalyticsVtmOsChart: `${fccBasePath}/vtm/comparative/vulnerabilities_by_os/`,
  comparativeAnalyticsVtmPatched: `${fccBasePath}/vtm/comparative/patched_vulnerabilities/`,
  comparativeAnalyticsVtmSeverityChart: `${fccBasePath}/vtm/comparative/vulnerabilities_by_severity/`,
  comparativeAnalyticsVtmTotalPerHost: `${fccBasePath}/vtm/comparative/metrics/`,
  documentsFolders: `${apiBasePath}/documents/documents/folders/`,
  escalationAttachments: `${apiBasePath}/escalations/general/attachments/`,
  escalationEmailValidate: `${utmBasePathV2}/escalation-email/validate/`,
  escalations: `${apiBasePath}/escalations/escalations/`,
  escalationsBySeverity: `${apiBasePath}/escalations/escalations/count/by_severity/`,
  escalationsCountByOrganization: `${apiBasePath}/escalations/escalations/count/by_organization/`,
  escalationsExcelReport: `${apiBasePath}/escalations/escalations/export/excel/`,
  escalationsMTTA: `${apiBasePath}/escalations/escalations/mtta/`,
  escalationsMTTR: `${apiBasePath}/escalations/escalations/mttr/`,
  escalationStatic: `${apiBasePath}/escalations/general/static/`,
  escalationsTopFive: `${apiBasePath}/escalations/escalations/top-five-escalations/`,
  escalationsVolume: `${apiBasePath}/escalations/escalations/volume/`,
  generalOrganization: `${advisoryServicePath}/general/organization/`,
  generalUsers: `${fccBasePath}/general/users`,
  globalSearch: `${fccBasePath}/global_search/`,
  globalSearchExport: `${fccBasePath}/global_search/result/export/excel/`,
  globalSearchResultCount: `${fccBasePath}/global_search/result/count/`,
  iomtAffectedDevices: `${fccBasePath}/iomt/alarms/`,
  iomtAlarms: `${fccBasePath}/iomt/alarms/`,
  iomtAlarmsExcelReport: `${fccBasePath}/iomt/alarms/export/excel/`,
  iomtAlarmTypes: `${fccBasePath}/iomt/device-types/`,
  iomtAverageRiskScore: `${fccBasePath}/iomt/average_risk_score/chart/`,
  iomtAverageRiskScoreTrend: `${fccBasePath}/iomt/average_risk/chart/`,
  iomtByPercentageOfHighRisk: `${fccBasePath}/iomt/high_risk_percentage/chart/`,
  iomtDevicesByCategories: `${fccBasePath}/iomt/devices_number/`,
  iomtHistoricalAlarms: `${fccBasePath}/iomt/historical/alarms/`,
  iomtMetrics: `${fccBasePath}/iomt/v2/metrics/`,
  iomtRealTimeAlarms: `${fccBasePath}/iomt/realtime/alarms/`,
  iomtRealTimeAlarmsDetails: `${fccBasePath}/iomt/alarms/details`,
  iomtRiskDeviceTypes: `${fccBasePath}/iomt/top10high_risk_devices/chart/`,
  iomtTopAlarmTypesByVolume: `${fccBasePath}/iomt/alarms/top_by_volume/chart/`,
  iomtTopVulnerabilities: `${fccBasePath}/iomt/top_5_vulns/`,
  iomtTotalVulnerabilitiesChartData: `${fccBasePath}/iomt/vulnerabilities_by_category/chart/`,
  maturityOverallScore: `${advisoryServicePath}/risk_assessment/dashboard/maturity/overall-score/`,
  maturityScoresOfFunctions: `${advisoryServicePath}/risk_assessment/dashboard/maturity/functions-score/`,
  mdrAlarms: `${fccBasePath}/mdr/alarms/`,
  mdrAlarmsData: `${fccBasePath}/mdr/metrics/`,
  mdrAlarmSearch: `${fccBasePath}/mdr/search/alarms/`,
  mdrAlarmsExcelReport: `${fccBasePath}/mdr/search/alarms/export/excel/`,
  mdrChartData: `${fccBasePath}/mdr/charts/`,
  mdrCriticalAssets: `${fccBasePath}/mdr/critical-assets/`,
  mdrIsolatedDevices: `${fccBasePath}/mdr/isolated-devices/`,
  mdrMachinesByOsType: `${fccBasePath}/mdr/sensor/os-types/`,
  mdrMachinesByStatus: `${fccBasePath}/mdr/sensor/statuses/`,
  mdrMachinesNotMeetingBestPractices: `${fccBasePath}/mdr/sensor/not-meet-best-practices/`,
  mdrOutdatedSensors: `${fccBasePath}/mdr/outdated/sensors/`,
  mdrSuspiciousAttacksBlocked: `${fccBasePath}/mdr/blocked-attacks/`,
  mdrTotalAlarms: `${fccBasePath}/mdr/total/alarms/`,
  netNewVulnerabilitiesThisMonth: `${fccBasePath}/vtm/new_vulnerabilities/`,
  newsfeed: `${fccBasePath}/newsfeed/`,
  notificationRead: `${fccBasePath}/notifications/read/`,
  notifications: `${fccBasePath}/notifications/list/`,
  notificationsCount: `${fccBasePath}/notifications/count/`,
  organization: `${utmBasePathV2}/organization/`,
  organizationShortDetails: `${utmBasePathV2}/organization/short-details/`,
  organizationPreference: `${utmBasePathV2}/organization-preferences/`,
  organizations: `${utmBasePathV2}/organizations/`,
  organizationUnreadNotificationsCount: `${fccBasePath}/notifications/count/unread/org-tenant/`,
  passwordReset: `${utmBasePathV2}/password/reset/`,
  pdfReport: `${fccBasePath}/pdf_report/`,
  pdfReportsGeneral: `${fccBasePath}/general/pdf-reports/`,
  pluginFamily: `${fccBasePath}/vtm/plugin-family/`,
  postAlert: `${fccBasePath}/notifications/post-alert/`,
  refreshToken: `${fccBasePath}/auth/token/`,
  riskAssessment: `${advisoryServicePath}/risk_assessment/`,
  riskAssessmentAssessments: `${advisoryServicePath}/risk_assessment/assessments/`,
  riskAssessmentAvailablePeriods: `${advisoryServicePath}/risk_assessment/assessment-periods/available/`,
  riskAssessmentClarizenProjectDetails: `${advisoryServicePath}/risk_assessment/clarizen/`,
  riskAssessmentControlMapping: `${advisoryServicePath}/risk_assessment/assessments/control-mapping`,
  riskAssessmentControlMetrics: `${advisoryServicePath}/risk_assessment/dashboard/control-metrics/`,
  riskAssessmentDocuments: `${advisoryServicePath}/risk_assessment/documents/`,
  riskAssessmentDocumentsFunctionsAndCategories: `${advisoryServicePath}/risk_assessment/categories-functions/`,
  riskAssessmentFacilities: `${advisoryServicePath}/risk_assessment/facilities/`,
  riskAssessmentHeatmap: `${advisoryServicePath}/risk_assessment/dashboard/maturity/heat-map/`,
  riskAssessmentRisksBySeverity: `${advisoryServicePath}/risk_assessment/dashboard/risk-by-severity/`,
  riskAssessmentTimelines: `${advisoryServicePath}/risk_assessment/dashboard/timelines/`,
  riskAssessmentWalkthroughAreas: `${advisoryServicePath}/risk_assessment/walkthrough/areas/`,
  riskBySeverityByYearData: `${advisoryServicePath}/risk_assessment/dashboard/risk-by-severity/year-over-year/`,
  riskRegister: `${advisoryServicePath}/risk_register/`,
  riskRegisterCapDashboard: `${advisoryServicePath}/risk_register/cap/dashboard/`,
  riskRegisterCategories: `${advisoryServicePath}/risk_register/risk-categories/`,
  riskRegisterClosedRisksThisQuarter: `${advisoryServicePath}/risk_register/dashboard/closed-risks/`,
  riskRegisterDiscussionDetails: `${advisoryServicePath}/risk_register/discussion-details/`,
  riskRegisterExcelReport: `${advisoryServicePath}/risk_register/risks/export/excel/`,
  riskRegisterList: `${advisoryServicePath}/risk_register/risks/`,
  riskRegisterNumberOfAcceptedRisks: `${advisoryServicePath}/risk_register/risks/accepted`,
  riskRegisterOpenRisksByCategory: `${advisoryServicePath}/risk_register/dashboard/risks-by-categories/`,
  riskRegisterQuarterOverQuarterTrend: `${advisoryServicePath}/risk_register/dashboard/severity-by-quarters/`,
  riskRegisterRemediatedRisksMetrics: `${advisoryServicePath}/risk_register/dashboard/risk-remediated/`,
  riskRegisterRisks: `${advisoryServicePath}/risk_register/risks/`,
  riskRegisterRisksBySeverity: `${advisoryServicePath}/risk_register/dashboard/by-severity/`,
  riskRegisterRisksMetrics: `${advisoryServicePath}/risk_register/dashboard/risks-by-status/`,
  riskRegisterTotalNumberOpenRisks: `${advisoryServicePath}/risk_register/dashboard/open-risks-total-number/`,
  salesforce: `${utmBasePathV2}/sf/`,
  scanGroupList: `${fccBasePath}/vtm/client-groups/`,
  sendMessage: `${fccBasePath}/general/send/message/`,
  services: `${utmBasePathV2}/services/`,
  siemAlarms: `${fccBasePath}/siem/alarms/`,
  siemAlarmsByIds: `${fccBasePath}/siem/selected/alarms/`,
  siemAlarmsByIntentChartData: `${fccBasePath}/siem/alarms_by_intent/`,
  siemAlarmsByMethodChartData: `${fccBasePath}/siem/alarms/breakdown/methods/`,
  siemAlarmsExcelReport: `${fccBasePath}/siem/search/alarms/export/excel/`,
  siemAlarmsSearch: `${fccBasePath}/siem/search/alarms/`,
  siemAlarmsTrendChartData: `${fccBasePath}/siem/alarms/events/breakdown/`,
  siemClientHoursSaved: `${fccBasePath}/siem/client_hours_saved/`,
  siemEscalatedAlarmsChartData: `${fccBasePath}/siem/escalated_alarms_funnel/`,
  siemMetricsData: `${fccBasePath}/siem/metrics/`,
  siemTopEscalatedAlarms: `${fccBasePath}/siem/escalated_alarms/top`,
  static: `${fccBasePath}/general/static/`,
  staticAdvisoryServices: `${advisoryServicePath}/general/static/`,
  supportRequest: `${utmBasePathV2}/jira/support-request/`,
  supportRequestCategoriesAndSubcategories: `${utmBasePathV2}/jira/support-request/categories-subcategories/`,
  tenant: `${utmBasePathV2}/tenant/`,
  tenantShortDetails: `${utmBasePathV2}/tenant/short-details/`,
  tenantIncidentReport: `${utmBasePathV2}/jira/report/`,
  tenantPreference: `${utmBasePathV2}/tenant-preferences/`,
  tenants: `${utmBasePathV2}/tenants/`,
  tenantsByOrganization: `${utmBasePathV2}/tenants/by_organization/`,
  topAlarmsByDetectionType: `${fccBasePath}/mdr/alarms/top/detection-type/`,
  unifiedAveragePerHostVulnerabilities: `${fccBasePath}/vtm/hosts/vulnerabilities/total/`,
  unifiedTotalVulnerabilities: `${fccBasePath}/vtm/vulnerabilities_by_severity/`,
  unifiedVulnerabilitiesTrend: `${fccBasePath}/vtm/vulnerabilities/trend/chart/`,
  unlockService: `${fccBasePath}/general/services/unlock/`,
  unreadNotificationsCount: `${fccBasePath}/notifications/count/unread/`,
  user: `${utmBasePathV2}/user/`,
  userPreferences: `${utmBasePathV2}/user-preferences/`,
  userRoles: `${utmBasePathV2}/roles/`,
  users: `${utmBasePathV2}/users/`,
  usersByOrganization: `${utmBasePathV2}/users/by_organization/`,
  usersShortList: `${utmBasePathV2}/users/short-list/`,
  userStaticData: `${utmBasePathV2}/static/`,
  usersValidate: `${utmBasePathV2}/users`,
  vtm: `${fccBasePath}/vtm/`,
  vtmAcceptedVulnerabilities: `${fccBasePath}/vtm/accepted/vulnerabilities/`,
  vtmAuthenticatedVulnerabilitiesPatched: `${fccBasePath}/vtm/patched_vulnerabilities/`,
  vtmCreateOtrReports: `${fccBasePath}/vtm/otr-reports/create/`,
  vtmCreateScanGrouping: `${fccBasePath}/vtm/scans-group/create/`,
  vtmCreateScanPreview: `${fccBasePath}/vtm/scans-group/preview/`,
  vtmDetailedScans: `${fccBasePath}/vtm/detailed-scans/`,
  vtmHostsExcelReport: `${fccBasePath}/vtm/hosts/export/excel/`,
  vtmLastScansGroupSummary: `${fccBasePath}/vtm/scans-group/last/`,
  vtmOtrReportLink: `${fccBasePath}/vtm/download`,
  vtmOtrReports: `${fccBasePath}/vtm/otr-reports/`,
  vtmPatchingWorkEffortChart: `${fccBasePath}/vtm/vulnerabilities/patching_work_effort/chart/`,
  vtmPlugins: `${fccBasePath}/vtm/plugins/`,
  vtmScanDelete: `${fccBasePath}/vtm/scans/`,
  vtmScanGroups: `${fccBasePath}/vtm/scans-group/`,
  vtmScanUngroup: `${fccBasePath}/vtm/scan-group/ungroup/`,
  vtmScanUpload: `${fccBasePath}/vtm/scans/upload/`,
  vtmScanners: `${utmBasePathV2}/scanners/`,
  vtmTotalAcceptedVulnerabilities: `${fccBasePath}/vtm/accepted/vulnerabilities_total_number/`,
  vtmUngroupedScans: `${fccBasePath}/vtm/ungrouped-scans/`,
  vtmVulnerabilities: `${fccBasePath}/vtm/vulnerabilities/`,
  vtmVulnerabilitiesES: `${fccBasePath}/vtm/search/vulnerabilities/`,
  vtmVulnerabilitiesExcelReport: `${fccBasePath}/vtm/vulnerabilities/export/excel/`,
  vtmVulnerabilitiesExcelReportES: `${fccBasePath}/vtm/search/vulnerabilities/export/excel/`,
  vtmVulnerabilityTriage: `${fccBasePath}/vtm/vulnerabilities/severity_breakdown/chart/`,
  vulnerableHostList: `${fccBasePath}/vtm/hosts/`,
  xdrAlarmsSearch: `${fccBasePath}/xdr/search/alarms/`,
  xdrAlarmsTrend: `${fccBasePath}/xdr/alarms/trend/`,
  xdrMachinesByOsType: `${fccBasePath}/xdr/sensor/os-types/`,
  xdrMachinesByStatus: `${fccBasePath}/xdr/sensor/statuses/`,
  xdrOutdatedSensors: `${fccBasePath}/xdr/outdated/sensors/`,
  xdrRecentEscalations: `${fccBasePath}/xdr/recent-escalations`,
  xdrTotalAlarms: `${fccBasePath}/xdr/total/alarms/`,
};
