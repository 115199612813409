import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import NotificationIcon from './notification-icon.component';
import { INotification } from '../notifications.type';
import { ICssStyle } from 'src/interfaces/css-style.type';
import { NotificationServiceLabelEnum, NotificationServiceValueEnum } from '../utils/notification-services.enum';
import { ThemesEnum } from 'src/enums/themes.enum';
import { NotificationTypesEnum } from 'src/shared/modules/notifications/utils/notifications-types.enum';
import { titleCase } from 'src/utils/title-case.util';

const classes: ICssStyle = {
  header: { display: 'flex', cursor: 'pointer', alignItems: 'flex-start' },
  title: { fontWeight: 'fontWeightMedium', paddingLeft: 2 },
  service: { color: 'primary.main', backgroundColor: 'rgba(0, 190, 165, 0.1)' },
  entityName: { display: 'inline-block', mb: 2, color: '#92979D', backgroundColor: '#E6E6E6' },
  date: { fontSize: '12px', opacity: 0.75 },
  footer: { display: 'flex', alignItems: 'center' },
  unread: { background: (theme: Theme): string => (theme.palette.mode === ThemesEnum.LIGHT ? '#ebfaf8' : '#3a4a47') },
  orgInfo: { fontSize: '12px', fontWeight: 'fontWeightMedium', p: '4px 8px', borderRadius: '4px', mr: 4 },
};

interface IProps {
  data: INotification;
  isRead: boolean;
  selectNotification: (data: INotification) => void;
}

const serviceNameMap: Record<NotificationServiceValueEnum, NotificationServiceLabelEnum> = {
  [NotificationServiceValueEnum.VTM]: NotificationServiceLabelEnum.VTM,
  [NotificationServiceValueEnum.SIEM]: NotificationServiceLabelEnum.SIEM,
  [NotificationServiceValueEnum.MDR]: NotificationServiceLabelEnum.MDR,
  [NotificationServiceValueEnum.IOMT]: NotificationServiceLabelEnum.IOMT,
  [NotificationServiceValueEnum.XDR]: NotificationServiceLabelEnum.XDR,
  [NotificationServiceValueEnum.RISK_REGISTER]: NotificationServiceLabelEnum.RISK_REGISTER,
  [NotificationServiceValueEnum.RISK_ASSESSMENT]: NotificationServiceLabelEnum.RISK_ASSESSMENT,
  [NotificationServiceValueEnum.REPORTS]: NotificationServiceLabelEnum.REPORTS,
};

export default function Notification({ data, isRead, selectNotification }: IProps): React.ReactElement {
  return (
    <>
      <Box sx={{ p: 4, ...(isRead ? {} : classes.unread) }}>
        {data?.entity_name && <Box sx={[classes.orgInfo, classes.entityName]}>{data.entity_name}</Box>}
        <Box sx={classes.header} onClick={(): void => selectNotification(data)}>
          <NotificationIcon type={data?.type} />
          <Typography component="h6" sx={classes.title}>
            {data.type === NotificationTypesEnum.ESCALATION ? `New escalation: ${titleCase(data?.name)}` : data?.name}
          </Typography>
        </Box>
        <Box mt={2} mb={1}>
          <Typography component="span">{data?.description}</Typography>
        </Box>
        <Box sx={classes.footer}>
          <Box sx={[classes.orgInfo, classes.service]}>{serviceNameMap[data?.service]}</Box>
          <Typography sx={classes.date}>{data?.created_at}</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
}
