import { useEffect } from 'react';

import axios from 'axios';

export default function useUnhandledPromiseErrors(): void {
  const handleUnhandledErrors = (event: PromiseRejectionEvent): void => {
    event.preventDefault();
    if (process.env.NODE_ENV === 'development' && !axios.isCancel(event.reason)) {
      console.error(event.reason);
    }
  };

  useEffect(() => {
    window.addEventListener('unhandledrejection', handleUnhandledErrors);
    return (): void => window.removeEventListener('unhandledrejection', handleUnhandledErrors);
  }, []);
}
