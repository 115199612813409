export enum GtmEventsEnum {
  ASSOCIATE_USER = 'associate_user',
  CHAT_HEADER_OPENED = 'chat_header_opened',
  CHAT_MESSAGE_SEND = 'chat_message_send',
  CHAT_OPENED = 'chat_opened',
  ESCALATION_ASSIGNED = 'escalation_assigned',
  ESCALATION_REMEDIATED = 'escalation_remediated',
  LOGIN = 'login',
  SEARCH = 'search',
  THREAD_STARTED = 'thread_started',
  VULNERABILITY_REMEDIATED = 'vulnerability_remediated',
}
