import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { ICssProperties, ICssStyle } from '../../interfaces/css-style.type';

const classes: ICssStyle = {
  spinner: { display: 'flex', justifyContent: 'center' },
  globalSpinner: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' },
};

interface IProps {
  isGlobal?: boolean;
  label?: string;
  size?: number;
  sx?: ICssProperties;
}

export const Loader = ({ isGlobal, label, sx = {}, size }: IProps): React.ReactElement => {
  return (
    <Box sx={{ ...(isGlobal ? classes.globalSpinner : classes.spinner), ...sx }}>
      <Box>
        <CircularProgress size={size} color="primary" />
        {label && <Box sx={{ mt: 3, color: 'primary.main' }}>{label}</Box>}
      </Box>
    </Box>
  );
};
