import BusinessCenter from '@mui/icons-material/BusinessCenter';
import CompareOutlined from '@mui/icons-material/CompareOutlined';
import Dashboard from '@mui/icons-material/Dashboard';
import Group from '@mui/icons-material/Group';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import People from '@mui/icons-material/People';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Settings from '@mui/icons-material/Settings';

import MsatIcon from 'src/shared/components/icons/msat-icon.component';
import ReportsIcon from 'src/shared/components/icons/reports-icon.component';
import TprmIcon from 'src/shared/components/icons/tprm-icon.component';
import { MainLayoutService } from '../../main-layout.service';
import { ClientService } from 'src/services/client.service';
import { CurrentUserService } from 'src/services/current-user.service';
import { UserPermissionService } from 'src/services/user-permission.service';
import { INavBarSection } from '../navbar.type';
import { AppLayersEnum } from '../../utils/app-layers.enum';
import { ClientServicesEnum } from 'src/enums/client-services.enum';
import { MenuOptionsEnum } from 'src/enums/menu-options.enum';
import { servicesIconMap } from 'src/constants/client-services-icon.constant';

const sectionList: INavBarSection[] = [
  {
    subheader: 'Main',
    items: [
      {
        title: 'Clients',
        icon: People,
        href: '/clients',
        id: MenuOptionsEnum.CLIENTS,
        layers: [AppLayersEnum.ADMIN],
        checkAccess: (): boolean => CurrentUserService.permissions.VIEW_CLIENTS_AND_SERVICES,
      },
      {
        title: 'Tenants',
        icon: People,
        href: `/${MenuOptionsEnum.ORG_TENANTS}`,
        id: MenuOptionsEnum.ORG_TENANTS,
        layers: [AppLayersEnum.ORGANIZATION],
        checkAccess: (): boolean => true,
      },
      {
        title: 'Unified Dashboard',
        icon: Dashboard,
        href: '/unified-dashboard',
        id: MenuOptionsEnum.UNIFIED_DASHBOARD,
        layers: [AppLayersEnum.CLIENT],
        checkAccess: (): boolean => true,
      },
      {
        title: 'Comparative Analytics',
        icon: CompareOutlined,
        href: '/comparative-analytics',
        id: MenuOptionsEnum.COMPARATIVE_ANALYTICS,
        layers: [AppLayersEnum.CLIENT],
        checkAccess: (): boolean =>
          !CurrentUserService.isRiskRegisterUser() && CurrentUserService.permissions.VIEW_ANALYTIC_SERVICE_LINE_SCORE,
      },
      {
        title: 'Reports',
        icon: ReportsIcon,
        href: '/reports',
        id: MenuOptionsEnum.REPORTS,
        layers: [AppLayersEnum.CLIENT],
        checkAccess: (): boolean => {
          return [
            CurrentUserService.permissions.VIEW_MDR_REPORTS,
            CurrentUserService.permissions.VIEW_IOMT_REPORTS,
            CurrentUserService.permissions.VIEW_SIEM_REPORTS,
            CurrentUserService.permissions.VIEW_RISK_ASSESSMENT_REPORTS,
            CurrentUserService.permissions.VIEW_RISK_REGISTER_REPORTS,
            CurrentUserService.permissions.VIEW_VTM_REPORTS,
            CurrentUserService.permissions.VIEW_COMPARATIVE_ANALYTICS_REPORTS,
          ].some(Boolean);
        },
      },
      {
        title: 'Escalations',
        icon: ReportProblemOutlinedIcon,
        href: '/escalations',
        id: MenuOptionsEnum.ESCALATIONS,
        layers: [AppLayersEnum.CLIENT],
        checkAccess: (): boolean => CurrentUserService.permissions.ESCALATION_VIEW_LIST,
      },
      {
        title: 'SIEM',
        icon: servicesIconMap[ClientServicesEnum.SIEM],
        href: `/${MenuOptionsEnum.SIEM}`,
        id: MenuOptionsEnum.SIEM,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.VIEW_SIEM_DASHBOARDS,
            ClientServicesEnum.SIEM,
            services,
          ),
        checkAccess: (): boolean => ClientService.checkSiemMdrServiceAccess(ClientServicesEnum.SIEM),
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.SIEM),
      },
      {
        title: 'VTM',
        icon: servicesIconMap[ClientServicesEnum.VTM],
        href: `/${MenuOptionsEnum.VTM}`,
        id: MenuOptionsEnum.VTM,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.VIEW_VTM_DASHBOARDS,
            ClientServicesEnum.VTM,
            services,
          ),
        checkAccess: (): boolean => true,
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.VTM),
      },
      {
        title: 'IoMT',
        icon: servicesIconMap[ClientServicesEnum.IOMT],
        href: `/${MenuOptionsEnum.IOMT}`,
        id: MenuOptionsEnum.IOMT,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.VIEW_IOMT_DASHBOARD,
            ClientServicesEnum.IOMT,
            services,
          ),
        checkAccess: (): boolean => true,
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.IOMT),
      },
      {
        title: 'MDR',
        icon: servicesIconMap[ClientServicesEnum.MDR],
        href: `/${MenuOptionsEnum.MDR}`,
        id: MenuOptionsEnum.MDR,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.VIEW_MDR_DASHBOARD,
            ClientServicesEnum.MDR,
            services,
          ),
        checkAccess: (): boolean => ClientService.checkSiemMdrServiceAccess(ClientServicesEnum.MDR),
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.MDR),
      },
      {
        title: 'XDR',
        icon: servicesIconMap[ClientServicesEnum.XDR],
        href: `/${MenuOptionsEnum.XDR}`,
        id: MenuOptionsEnum.XDR,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.VIEW_XDR_SERVICE,
            ClientServicesEnum.XDR,
            services,
          ),
        checkAccess: (): boolean => true,
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.XDR),
      },
    ],
  },
  {
    subheader: 'Advisory Services',
    items: [
      {
        title: 'Risk Assessment',
        icon: servicesIconMap[ClientServicesEnum.RISK_ASSESSMENT],
        href: `/${MenuOptionsEnum.RISK_ASSESSMENT}`,
        id: MenuOptionsEnum.RISK_ASSESSMENT,
        layers: [AppLayersEnum.ORGANIZATION, AppLayersEnum.CLIENT],
        className: 'a-service-title',
        checkAccess: (): boolean => true,
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.RISK_ASSESSMENT,
            ClientServicesEnum.RISK_ASSESSMENT,
            services,
          ),
        checkShowTailIcon: (): boolean =>
          MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.RISK_ASSESSMENT),
      },
      {
        title: 'Risk Register',
        icon: servicesIconMap[ClientServicesEnum.RISK_REGISTER],
        href: `/${MenuOptionsEnum.RISK_REGISTER}`,
        id: MenuOptionsEnum.RISK_REGISTER,
        layers: [AppLayersEnum.ORGANIZATION, AppLayersEnum.CLIENT],
        className: 'a-service-title',
        checkAccess: (): boolean => true,
        isInactive: (services: ClientServicesEnum[]): boolean =>
          !MainLayoutService.checkClientServiceAccess(
            CurrentUserService.permissions.RISK_REGISTER,
            ClientServicesEnum.RISK_REGISTER,
            services,
          ),
        checkShowTailIcon: (): boolean => MainLayoutService.checkShowServiceTailIcon(ClientServicesEnum.RISK_REGISTER),
      },
      {
        title: 'vCISO',
        icon: servicesIconMap[ClientServicesEnum.VISP],
        href: `/${MenuOptionsEnum.VISP}`,
        id: MenuOptionsEnum.VISP,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: () => true,
        checkAccess: (): boolean => false,
      },
      {
        title: 'Pen Testing',
        icon: servicesIconMap[ClientServicesEnum.PENTESTING],
        href: `/${MenuOptionsEnum.PENTESTING}`,
        id: MenuOptionsEnum.PENTESTING,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: () => true,
        checkAccess: (): boolean => false,
      },
      {
        title: 'Incident Response',
        icon: servicesIconMap[ClientServicesEnum.INCIDENT_RESPONSE],
        href: `/${MenuOptionsEnum.INCIDENT_RESPONSE}`,
        id: MenuOptionsEnum.INCIDENT_RESPONSE,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: () => true,
        checkAccess: (): boolean => false,
      },
      {
        title: 'TPRM',
        icon: TprmIcon,
        href: `/${MenuOptionsEnum.TPRM}`,
        id: MenuOptionsEnum.TPRM,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: () => true,
        checkAccess: (): boolean => false,
      },
      {
        title: 'MSAT',
        icon: MsatIcon,
        href: `/${MenuOptionsEnum.MSAT}`,
        id: MenuOptionsEnum.MSAT,
        layers: [AppLayersEnum.CLIENT],
        className: 'a-service-title',
        isInactive: () => true,
        checkAccess: (): boolean => false,
      },
    ],
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Tenant Management',
        icon: BusinessCenter,
        href: `/${MenuOptionsEnum.TENANT_MANAGEMENT}`,
        id: MenuOptionsEnum.TENANT_MANAGEMENT,
        layers: [AppLayersEnum.ADMIN],
        checkAccess: (): boolean => UserPermissionService.hasTenantManagementViewAccess(),
      },
      {
        title: 'Organization Profile',
        icon: BusinessCenter,
        href: `/${MenuOptionsEnum.ORGANIZATION_PROFILE}`,
        id: MenuOptionsEnum.ORGANIZATION_PROFILE,
        layers: [AppLayersEnum.ORGANIZATION],
        checkAccess: (): boolean => CurrentUserService.permissions.ORGANIZATION_PROFILE_VIEW,
      },
      {
        title: 'Tenant Profile',
        icon: BusinessCenter,
        href: `/${MenuOptionsEnum.TENANT_PROFILE}`,
        id: MenuOptionsEnum.TENANT_PROFILE,
        layers: [AppLayersEnum.CLIENT],
        checkAccess: (): boolean => CurrentUserService.permissions.VIEW_TENANT_PROFILE,
      },
      {
        title: 'User Management',
        icon: Group,
        href: '/user-management',
        id: MenuOptionsEnum.USER_MANAGEMENT,
        layers: [AppLayersEnum.ADMIN, AppLayersEnum.ORGANIZATION, AppLayersEnum.CLIENT],
        checkAccess: (): boolean => !!CurrentUserService.permissions.VIEW_TENANT_USER_LIST,
      },
      {
        title: 'Audit Log',
        icon: LibraryBooks,
        href: `/${MenuOptionsEnum.AUDIT_LOG}`,
        id: MenuOptionsEnum.AUDIT_LOG,
        layers: [AppLayersEnum.ADMIN, AppLayersEnum.ORGANIZATION, AppLayersEnum.CLIENT],
        checkAccess: (): boolean => UserPermissionService.hasAuditLogViewAccess(),
      },
      {
        title: 'Settings',
        icon: Settings,
        href: `/${MenuOptionsEnum.SETTINGS}`,
        id: MenuOptionsEnum.SETTINGS,
        layers: [AppLayersEnum.ORGANIZATION, AppLayersEnum.CLIENT],
        checkAccess: (): boolean => CurrentUserService.permissions.TENANT_PREFERENCE_UPDATE,
      },
    ],
  },
  {
    subheader: 'Admin Settings',
    items: [
      {
        title: 'Global Settings',
        icon: Settings,
        href: `/${MenuOptionsEnum.SETTINGS}`,
        id: MenuOptionsEnum.GLOBAL_SETTINGS,
        layers: [AppLayersEnum.ADMIN],
        checkAccess: (): boolean => CurrentUserService.permissions.TENANT_MASTER_PREFERENCE_GET,
      },
    ],
  },
];

export default sectionList;
