import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';

export default function FccOptionItem({
  key,
  value,
  children,
  disabled,
  sx,
  ...props
}: MenuItemProps): React.ReactElement {
  return (
    <MenuItem className="a-select-option" key={key} value={value} disabled={disabled} sx={sx} {...props}>
      {children}
    </MenuItem>
  );
}
