import axios from 'axios';
import qs from 'qs';

import { AuthService } from '../services/auth.service';
import { SessionService } from './session.service';

export const baseURL =
  process.env.NODE_ENV === 'development' ? 'https://app.dev.fortifiedhealthsecurity.com' : window.location.origin;

export const wsBaseUrl =
  process.env.NODE_ENV === 'development'
    ? `wss://app.dev.fortifiedhealthsecurity.com`
    : `wss://${window.location.origin.split('//')[1]}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const paramsSerializer = (params: Record<string, any>): string =>
  qs.stringify(params, { arrayFormat: 'comma', encode: true, encoder: encodeURIComponent, skipNulls: true });

export const axiosConfig = { baseURL, paramsSerializer };
const axiosInstance = axios.create(axiosConfig);
const emailErrorMessage = 'User has not verified his email.';

const handleRedirectToLogin = (): void => {
  SessionService.preserveRoute();
  SessionService.redirectToLoginPage();
};

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const session = await AuthService.getCurrentSession();
      if (session) {
        const token = session.getAccessToken().getJwtToken();
        token && (config.headers.Authorization = `Bearer ${token}`);
      } else if (SessionService.reportAccessToken) {
        config.headers.Authorization = `S2S ${SessionService.reportAccessToken}`;
      }
    } catch (e) {
      console.error(e);
    }
    return config;
  },
  (error) => Promise.reject(error || 'Something went wrong'),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && !error.config._retry) {
      const originalRequest = error.config;
      originalRequest._retry = true;
      return axiosInstance(originalRequest);
    }

    if (error.response?.status === 401 && error.config._retry) {
      handleRedirectToLogin();
    }

    if (error.response?.status === 502 && !error.config._retry) {
      const originalRequest = error.config;
      originalRequest._retry = true;

      return axiosInstance(originalRequest);
    }

    if (error.response?.status === 403 && error.response?.data?.errors?.[0]?.detail === emailErrorMessage) {
      handleRedirectToLogin();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
